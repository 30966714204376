import { fetcher } from "@/utils/fetchers/fetcher";
import { Case } from "../types/Case";
import { useQuery } from "@tanstack/react-query";
import { GET_CASES_FETCH_KEY } from "./useGetCases";

const getCase = async (caseId: string) => {
  const response = await fetcher<Case>(`/cases/${caseId}`);
  return response.data;
};

interface UseGetCaseOptionsProps {
  refetchOnMount?: boolean;
  enabled?: boolean;
}

export const useGetCase = (
  caseId: string,
  { refetchOnMount = true, enabled = true }: UseGetCaseOptionsProps = {},
) => {
  return useQuery({
    queryKey: [GET_CASES_FETCH_KEY, caseId],
    queryFn: () => getCase(caseId),
    enabled: !!caseId && enabled,
    refetchOnMount,
  });
};
