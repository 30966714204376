import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { Court } from "../types/Court";

export const GET_COURTS_FETCH_KEY = "get_courts";

const getCourts = async () => {
  const response = await fetcher<Court[]>("/courts");

  return response.data;
}

export const useGetCourts = () => {
  const query = useQuery({
    queryKey: [GET_COURTS_FETCH_KEY],
    queryFn: getCourts,
  });

  return {
    ...query,
    courts: query.data || [],
  };
};