import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import {
  Avatar,
  Box,
  Button,
  Card,
  FormControl,
  Input,
  Text,
} from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { ProfileLayout } from "../components/ProfileLayout";
import { useUpdateUser } from "@/features/users/useCases/useUpdateUser";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = z.object({
  name: z.string({ description: "field_required" }),
});
type UserProfileFormData = z.infer<typeof schema>;

export const ProfilePage = () => {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUser();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserProfileFormData>({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: { name: currentUser?.name },
  });
  const { updateUser, isPending } = useUpdateUser();

  const onSubmit = async (userData: UserProfileFormData) => {
    if (!currentUser) return;

    updateUser({
      userId: currentUser?.id,
      data: {
        active: currentUser.active,
        role: currentUser.role_id,
        name: userData.name,
      },
    });
  };

  return (
    <ProfileLayout>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Card className="min-w-full md:min-w-[600px] py-11 px-20">
          <Card.Body>
            <Box className="flex items-center mb-2">
              <Text className="flex text-primary-900 font-semibold text-xl">
                {t("profile.my_account")}
              </Text>
              <Avatar size="md" name={currentUser?.name} className="ml-2" />
            </Box>

            <Box className="flex flex-col gap-4">
              <FormControl>
                <FormControl.Label>Email:</FormControl.Label>

                <Text className="text-sm">{currentUser?.email}</Text>
              </FormControl>

              <FormControl>
                <FormControl.Label>Rol:</FormControl.Label>

                <Text className="text-sm">{currentUser?.role?.name}</Text>
              </FormControl>

              <FormControl isInvalid={!!errors.name} required>
                <FormControl.Label>Nombre</FormControl.Label>
                <Input {...register("name")} />
              </FormControl>
            </Box>
          </Card.Body>

          <Card.Footer>
            <Button type="submit" loading={isPending} className="flex ml-auto">
              {t("ui.save")}
            </Button>
          </Card.Footer>
        </Card>
      </Box>
    </ProfileLayout>
  );
};
