import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateUser, User } from "../types/User";
import { GET_USERS_FETCH_KEY } from "./useGetUsers";

interface CreateUserResponse extends User {
  password?: string | null;
  token?: string | null;
  invite?: string | null;
}

const createUser = async (data: CreateUser) => {
  const response = await fetcher.post<CreateUserResponse>("/users", {
    user: data,
  });
  return response.data;
};

export const useCreateUser = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isPending, data, mutateAsync } = useMutation({
    mutationFn: createUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_USERS_FETCH_KEY] });

      toast.add({
        content: "Usuario creado exitosamente",
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: "Error al crear el usuario",
        status: "danger",
      });
    },
  });

  return {
    createUser: mutateAsync,
    isPending,
    data,
  };
};

