import { normalizeText } from "./normalizeText";

/**
 * String includes another string ignoring case and diacritics
 * @param str1
 * @param str2
 * @returns
 */
export const stringIncludes = (str1: string, str2: string) => {
  const normalizedStr1 = normalizeText(str1);
  const normalizedStr2 = normalizeText(str2);
  return normalizedStr1.includes(normalizedStr2);
};

