import { navigateToSSO } from "../useCases/navigateToSSO";
import {
  TOKEN_QUERY_PARAM_NAME,
  getTokenFromURL,
} from "../useCases/getTokenFromURL";
import { removeQueryParam } from "../../../utils/urls/removeQueryParam";
import { STORAGE_SESSION_NAME } from "../conts";
import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import { useEffect } from "react";
import { Box, Button, Placeholder, Spinner } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { setFetcherToken } from "@/utils/fetchers/fetcher";
import { signOut } from "../useCases/signOut";
import { getTenantName } from "../useCases/getTenantName";
import { identifyTrackedUser } from "../../../utils/analytics/identifyTrackedUser";
import { trackEvent } from "@/utils/analytics/trackEvent";
import { useQueryClient } from "@tanstack/react-query";

export interface AuthProviderProps {
  children?: React.ReactNode;
}

const getToken = () => localStorage.getItem(STORAGE_SESSION_NAME);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    data: user,
    isLoading,
    refetch,
    isError,
    isPending,
  } = useCurrentUser({
    enabled: !!getToken(),
  });

  useEffect(() => {
    const loadSession = () => {
      const tokenStorage = getToken();
      const tokenURL = getTokenFromURL(window.location.search);

      if (!tokenStorage && !tokenURL) {
        const tenant = getTenantName();
        return navigateToSSO({ redirect: window.location.href, tenant });
      }

      if (tokenURL && tokenURL !== tokenStorage) {
        queryClient.clear();
        localStorage.setItem(STORAGE_SESSION_NAME, tokenURL);
        removeQueryParam(TOKEN_QUERY_PARAM_NAME);
        setFetcherToken(tokenURL);
        refetch();
      }
    };

    loadSession();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user)
      identifyTrackedUser({
        id: user.id,
        role: user.role.type,
        tenant: user.tenant,
        email: user.email,
      });
  }, [user]);

  if (isLoading || isPending)
    return (
      <Box className="flex justify-center items-center w-full h-screen">
        <Spinner size="20" />
      </Box>
    );

  if (isError)
    return (
      <Box className="flex justify-center items-center w-full h-screen">
        <Placeholder className="w-[600px]" variant="vertical">
          <Placeholder.Image>
            <Placeholder.EmptyErrorIcon />
          </Placeholder.Image>
          <Placeholder.Content>
            <Placeholder.Title>{t("ui.error_loading_data")}</Placeholder.Title>
            <Placeholder.Description>
              {t("ui.an_error_occurred_while_loading_the_data")}
            </Placeholder.Description>
          </Placeholder.Content>
          <Placeholder.BtnGroup className="flex gap-4">
            <Button
              onClick={() => {
                trackEvent("User logged out");
                signOut();
              }}
              variant="outline"
            >
              Cerrar sesión
            </Button>

            <Button onClick={() => window.location.reload()}>
              {t("ui.reload")}
            </Button>
          </Placeholder.BtnGroup>
        </Placeholder>
      </Box>
    );

  return children;
};
