import { Table } from "@suit-ui/react";
import { useTranslation } from "react-i18next";

const options = [20, 50, 100];

interface TableFooterProps {
  isEmpty?: boolean;
  rowsPerPageSelect?: boolean;
}

export function TableFooter({
  isEmpty,
  rowsPerPageSelect = true,
}: TableFooterProps) {
  const { t } = useTranslation();

  if (isEmpty) return null;

  return (
    <Table.Footer>
      {rowsPerPageSelect ? (
        <Table.RowsPerPageSelect
          options={options}
          label={t("cases.rows_per_page")}
        />
      ) : (
        <div />
      )}
      <Table.Pagination>
        <Table.Counter>
          {({ from, to, total }) => `${from}-${to} ${t("ui.of")} ${total}`}
        </Table.Counter>
        <Table.Paginator />
      </Table.Pagination>
    </Table.Footer>
  );
}
