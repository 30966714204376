import { fetcher } from "@/utils/fetchers/fetcher";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "@suit-ui/react";
import { GET_TASK_COMMENTS_FETCH_KEY } from "./useGetTaskComments";
import { TaskComment } from "../types/TaskComment";

interface CreateTaskCommentProps {
  taskId: string;
  comment: TaskComment;
}

const createTaskComment = async (params: CreateTaskCommentProps) => {
  const response = await fetcher.post<TaskComment>(
    `/tasks/${params.taskId}/comments`,
    {
      comment: params.comment,
    },
  );

  return response.data;
};

export const useCreateTaskComment = (taskId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toast = useToast();

  return useMutation({
    mutationFn: createTaskComment,
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: [GET_TASK_COMMENTS_FETCH_KEY, taskId],
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });
};
