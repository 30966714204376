import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { CredentialType } from "../types/CredentialType";

const GET_CREDENTIALS_TYPES_KEY = "get-credentials-types";

const getCredentialsTypes = async () => {
  const res = await fetcher<CredentialType>("/scrapers/credential_types");
  return res.data;
};

export const useGetCredentialsTypes = () => {
  const query = useQuery({
    queryKey: [GET_CREDENTIALS_TYPES_KEY],
    queryFn: getCredentialsTypes,
  });
  return {
    ...query,
    credentialsTypes: query.data,
  };
};
