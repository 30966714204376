import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { GET_CASES_FETCH_KEY } from "./useGetCases";

const deleteCaseParty = async ({
  caseId,
  casePartyId,
}: {
  caseId: string;
  casePartyId: string;
}) => {
  const response = await fetcher.delete(
    `/cases/${caseId}/case_parties/${casePartyId}`,
  );
  return response.data;
};

export const useDeleteCaseParty = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteCaseParty,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_CASES_FETCH_KEY],
      });

      toast.add({
        content: t("cases.case_updated"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return { deleteCaseParty: mutateAsync, isPending };
};
