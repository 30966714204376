import { useTranslation } from "react-i18next";
import { Event } from "../types/Event";
import { Box, Table, Text, Tooltip, Avatar } from "@suit-ui/react";
import { format } from "date-fns";
import { EditEventIconButton } from "./EditEventIconButton";
import { DeleteEventIconButton } from "./DeleteEventIconButton";
import { Pagination } from "@/utils/pagination/PaginatedResource";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { User } from "@/features/users/types/User";
import { useGetCourts } from "@/features/cases/useCases/useGetCourts";
import { TableBody } from "@/ui/TableBody";
import { useNavigate } from "react-router-dom";
import { routes } from "@/router";
import clsx from "clsx";
import { TableFooter } from "@/ui/TableFooter";
import { trackEvent } from "@/utils/analytics/trackEvent";

export interface EventTableProps {
  events: Event[];
  title?: string;
  isLoading?: boolean;
  pagination?: Pagination;
  nextPage: () => void;
  prevPage: () => void;
  onChangePageRows: (page: number) => void;
  includeCase?: boolean;
  strongHeader?: boolean;
  isEditable?: boolean;
  availableEventUsers?: string[];
  hideActions?: boolean;
}

const parseAttendees = (attendees: string[], users: User[]) => {
  return attendees.map((attendee) => {
    const user = users.find((user) => user.id === attendee);

    if (user) {
      return (
        <Tooltip key={`user_ids-id-${user?.id}`} label={user?.name}>
          <Avatar name={user?.name} />
        </Tooltip>
      );
    }

    return attendee;
  });
};

export const EventTable: React.FC<EventTableProps> = ({
  title,
  events,
  isLoading,
  pagination,
  nextPage,
  prevPage,
  onChangePageRows,
  includeCase,
  strongHeader,
  isEditable = true,
  availableEventUsers = [],
}) => {
  const { t } = useTranslation();
  const { users } = useGetUsers();
  const { courtMap } = useGetCourts();
  const navigate = useNavigate();

  const headerClass = strongHeader
    ? "bg-primary-800 text-neutral-50"
    : "bg-primary-200 text-neutral-900";

  const isEmpty = events.length <= 0;

  const rowClass = includeCase ? "cursor-pointer" : "";

  const handleNavigate = (id: string) => {
    if (!includeCase) {
      return;
    }

    const selection = window.getSelection();

    if (selection?.type != "Range") {
      navigate(`${routes.cases}/${id}?tab=events`);
      trackEvent("Event clicked");
    }
  };

  return (
    <Box>
      {title && (
        <Box className="mb-3">
          <Text className="text-lg font-semibold">{title}</Text>
        </Box>
      )}
      <Table.Container
        isLoading={isLoading}
        variant="elevated"
        className="rounded-md"
        page={pagination?.page}
        rowsPerPage={pagination?.pageSize}
        totalPages={pagination?.pageCount}
        totalRows={pagination?.total}
        onNextPage={nextPage}
        onPreviousPage={prevPage}
        onChangeRowsPerPage={(page) => onChangePageRows(Number(page))}
      >
        <Table>
          <Table.Head className={`${headerClass} font-semibold`}>
            <Table.Row>
              <Table.Header>{t("events.start_date")}</Table.Header>
              <Table.Header>{t("events.end_date")}</Table.Header>
              <Table.Header>{t("events.summary")}</Table.Header>
              <Table.Header>{t("events.description")}</Table.Header>
              {includeCase && <Table.Header>{t("cases.case")}</Table.Header>}
              <Table.Header>{t("events.attendees")}</Table.Header>
              {isEditable && (
                <Table.Header className="w-24">
                  {t("events.options")}
                </Table.Header>
              )}
            </Table.Row>
          </Table.Head>
          <TableBody isEmpty={isEmpty} title={t("events.table_no_data")}>
            {events?.map((event) => (
              <Table.Row
                key={event.id}
                className={clsx(
                  "even:bg-neutral-100 odd:bg-neutral-50",
                  rowClass,
                )}
                onClick={
                  includeCase
                    ? () => {
                        handleNavigate(event.case?.id as string);
                      }
                    : undefined
                }
              >
                <Table.Cell>
                  <Text className="text-sm font-semibold whitespace-nowrap">
                    {event.start_date &&
                      format(
                        event.start_date,
                        event.all_day
                          ? t("date_formats.date")
                          : t("date_formats.datetime"),
                      )}
                  </Text>
                </Table.Cell>
                <Table.Cell>
                  <Text className="text-sm font-semibold whitespace-nowrap">
                    {event.end_date &&
                      format(
                        event.end_date,
                        event.all_day
                          ? t("date_formats.date")
                          : t("date_formats.datetime"),
                      )}
                  </Text>
                </Table.Cell>
                <Table.Cell>
                  <Text className="text-sm font-semibold">{event.summary}</Text>
                </Table.Cell>
                <Table.Cell>{event.description}</Table.Cell>
                {includeCase && (
                  <Table.Cell>
                    <Box>
                      <Text className="text-sm">
                        {`${event.case?.code} - ${
                          courtMap.get(event.case?.court_id as string)?.name ||
                          ""
                        }`}
                      </Text>
                      <Text className="text-sm">{event.case?.title}</Text>
                    </Box>
                  </Table.Cell>
                )}
                <Table.Cell>
                  <Avatar.Group
                    size="sm"
                    variant="dark"
                    max={3}
                    className="my-2"
                  >
                    {parseAttendees(event.attendees!, users)}
                  </Avatar.Group>
                </Table.Cell>
                {isEditable && (
                  <Table.Cell>
                    <Box className="flex justify-center w-full gap-1">
                      <EditEventIconButton
                        event={event}
                        availableEventUsers={availableEventUsers}
                        size={strongHeader ? "md" : "sm"}
                      />
                      <DeleteEventIconButton
                        event={event}
                        size={strongHeader ? "md" : "sm"}
                      />
                    </Box>
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
          </TableBody>
        </Table>
        <TableFooter isEmpty={isEmpty} />
      </Table.Container>
    </Box>
  );
};
