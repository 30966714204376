import { Modal } from "@suit-ui/react";
import { Case } from "../types/Case";
import { useCreateCaseParty } from "../useCases/useCreateCaseParty";
import { CasePartyForm, CasePartyFormData } from "./CasePartyForm";
import { trackEvent } from "@/utils/analytics/trackEvent";

export interface AddCasePartyModalProps {
  caseId: Case["id"];
  isOpen: boolean;
  onClose: () => void;
}

export const AddCasePartyModal = ({
  isOpen,
  onClose,
  caseId,
}: AddCasePartyModalProps) => {
  const { createCaseParty, isPending: isCreatingParty } = useCreateCaseParty();

  const onSubmit = async (data: CasePartyFormData) => {
    await createCaseParty({
      caseId,
      data: {
        name: data.name,
        identifier: data.identifier,
        party_type: data.party_type,
        comments: data.comments,
      },
    });

    trackEvent("Party added to case");

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
          Nueva parte involucrada
        </Modal.Header>

        <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
        <Modal.Body>
          <CasePartyForm
            onSubmit={onSubmit}
            onCancel={onClose}
            isLoading={isCreatingParty}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
