import { UserPermission } from "@/features/users/types/UserPermission";
import { useCurrentUser } from "./useCurrentUser";

export const useUserAccess = (
  permission?: UserPermission | UserPermission[] | null,
) => {
  const { data: currentUser } = useCurrentUser();

  const permissionList = Array.isArray(permission) ? permission : [permission];
  const hasSomePermission = permissionList?.some(
    (p) => p && currentUser?.role?.permissions?.includes(p),
  );

  if (!permission || hasSomePermission) return true;

  return false;
};
