import { fetcher } from "@/utils/fetchers/fetcher";
import { Court } from "../types/Court";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const updateCourt = async (data: Court) => {
  const response = await fetcher.put<Court>(
    `/courts/${data.id}`, { court: { name: data.name } },
  );

  return response.data
};

export const useUpdateCourt = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: updateCourt,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ["get-court", data.id]
      });

      toast.add({
        content: t("cases_data.courts.update_success"),
        status: "success",
      })
    },

    onError: () => {
      toast.add({
        content: t("cases_data.courts.update_failed"),
        status: "danger",
      })
    }
  })

  return {
    updateCourt: mutateAsync,
    isPending,
  }
};