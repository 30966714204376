import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { GET_CUSTOM_FIELD_VALUES_FETCH_KEY } from "./useGetCustomFieldValues";

const deleteCustomFieldValue = async (
  customFieldId: string,
  customFieldValueId: string,
) => {
  const response = await fetcher.delete(
    `/custom_fields/${customFieldId}/custom_field_values/${customFieldValueId}`,
  );
  return response.data;
};

export const useDeleteCustomFieldValue = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (data: {
      customFieldId: string;
      customFieldValueId: string;
    }) => {
      await deleteCustomFieldValue(data.customFieldId, data.customFieldValueId);
      return data;
    },
    onSuccess: async ({ customFieldId }) => {
      await queryClient.invalidateQueries({
        queryKey: [GET_CUSTOM_FIELD_VALUES_FETCH_KEY, customFieldId],
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return {
    deleteCustomFieldValue: mutateAsync,
    isPending,
  };
};
