import { Box, IconButton, Text } from "@suit-ui/react";
import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import { Comment } from "./Comment";
import { CommentInput } from "./CommentInput";
import { useMemo, useState } from "react";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { Task } from "../types/Task";
import { User } from "@/features/users/types/User";
import { useGetTaskComments } from "../useCases/useGetTaskComments";
import { useCreateTaskComment } from "../useCases/useCreateTaskComment";
import { useTranslation } from "react-i18next";
import { PlaceholderLoader } from "@/ui/PlaceholderLoader";
import Confirm from "@/ui/Confirm";
import { MdOutlineClose } from "react-icons/md";
import { useDeleteTaskComment } from "../useCases/useDeleteTaskComment";
import { UserAccess } from "@/features/session/components/UserAccess";
import { useUserAccess } from "@/features/session/useCases/useUserAccess";
import { trackEvent } from "@/utils/analytics/trackEvent";

interface CommentsProps {
  task: Task;
  isEditable?: boolean;
}

export function Comments({ task, isEditable }: CommentsProps) {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const { findById } = useGetUsers();
  const { data: comments, isLoading } = useGetTaskComments(task._id);
  const createComment = useCreateTaskComment(task._id);
  const deleteComment = useDeleteTaskComment(task._id);
  const [newComment, setNewComment] = useState<string>("");
  const usersSuggestions = useMemo(() => {
    return task?.case?.user_ids
      ?.map((userId) => findById(userId))
      .filter(Boolean) as User[];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task?.case?.user_ids]);

  const addComment = () => {
    if (newComment.length === 0) {
      return;
    }

    createComment.mutate({
      taskId: task._id,
      comment: { content: newComment },
    });

    trackEvent("Comment created");
    setNewComment("");
  };

  const canCommentsDestroy = useUserAccess("comments-destroy");

  return (
    <Box className="border-t border-neutral-200 pt-4">
      <Text as="h3" className="text-sm font-semibold mb-3">
        {t("comments.title")}
      </Text>
      <Box className="flex flex-col gap-2.5">
        {!isLoading && !comments?.length && !createComment.isPending && (
          <Box className="py-4">
            <Text className="text-neutral-500 text-sm italic">
              {t("movements.no_comments")}...
            </Text>
          </Box>
        )}

        {comments?.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            deleteButton={
              (currentUser?.data?.id === comment.created_by_id ||
                canCommentsDestroy) &&
              isEditable && (
                <Confirm
                  title={t("movements.delete_comment_title")}
                  onConfirm={() => {
                    deleteComment.mutateAsync({
                      commentId: comment.id!,
                      taskId: task._id,
                    });
                    trackEvent("Comment deleted");
                  }}
                  onConfirmText={t("ui.delete")}
                  button={
                    <IconButton
                      icon={<MdOutlineClose />}
                      color="neutral"
                      variant="text"
                      size="xs"
                      className="absolute top-3 right-3 group-hover:inline-flex hidden"
                    />
                  }
                  onOpen={(e) => e.stopPropagation()}
                  body={t("movements.delete_warning")}
                  isLoading={deleteComment.isPending}
                />
              )
            }
          />
        ))}
        {createComment.isPending && (
          <Comment
            comment={{
              content: createComment.variables?.comment?.content,
              created_at: new Date(),
              created_by_id: currentUser?.data?.id,
            }}
          />
        )}

        {isLoading && <PlaceholderLoader className="w-full h-[74px]" />}
      </Box>

      <UserAccess permission="comments-create">
        <CommentInput
          value={newComment}
          usersSuggestions={usersSuggestions}
          onChange={(value) => setNewComment(value)}
          onSubmit={addComment}
        />
      </UserAccess>
    </Box>
  );
}
