import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { TaskComment } from "../types/TaskComment";

export const GET_TASK_COMMENTS_FETCH_KEY = "GET_TASK_COMMENTS_FETCH_KEY";

const getComments = async (taskId: string) => {
  const response = await fetcher.get<TaskComment[]>(
    `/tasks/${taskId}/comments`,
  );
  return response.data;
};

export const useGetTaskComments = (taskId: string) => {
  return useQuery({
    queryKey: [GET_TASK_COMMENTS_FETCH_KEY, taskId],
    queryFn: () => getComments(taskId),
  });
};
