import {
  Button,
  Dialog,
  IconButton,
  IconButtonProps,
  useDisclosure,
} from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { useDeleteEvent } from "../useCases/useDeleteEvent";
import { Event } from "../types/Event";
import { MdDeleteOutline } from "react-icons/md";
import { trackEvent } from "@/utils/analytics/trackEvent";

interface DeleteEventIconButtonProps {
  event: Event;
  size?: IconButtonProps["size"];
}

export const DeleteEventIconButton: React.FC<DeleteEventIconButtonProps> = ({
  event,
  size = "sm",
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync, isPending } = useDeleteEvent(
    event.resource_type,
    event.resource_id,
    event.id,
  );

  const handleOnClose = () => {
    if (isPending) return;

    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync();
    trackEvent("Event deleted");
    onClose();
  };

  const handleOnOpen = (e: React.MouseEvent) => {
    e.stopPropagation();

    onOpen();
  };

  return (
    <>
      <IconButton
        onClick={handleOnOpen}
        variant="text"
        icon={<MdDeleteOutline />}
        color="neutral"
        size={size}
      />

      <Dialog
        isOpen={isOpen}
        onClose={handleOnClose}
        status="warning"
        size="sm"
      >
        <Dialog.Overlay />

        <Dialog.Content>
          <Dialog.Header>{t("events.delete_event")}</Dialog.Header>
          <Dialog.CloseButton />

          <Dialog.Body>{t("events.delete_warning")}</Dialog.Body>
          <Dialog.Footer>
            <Button
              onClick={handleOnClose}
              variant="text"
              color="neutral"
              className="mr-2"
              size="sm"
            >
              {t("events.cancel")}
            </Button>
            <Button size="sm" onClick={handleDelete} loading={isPending}>
              {t("events.confirm")}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
