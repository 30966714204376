import { Button, Dialog, useDisclosure } from "@suit-ui/react";
import { CredentialFormData } from "../types/CredentialFormData";
import { useUpdateCredentials } from "../useCases/useUpdateCredentials";
import CredentialModal from "./CredentialModal";
import { t } from "i18next";

interface EditCredentialModalProps {
  credential: CredentialFormData;
  credentialId: string;
  isOpen?: boolean;
  onClose: () => void;
}

const EditCredentialModal: React.FC<EditCredentialModalProps> = ({
  credential,
  credentialId,
  isOpen = false,
  onClose,
}) => {
  const { mutateAsync, data } = useUpdateCredentials();
  const duplicateDialog = useDisclosure();

  const onSubmit = async (
    cred: CredentialFormData,
    validatedKeyPath: string,
  ) => {
    const credResponse = await mutateAsync({
      credParams: cred,
      id: credentialId,
      validatedPath: validatedKeyPath,
    });

    if (credResponse.is_duplicate) duplicateDialog.onOpen();

    onClose();
  };

  return (
    <>
      <CredentialModal
        credential={credential}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        isCreationMode={false}
      />
      <Dialog isOpen={duplicateDialog.isOpen} onClose={duplicateDialog.onClose}>
        <Dialog.Overlay />
        <Dialog.Content>
          <Dialog.Header>{t("credentials.already_exists.title")}</Dialog.Header>
          <Dialog.CloseButton />
          <Dialog.Body>
            {t("credentials.already_exists.description", {
              name: data?.name,
            })}
          </Dialog.Body>
          <Dialog.Footer>
            <Button size="sm" onClick={duplicateDialog.onClose}>
              {t("ui.agree")}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default EditCredentialModal;
