import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET_CASES_FETCH_KEY } from "./useGetCases";
import { Case } from "../types/Case";
import { CaseParty } from "../types/CaseParty";

export const updateCaseParty = async ({
  caseId,
  data,
}: {
  caseId: Case["id"];
  data: CaseParty;
}) => {
  const res = await fetcher.put<CaseParty>(
    `/cases/${caseId}/case_parties/${data._id}`,
    { case_party: data },
  );
  return res.data;
};

export const useUpdateCaseParty = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: updateCaseParty,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_CASES_FETCH_KEY],
      });

      toast.add({
        content: "Caso actualizado correctamente",
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: "Error al actualizar los datos del caso",
        status: "danger",
      });
    },
  });

  return {
    updateCase: mutateAsync,
    isPending,
  };
};

