import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateAttachment,
  Attachment,
} from "@/features/movements/types/Attachment";
import { fetcher } from "@/utils/fetchers/fetcher";
import { useMemo, useState } from "react";
import { AxiosProgressEvent, GenericAbortSignal } from "axios";
import { GET_TASK_ATTACHMENTS_FETCH_KEY } from "./useGetTaskAttachments";

interface CreateAttachmentProps {
  taskId: string;
  attachment: CreateAttachment;
  setProgress: (value: number) => void;
  signal: GenericAbortSignal;
}

const createAttachment = async ({
  taskId,
  attachment,
  signal,
  setProgress,
}: CreateAttachmentProps) => {
  const response = await fetcher.post<Attachment>(
    `/tasks/${taskId}/attachments`,
    {
      file: attachment.file,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      signal: signal,
      onUploadProgress: (upload: AxiosProgressEvent) =>
        setProgress &&
        setProgress(Math.round((upload.loaded * 100) / (upload?.total ?? 0))),
    },
  );

  return response.data;
};

export const useCreateTaskAttachment = () => {
  const [progress, setProgress] = useState(0);
  const controller = useMemo(() => new AbortController(), []);
  const queryClient = useQueryClient();

  const onAbort = () => {
    controller.abort();
  };

  const mutation = useMutation({
    mutationFn: ({
      taskId,
      attachment,
    }: Pick<CreateAttachmentProps, "attachment" | "taskId">) =>
      createAttachment({
        taskId,
        attachment,
        setProgress,
        signal: controller.signal,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_TASK_ATTACHMENTS_FETCH_KEY],
      });
    },
  });

  return { ...mutation, progress, onAbort };
};
