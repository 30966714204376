import {
  GREEN_MARIO_API_URL,
  GREEN_MARIO_COOKIE_API_URL,
  SSO_API_URL,
} from "@/configs";
import { signOut } from "@/features/session/useCases/signOut";
import axios, { AxiosError } from "axios";
import * as Sentry from "@sentry/browser";

const token = localStorage.getItem("token");
const tenant = window.location.host.split(".")[0];
const baseURL = GREEN_MARIO_API_URL.replace("{tenant}", tenant);
const baseCookieURL = GREEN_MARIO_COOKIE_API_URL.replace("{tenant}", tenant);

export const fetcher = axios.create({
  baseURL,
  headers: { token },
});

export const fetcherSSO = axios.create({
  baseURL: SSO_API_URL,
  headers: { token },
});

export const fetcherCookie = axios.create({
  baseURL: baseCookieURL,
});

const errorInterceptor = (error: AxiosError<{ message?: string }>) => {
  const isUnauthorized = error.response?.status === 401;
  const message = error.response?.data?.message;
  const isInvalidToken = message === "Invalid authentication credentials";

  if (isUnauthorized && isInvalidToken) signOut();
  else {
    Sentry.setExtra("request_payload", JSON.parse(error?.config?.data));
    Sentry.captureException(error);
  }

  return Promise.reject(error);
};

fetcher.interceptors.response.use((res) => res, errorInterceptor);
fetcherSSO.interceptors.response.use((res) => res, errorInterceptor);
fetcherCookie.interceptors.response.use((res) => res, errorInterceptor);

export const setFetcherToken = (t: string) => {
  fetcher.defaults.headers.token = t;
  fetcherSSO.defaults.headers.token = t;
};
