import { Box, Button, Icon, Placeholder } from "@suit-ui/react";
import { useGetCourts } from "../useCases/useGetCourts";
import { format, formatISO } from "date-fns";
import { useDateLocale } from "@/utils/i18n/useDateLocale";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "@/router";
import { PlaceholderLoader } from "@/ui/PlaceholderLoader";
import { useGetTasks } from "@/features/tasks/useCases/useGetTasks";
import { MdOutlineEvent } from "react-icons/md";
import { trackEvent } from "@/utils/analytics/trackEvent";

export function OverviewTasksTable() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dateLocale = useDateLocale();
  const { tasks, isLoading } = useGetTasks({
    type: "all",
    pagination: {
      defaultValues: {
        pageSize: 10,
      },
    },
    filters: {
      sort: ["end_date:desc"],
      end_date: { gte: formatISO(new Date()) },
      state: {
        or: ["todo", "in_progress"],
      },
    },
  });
  const { courtMap } = useGetCourts();

  if (isLoading) {
    return (
      <Box className="flex flex-col h-[420px] overflow-hidden">
        {Array.from({ length: 3 }).map((_, index) => (
          <Box
            key={`last_movements_loader_${index}`}
            className="flex flex-col p-6 gap-2"
          >
            <PlaceholderLoader className="w-full h-4 mb-2" />
            <PlaceholderLoader className="w-full h-2" />
            <PlaceholderLoader className="w-full h-2" />
            <PlaceholderLoader className="w-full h-2" />
            <PlaceholderLoader className="w-full h-2" />
          </Box>
        ))}
      </Box>
    );
  }

  if (!tasks.length) {
    return (
      <>
        <Box className="flex ml-10 mt-5">
          <Box className="flex align-left text-primary-900 font-bold text-lg">
            {t("tasks.tasks")}
          </Box>
          <Box className="flex align-right ml-auto mr-10">
            <Button
              variant="text"
              size="xs"
              className="text-xs align-right"
              onClick={() => {
                trackEvent("Home Page - See more tasks clicked");
                navigate(routes.tasks);
              }}
            >
              {t("overview.see_more")}
            </Button>
          </Box>
        </Box>
        <Box className="flex flex-col h-[420px] justify-center items-center px-8">
          <Placeholder>
            <Placeholder.Image>
              <Placeholder.EmptyIcon />
            </Placeholder.Image>
            <Placeholder.Content>
              <Placeholder.Title>{t("ui.empty_data")}</Placeholder.Title>
              <Placeholder.Description>
                {t("ui.empty_data_description")}
              </Placeholder.Description>
            </Placeholder.Content>
          </Placeholder>
        </Box>
      </>
    );
  }

  return (
    <Box className="max-h-[420px] overflow-y-auto px-10">
      <Box className="flex mt-5">
        <Box className="flex align-left text-primary-900 font-bold text-lg">
          {t("tasks.tasks")}
        </Box>
        <Box className="flex align-right ml-auto">
          <Button
            variant="text"
            size="xs"
            className="text-xs align-right"
            onClick={() => {
              trackEvent("Home Page - See more tasks clicked");
              navigate(routes.tasks);
            }}
          >
            {t("overview.see_more")}
          </Button>
        </Box>
      </Box>
      {tasks.map((task) => (
        <Box
          as={Link}
          to={`${routes.cases}/${task.case?.id}?tab=tasks`}
          key={`next_tasks_${task._id}`}
          onClick={() => trackEvent("Home Page - Task clicked")}
          className="pt-4.5 pb-3 px-6 border-b border-neutral-300 last:border-b-0 flex text-neutral-600 hover:text-primary-600"
        >
          <Box className="flex">
            <Icon as={MdOutlineEvent} />
          </Box>
          <Box
            as="h2"
            className="flex flex-col px-10 font-bold mb-1 first-letter:capitalize"
          >
            {task?.start_date &&
              format(
                new Date(task.start_date),
                `EEEE dd '${t("ui.of")}' MMMM`,
                {
                  locale: dateLocale,
                },
              )}
          </Box>
          <Box className="flex flex-col">
            <Box className="flex flex-col font-bold text-md">
              {task.description}
            </Box>
            {task.case?.title && (
              <Box className="text-sm">{task.case?.title}</Box>
            )}
          </Box>
          {courtMap.get(task.case?.court_id as string)?.name && (
            <Box className="flex text-sm px-10">
              {courtMap.get(task.case?.court_id as string)?.name}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
