import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { Case } from "../types/Case";
import { useTranslation } from "react-i18next";
import { CaseFilter, GET_CASES_FETCH_KEY } from "./useGetCases";

const activateCases = async ({ filter }: { filter?: CaseFilter }) => {
  const reponse = await fetcher.post<Case>("/cases/activate", {
    filter,
  });

  return reponse.data;
};

export const useActivateCases = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: activateCases,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_CASES_FETCH_KEY] });

      toast.add({
        content: t("cases.activate_case_success"),
        status: "success",
      });
    },

    onError: (args) => {
      console.error(args);
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });
};
