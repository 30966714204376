import { CustomFieldType } from "../types/CustomField";
import { format } from "date-fns";

export const getCustomFieldValueFormatted = (
  value: unknown,
  type?: CustomFieldType | null,
): string => {
  if (value === null || value === undefined) return "";

  if (type === "FLOAT") return value as string;
  if (type === "INTEGER") return value as string;
  if (type === "BOOL") return value === true ? "Sí" : "No";

  if (type === "DATETIME")
    return format(new Date(value as string), "dd/MM/yyyy HH:mm:ss");

  if (type === "FIXED_SELECT" || type === "VARIABLE_SELECT")
    return value as string;

  if (type === "DATE" && typeof value === "string") {
    const datePart = value.split("T")[0];
    return datePart.split("-").reverse().join("/");
  }

  return String(value);
};

