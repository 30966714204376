import { trackEvent } from "@/utils/analytics/trackEvent";
import { fetcher } from "@/utils/fetchers/fetcher";
import { useTranslation } from "react-i18next";
import { CaseFilter } from "../useCases/useGetCases";
import qs from "qs";
import {
  Box,
  Button,
  Checkbox,
  Modal,
  Text,
  useDisclosure,
} from "@suit-ui/react";
import { MdDownload } from "react-icons/md";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useGetCustomFields } from "@/features/customFields/useCases/useGetCustomFields";
import { ChangeEvent, useEffect } from "react";
import { PlaceholderLoader } from "@/ui/PlaceholderLoader";

const fields = [
  "id",
  "code",
  "title",
  "court-name",
  "client-name",
  "last_movement_date",
  "last_movement_title",
  "active",
  "created_at",
  "created_by-name",
  "case_parties-name",
  "case_parties-identifier",
] as const;

type Field = (typeof fields)[number];

const defaultCheckedFields: Field[] = [
  "code",
  "title",
  "court-name",
  "client-name",
  "last_movement_date",
  "last_movement_title",
];

interface DownloadCasesProps {
  filters: CaseFilter;
  disabled?: boolean;
  className?: string;
}

const defaultColumnValues = fields.reduce(
  (a, v) => ({ ...a, [v]: defaultCheckedFields.includes(v) }),
  {} as { [K in Field]: boolean },
);

export function DownloadCases({
  filters,
  disabled = false,
  className,
}: DownloadCasesProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { customFields, isSuccess, isLoading } = useGetCustomFields("Case", {
    refetchOnMount: false,
  });
  const { t } = useTranslation();

  const defaultCustomFieldsValues = customFields.reduce(
    (a, v) => ({
      ...a,
      [`custom_data-${v.name}`]: v.display_order_table === 0 ? false : true,
    }),
    {} as { [K in Field]: boolean },
  );

  const defaultValues = {
    ...defaultColumnValues,
    ...defaultCustomFieldsValues,
  };

  const { register, handleSubmit, watch, reset } = useForm<{
    [index: string]: boolean;
  }>({
    mode: "onBlur",
    defaultValues,
  });

  const handleDownloadCases = async (columns: string[]) => {
    trackEvent("Cases downloaded");

    const response = await fetcher.get("/cases/download", {
      params: {
        filter: filters,
        columns,
      },
      responseType: "blob",
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: "brackets" }),
    });
    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${t("cases.cases")}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const onSubmit = handleSubmit(async (form: { [index: string]: boolean }) => {
    const columns = Object.entries(form)
      .filter(([, v]) => v)
      .map(([key]) => key.replace(/-/g, "."));

    handleDownloadCases(columns);
  });

  const onChangeColumnsCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    reset(
      Object.keys(defaultValues).reduce(
        (a, v) => ({ ...a, [v]: e.target.checked }),
        {} as { [K in Field]: boolean },
      ),
    );
  };

  const columns = watch();

  const allChecked = Object.values(columns).every(Boolean);
  const isIndeterminate = Object.values(columns).some(Boolean) && !allChecked;

  useEffect(() => {
    if (isSuccess) {
      reset(defaultValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <Button
        className={clsx("ml-auto gap-1", className)}
        leftIcon={<MdDownload />}
        variant="text"
        onClick={onOpen}
        disabled={disabled}
      >
        {t("cases.download_cases")}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        onAnimationComplete={({ isOpen }) => !isOpen && reset(defaultValues)}
        size="sm"
      >
        <Modal.Overlay />

        <Modal.Content as="form" onSubmit={onSubmit}>
          <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
            {t("cases.download_cases")}
          </Modal.Header>

          <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
          <Modal.Body>
            <Text className="mb-6">
              {t("cases.download_columns.description")}
            </Text>

            {isLoading ? (
              <Box className="flex flex-col gap-y-5">
                <PlaceholderLoader className="w-32 h-6" />

                <Box className="ml-[18px] flex flex-col gap-y-4">
                  <PlaceholderLoader className="w-48 h-6" />
                  <PlaceholderLoader className="w-56 h-6" />
                  <PlaceholderLoader className="w-60 h-6" />
                  <PlaceholderLoader className="w-52 h-6" />
                </Box>
              </Box>
            ) : (
              <Box className="flex flex-col gap-y-5">
                <Checkbox
                  checked={allChecked}
                  indeterminate={isIndeterminate}
                  onChange={onChangeColumnsCheckbox}
                >
                  {t("cases.download_columns.columns")}
                </Checkbox>
                <Box className="ml-[18px] flex flex-col gap-y-4">
                  {fields.map((field) => (
                    <Checkbox key={field} {...register(field)}>
                      {t(`cases.download_columns.${field}`)}
                    </Checkbox>
                  ))}
                  {customFields.map((field) => (
                    <Checkbox
                      key={`custom_data-${field.name}`}
                      {...register(`custom_data-${field.name}`)}
                    >
                      {field.name}
                    </Checkbox>
                  ))}
                </Box>
              </Box>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={onClose}
              variant="text"
              className="mr-2"
              color="neutral"
            >
              {t("ui.cancel")}
            </Button>
            <Button
              type="submit"
              disabled={!Object.values(columns).some(Boolean)}
            >
              {t("ui.download")}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}
