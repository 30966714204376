import { PageContainer } from "@/ui/containers/PageContainer";
import {
  Badge,
  Box,
  Button,
  Dropdown,
  Icon,
  IconButton,
  Link,
  Placeholder,
  Tag,
  Text,
  Tooltip,
} from "@suit-ui/react";
import { useParams } from "react-router-dom";
import { CreateMovement } from "../types/Movement";
import {
  MdBlock,
  MdInfoOutline,
  MdMoreVert,
  MdOutlineEdit,
  MdOutlineEvent,
  MdOutlinePublic,
  MdOutlineTimeline,
  MdPeopleOutline,
  MdSettingsBackupRestore,
} from "react-icons/md";
import { useMemo, useState } from "react";
import { CreateMovementModal } from "@/features/movements/components/CreateMovementModal";
import { useGetCase } from "../useCases/useGetCase";
import { useCreateMovement } from "../../movements/useCases/useCreateMovement";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import { useGetCourts } from "../useCases/useGetCourts";
import { useTranslation } from "react-i18next";
import { CreateEventButton } from "@/features/events/components/CreateEventButton";
import { EventTable } from "@/features/events/components/EventTable";
import { useUserAccess } from "@/features/session/useCases/useUserAccess";
import { UserAccess } from "@/features/session/components/UserAccess";
import { EventQueryParams } from "@/features/events/types/Event";
import { useGetEvents } from "@/features/events/useCases/useGetEvents";
import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import { EditCaseModal } from "../components/EditCaseModal";
import { Case } from "../types/Case";
import { useGetCustomFields } from "@/features/customFields/useCases/useGetCustomFields";
import { getCustomFieldValueFormatted } from "@/features/customFields/useCases/getCustomFieldValueFormatted";
import { useTenantData } from "@/features/session/useCases/useTenantData";
import { UrlQueryTabs } from "../../../ui/UrlQueryTabs";
import { CaseMovementsTable } from "../components/CaseMovementsTable";
import { CaseUsersTable } from "../components/CaseUsersTable";
import { format, parseISO } from "date-fns";
import { useUpdateCase } from "../useCases/useUpdateCase";
import { AxiosError } from "axios";
import { routes } from "@/router";
import { TaskTabTables } from "@/features/tasks/components/TaskTabTables";
import { TaskTabButton } from "@/features/tasks/components/TaskTabButton";
import Confirm from "@/ui/Confirm";
import { useGetClients } from "@/features/clients/useCases/useGetClients";
import { trackEvent } from "@/utils/analytics/trackEvent";
import { DeleteCaseDropdownItem } from '../components/DeleteCaseDropdownItem';

export const CaseDetailPage = () => {
  const [editCase, setEditCase] = useState<Case["id"] | null>(null);
  const { updateCase } = useUpdateCase();
  const { data: currentUser } = useCurrentUser();
  const { t } = useTranslation();
  const { caseId: id } = useParams();
  const caseId = id as string;
  const { data: caseData, isLoading, isError, error } = useGetCase(caseId);
  const { response } = (error || { response: {} }) as AxiosError;
  const { courtMap } = useGetCourts();
  const { clientMap } = useGetClients();
  const { customFields } = useGetCustomFields("Case");
  const { getFFValue } = useTenantData();
  const hasPermission = useUserAccess("cases-read");
  const canShowCustomFields = getFFValue("enable_enhanced_custom_data");

  const [isCreateMovementModalOpen, setIsCreateMovementModalOpen] =
    useState(false);
  const createMovement = useCreateMovement(id);

  const handleCreateMovement = async (data: CreateMovement) => {
    await createMovement.mutateAsync({
      caseId,
      movement: data,
    });
    trackEvent("Movement created");
  };

  const nextEventsParams = useMemo((): EventQueryParams => {
    return {
      filter: {
        startDate: {
          gte: new Date().toISOString(),
        },
      },
      sort: "start_date:asc",
    };
  }, []);

  const {
    events: nextEvents,
    isLoading: isLoadingNextEvents,
    isFetching: isFetchingNextEvents,
    pagination: nextEventsPagination,
    nextPage: nextEventsNextPage,
    prevPage: nextEventsPrevPage,
    onChangePageRows: onChangeNextEventsPageRows,
  } = useGetEvents("cases", caseId, nextEventsParams);

  const pastEventsParams = useMemo((): EventQueryParams => {
    return {
      filter: {
        startDate: {
          lt: new Date().toISOString(),
        },
      },
      sort: "start_date:desc",
    };
  }, []);

  const {
    events: pastEvents,
    isLoading: isLoadingPastEvents,
    isFetching: isFetchingPastEvents,
    pagination: pastEventsPagination,
    nextPage: pastEventsNextPage,
    prevPage: pastEventsPrevPage,
    onChangePageRows: onChangePastEventsPageRows,
  } = useGetEvents("cases", caseId, pastEventsParams);

  const customFieldInDetails = customFields.reduce((acc, field) => {
    if (field.display_order_table > 0) {
      acc.push(field.name);
    }
    return acc;
  }, [] as string[]);

  const showMovements = useUserAccess("movements-read");

  if (!hasPermission) return null;

  if (isLoading)
    return (
      <PageContainer isCentered>
        <PageContainer.Loader />
      </PageContainer>
    );

  if (isError)
    return (
      <PageContainer isCentered>
        <Placeholder>
          <Placeholder.Image>
            <Placeholder.EmptyFileIcon />
          </Placeholder.Image>
          <Placeholder.Content>
            <Placeholder.Title>
              {response?.status === 404 &&
                t("cases.case_not_found_error_title")}
              {response?.status === 403 &&
                t("cases.case_no_permission_error_title")}
            </Placeholder.Title>
            <Placeholder.Description>
              {response?.status === 404 &&
                t("cases.case_not_found_error_description")}
              {response?.status === 403 &&
                t("cases.case_no_permission_error_description")}

              <Link
                href={routes.cases}
                className="flex text-primary-600 justify-center mt-8 font-regular"
                variant="unstyled"
              >
                {t("overview.go_to_cases")}
              </Link>
            </Placeholder.Description>
          </Placeholder.Content>
        </Placeholder>
      </PageContainer>
    );

  if (!currentUser) return null;

  const isCaseEditable = caseData?.active && currentUser?.role.type !== "guest";
  const availableUsers = caseData?.user_ids || [];

  return (
    <PageContainer className="flex flex-col">
      <Box className="flex justify-between mb-4.5">
        <BreadcrumbNav customName={caseData?.title} />
      </Box>

      <Box className="flex flex-col gap-8">
        <Box className="bg-neutral-50 rounded-md flex flex-col py-8 gap-4 px-4.5 shadow-xs">
          <Box className="flex justify-between items-center gap-4">
            <Text as="h2" className="text-primary-800 text-xl font-semibold">
              {caseData?.title}{" "}
              {!caseData?.active && (
                <Tag color="danger" className="bg-danger-100 ml-2">
                  <Tag.Label className="text-danger-400">
                    {t("cases.inactive")}
                  </Tag.Label>
                </Tag>
              )}
            </Text>

            <Box>
              <UserAccess permission="cases-update">
                <Tooltip label={t("ui.edit")}>
                  <IconButton
                    icon={<MdOutlineEdit />}
                    variant="text"
                    color="neutral"
                    onClick={() => caseData && setEditCase(caseData.id)}
                    disabled={!isCaseEditable}
                  />
                </Tooltip>
              </UserAccess>

              <UserAccess permission="cases-destroy">
                <Confirm
                  title={`${
                    caseData?.active ? t("ui.deactivate") : t("ui.activate")
                  } - ${caseData?.title}`}
                  onConfirm={() => {
                    if (caseData)
                      updateCase({
                        id: caseData.id,
                        active: !caseData.active,
                      });

                    trackEvent("Case deactivate/activate button clicked");
                  }}
                  onConfirmText={
                    caseData?.active ? t("ui.deactivate") : t("ui.activate")
                  }
                  button={
                    <Box className="inline-flex">
                      <Tooltip
                        label={
                          caseData?.active
                            ? t("ui.deactivate")
                            : t("ui.activate")
                        }
                      >
                        <IconButton
                          icon={
                            caseData?.active ? (
                              <MdBlock />
                            ) : (
                              <MdSettingsBackupRestore />
                            )
                          }
                          variant="text"
                          color={caseData?.active ? "danger" : "success"}
                        />
                      </Tooltip>
                    </Box>
                  }
                  body={t("cases.toogle_case_description")}
                />
              </UserAccess>

              {caseData && 
                <UserAccess permission="cases-destroy">
                  <Dropdown placement="bottom-end">
                    <Dropdown.Button
                      as={IconButton}
                      color="neutral"
                      icon={<MdMoreVert />}
                      variant="text"
                    />
                    <Dropdown.List>
                      <DeleteCaseDropdownItem kase={caseData} />
                    </Dropdown.List>
                  </Dropdown>
                </UserAccess>}
            </Box>
          </Box>

          <Box className="text-neutral-700 text-sm grid grid-cols-4 gap-4">
            <Text as="span" className="flex items-center gap-1">
              <Text as="strong" className="font-semibold">
                {t("cases.code")}:
              </Text>
              {caseData?.code}
            </Text>

            {caseData?.scraper_metadata?.succeeded_at && (
              <Text as="span" className="flex items-center gap-1">
                <Icon as={MdInfoOutline} className="text-primary-800" />
                <Text as="strong" className="font-semibold">
                  {t("cases.last_updated")}:
                </Text>

                {format(
                  parseISO(caseData?.scraper_metadata?.succeeded_at),
                  t("date_formats.date"),
                )}
              </Text>
            )}

            {!!caseData?.court_id && (
              <Text as="span" className="flex items-center gap-1">
                <Text as="strong" className="font-semibold">
                  {t("cases.court")}:
                </Text>
                {courtMap.get(caseData?.court_id)?.name}
              </Text>
            )}

            {!!caseData?.client_id && (
              <Text as="span" className="flex items-center gap-1">
                <Text as="strong" className="font-semibold">
                  {t("cases.client")}:
                </Text>
                {clientMap.get(caseData?.client_id)?.name}
              </Text>
            )}

            {customFieldInDetails.map((key) => (
              <Text key={key} as="span" className="flex items-center gap-1">
                <Text as="strong" className="font-semibold">
                  {key}:
                </Text>
                {getCustomFieldValueFormatted(
                  caseData?.custom_data?.[key],
                  customFields.find((field) => field.name === key)?.type,
                ) || "-"}
              </Text>
            ))}

            {!!caseData?.scraper_params?.url && (
              <Text as="span" className="flex items-center gap-1">
                <Tooltip label={caseData?.scraper_params?.scraper}>
                  <Box className="flex">
                    <Icon as={MdOutlinePublic} className="text-primary-800" />
                  </Box>
                </Tooltip>
                {t("cases.connected_case")}
              </Text>
            )}
          </Box>
        </Box>

        <Box className="w-full">
          <UrlQueryTabs
            defaultTab="movements"
            tabsPanelsContainerClassname="bg-neutral-50"
            tabs={[
              {
                tabContent: showMovements ? (
                  <Box className="flex items-center gap-1 px-2">
                    <Icon as={MdOutlineTimeline} />
                    {t("cases.tabs.movements")}
                  </Box>
                ) : undefined,
                tabParam: "movements",
                panelContent: (
                  <Box className="flex flex-col gap-4">
                    {isCaseEditable && (
                      <UserAccess permission="movements-create">
                        <Button
                          size="sm"
                          className="ml-auto"
                          onClick={() => {
                            setIsCreateMovementModalOpen(true);
                          }}
                        >
                          {t("cases.add_movements")}
                        </Button>
                      </UserAccess>
                    )}
                    {!isLoading && (
                      <CaseMovementsTable
                        caseId={caseId}
                        isEditable={isCaseEditable}
                        availableUsers={availableUsers}
                      />
                    )}
                  </Box>
                ),
              },
              {
                tabContent: (
                  <Box className="flex items-center gap-1 px-2">
                    <Icon as={MdPeopleOutline} />
                    {t("cases.tabs.users")}
                  </Box>
                ),
                tabParam: "users",
                panelContent: !isLoading && (
                  <CaseUsersTable caseId={caseId} isEditable={isCaseEditable} />
                ),
              },
              {
                tabContent: (
                  <Box className="flex items-center gap-1 px-2">
                    <Icon as={MdOutlineEvent} />
                    {t("events.events")}
                    {!!nextEvents?.length && (
                      <Badge color="danger">{nextEvents.length}</Badge>
                    )}
                  </Box>
                ),
                tabParam: "events",
                panelContent: (
                  <Box className="flex flex-col gap-3">
                    {isCaseEditable && (
                      <UserAccess permission="events-create">
                        <Box className="flex justify-end gap-4">
                          <CreateEventButton
                            resourceId={caseId}
                            resourceType="cases"
                            availableEventUsers={availableUsers}
                          />
                        </Box>
                      </UserAccess>
                    )}
                    <EventTable
                      title={t("events.next_events")}
                      events={nextEvents}
                      isLoading={isLoadingNextEvents || isFetchingNextEvents}
                      pagination={nextEventsPagination}
                      nextPage={nextEventsNextPage}
                      prevPage={nextEventsPrevPage}
                      onChangePageRows={onChangeNextEventsPageRows}
                      isEditable={isCaseEditable}
                      availableEventUsers={availableUsers}
                    />
                    <Box className="mb-2" />
                    <EventTable
                      title={t("events.past_events")}
                      events={pastEvents}
                      isLoading={isLoadingPastEvents || isFetchingPastEvents}
                      pagination={pastEventsPagination}
                      nextPage={pastEventsNextPage}
                      prevPage={pastEventsPrevPage}
                      onChangePageRows={onChangePastEventsPageRows}
                      isEditable={isCaseEditable}
                      availableEventUsers={availableUsers}
                    />
                  </Box>
                ),
              },
              {
                tabContent: (
                  <UserAccess permission="tasks-read">
                    <TaskTabButton caseId={caseId} />
                  </UserAccess>
                ),
                tabParam: "tasks",
                panelContent: (
                  <UserAccess permission="tasks-read">
                    <TaskTabTables
                      availableUsers={availableUsers}
                      isCaseEditable={isCaseEditable}
                      caseId={id as string}
                    />
                  </UserAccess>
                ),
              },
              {
                tabContent: canShowCustomFields ? (
                  <Box className="flex items-center gap-1">
                    <Icon as={MdInfoOutline} />
                    {t("cases.tabs.extras")}
                  </Box>
                ) : undefined,
                tabParam: "extras",
                panelContent: canShowCustomFields ? (
                  <Box className="flex flex-col gap-3">
                    {Object.entries(caseData?.custom_data || {}).map(
                      ([key, value]) =>
                        customFieldInDetails.includes(key) ? null : (
                          <Text
                            key={key}
                            as="span"
                            className="flex items-center gap-1"
                          >
                            <Text as="strong" className="font-semibold">
                              {key}:
                            </Text>
                            {getCustomFieldValueFormatted(
                              value,
                              customFields.find((field) => field.name === key)
                                ?.type,
                            )}
                          </Text>
                        ),
                    )}
                  </Box>
                ) : undefined,
              },
            ]}
          />
        </Box>
      </Box>

      <CreateMovementModal
        isOpen={isCreateMovementModalOpen}
        onClose={() => setIsCreateMovementModalOpen(false)}
        onSubmit={(data) => {
          handleCreateMovement(data);
          setIsCreateMovementModalOpen(false);
        }}
      />

      <EditCaseModal
        isOpen={!!editCase}
        onClose={() => setEditCase(null)}
        caseId={editCase}
      />
    </PageContainer>
  );
};
