import { fetcher } from "@/utils/fetchers/fetcher";

import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateUser, User } from "../types/User";
import { GET_USERS_FETCH_KEY } from "./useGetUsers";
import { GET_CURRENT_USER_FETCH_KEY } from "../../session/useCases/useCurrentUser";

export const updateUser = async ({
  userId,
  data,
}: {
  userId: User["id"];
  data: UpdateUser;
}) => {
  const res = await fetcher.put(`/users/${userId}`, {
    user: data,
  });

  return res.data;
};

export const useUpdateUser = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: updateUser,

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_USERS_FETCH_KEY],
      });

      // TODO evitar que en cada actualización de usuario se haga una petición para obtener el usuario actual
      await queryClient.invalidateQueries({
        queryKey: [GET_CURRENT_USER_FETCH_KEY],
      });

      toast.add({
        content: "Datos de usuario actualizados",
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: "Error al actualizar los datos del usuario",
        status: "danger",
      });
    },
  });

  return {
    updateUser: mutate,
    isPending,
  };
};

