import { Select } from "@suit-ui/react";
import { useGetRoles } from "../useCases/useGetRoles";

export interface UserRoleSelectProps {
  value: string;
  onChange: (value: string) => void;
  rolesOpts?: { label: string; value: string }[];
}

export const UserRoleSelect = ({
  value,
  onChange,
  rolesOpts,
}: UserRoleSelectProps) => {
  const { roles } = useGetRoles();

  const defaultRolesOpts = roles
    .filter((role) => role.type !== "system")
    .map((role) => ({
      label: role.name,
      value: role.id,
    }));

  return (
    <Select
      menuPosition="fixed"
      valueAsObject={false}
      value={roles.find((role) => role.id === value)?.id}
      options={rolesOpts ?? defaultRolesOpts}
      onChange={(val: string) => onChange(val)}
    />
  );
};
