import {
  Box,
  Button,
  Dropdown,
  FormControl,
  IconButton,
  Input,
  Modal,
  Select,
  Switch,
  Table,
} from "@suit-ui/react";
import { MdMoreVert } from "react-icons/md";
import { useState } from "react";
import { useGetUsers } from "../useCases/useGetUsers";
import { format } from "date-fns";
import { useGetRoles } from "../useCases/useGetRoles";
import { useUpdateUser } from "../useCases/useUpdateUser";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { User } from "../types/User";
import { useTranslation } from "react-i18next";
import { useUpdateUserPassword } from "@/features/session/useCases/useUpdateUserPassword";
import { useTenantData } from "@/features/session/useCases/useTenantData";
import { CreateUserModal } from "./CreateUserModal";
import { UserAccess } from "@/features/session/components/UserAccess";
import clsx from "clsx";
import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import { DeleteUserConfirmModal } from "./DeleteUserConfirmModal";
import { trackEvent } from "@/utils/analytics/trackEvent";

const schema = z
  .object({ newPassword: z.string({ description: "field_required" }) })
  .required({ newPassword: true });

type ChangePassFormData = z.infer<typeof schema>;

export const UsersTable = () => {
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const { getFFValue } = useTenantData();
  const { users } = useGetUsers();
  const { roles } = useGetRoles();
  const { data: currUser } = useCurrentUser();
  const { updateUser } = useUpdateUser();
  const { updateUserPassword: updatePassword, isPending: isUpdatingPassword } =
    useUpdateUserPassword();

  const canManageInactiveUsers = getFFValue("enable_inactive_user_management");
  const canAdminChangePasswords = getFFValue("enable_admin_change_password");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ChangePassFormData>({
    resolver: zodResolver(schema),
  });

  const { t } = useTranslation();

  const [updateUserPassword, setUpdateUserPassword] = useState<User | null>(
    null,
  );
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);

  const getUserRole = (i: User) => roles.find((role) => role.id === i.role_id);

  const usersFiltered = showInactiveUsers
    ? users.sort((a, b) => {
        if (a.active === b.active) {
          return 0;
        }
        return a.active ? -1 : 1;
      })
    : users.filter((user) => user.active);

  const rolesOpts = roles
    .map((role) => ({
      label: role.name,
      value: role.id,
      type: role.type,
    }))
    .filter((role) => role.type !== "system");

  const rolesEditableOpts = rolesOpts.filter((role) => {
    return role.type !== "guest";
  });

  const currUserRole = currUser && getUserRole(currUser);

  return (
    <Box className="flex flex-col gap-4">
      <Box className="flex justify-between items-center gap-4">
        {canManageInactiveUsers && (
          <Switch
            checked={showInactiveUsers}
            onChange={() => setShowInactiveUsers((prev) => !prev)}
          >
            {t("users.show_inactive_users")}
          </Switch>
        )}

        <UserAccess permission="users-create">
          <Button
            className="ml-auto"
            onClick={() => setIsCreateUserModalOpen(true)}
          >
            {t("users.create_user")}
          </Button>
        </UserAccess>
      </Box>

      <Table.Container className="rounded-md" variant="elevated">
        <Table>
          <Table.Head className="bg-primary-800 text-neutral-50">
            <Table.Row className="text-neutral-50">
              <Table.Header>{t("users.table.name")}</Table.Header>
              <Table.Header>{t("users.table.email")}</Table.Header>
              <Table.Header>{t("users.table.created_at")}</Table.Header>
              <Table.Header>{t("users.table.created_by")}</Table.Header>
              <Table.Header>{t("users.table.user_profile")}</Table.Header>
              <Table.Header />
            </Table.Row>
          </Table.Head>
          <Table.Body className="bg-neutral-50">
            {usersFiltered.map((item) => {
              const userRoleType = getUserRole(item)?.type;
              const disableChangeRole =
                !item.active ||
                userRoleType === "system" ||
                userRoleType === "guest" ||
                (currUserRole?.type !== "admin" && userRoleType === "admin");

              if (userRoleType === "system") return null;

              return (
                <Table.Row
                  key={item.id}
                  className={clsx(
                    "even:bg-neutral-100 odd:bg-neutral-50",
                    !item.active && "text-neutral-400",
                  )}
                >
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell>{item.email}</Table.Cell>
                  <Table.Cell>
                    {format(new Date(item.created_at), "dd/MM/yyyy")}
                  </Table.Cell>
                  <Table.Cell>
                    {item.created_by_id
                      ? users.find((user) => user.id === item.created_by_id)
                          ?.name
                      : ""}
                  </Table.Cell>
                  <Table.Cell>
                    <Select
                      menuPosition="fixed"
                      valueAsObject={false}
                      className="w-[180px]"
                      value={roles.find((role) => role.id === item.role_id)?.id}
                      options={
                        userRoleType === "guest" ? rolesOpts : rolesEditableOpts
                      }
                      onChange={(value: string) => {
                        updateUser({
                          userId: item.id,
                          data: {
                            role: value,
                            active: item.active,
                            name: item.name,
                          },
                        });
                        trackEvent("User role updated");
                      }}
                      disabled={disableChangeRole}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <UserAccess permission={["users-update", "users-destroy"]}>
                      <Dropdown placement="bottom-end">
                        <Dropdown.Button
                          as={IconButton}
                          icon={<MdMoreVert />}
                          variant="text"
                          color="neutral"
                        />

                        <Dropdown.List>
                          {canAdminChangePasswords &&
                            userRoleType !== "guest" && (
                              <UserAccess permission="users-password">
                                <Dropdown.Item
                                  onClick={() => setUpdateUserPassword(item)}
                                >
                                  Cambiar contraseña
                                </Dropdown.Item>
                              </UserAccess>
                            )}
                          <UserAccess permission="users-destroy">
                            <DeleteUserConfirmModal
                              user={item}
                              disabled={item.id === currUser?.id}
                            />
                          </UserAccess>
                        </Dropdown.List>
                      </Dropdown>
                    </UserAccess>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Table.Container>

      <CreateUserModal
        isOpen={isCreateUserModalOpen}
        onClose={() => setIsCreateUserModalOpen(false)}
      />

      <Modal
        isOpen={!!updateUserPassword}
        onClose={() => {
          setUpdateUserPassword(null);
          reset();
        }}
      >
        <Modal.Overlay />

        <Modal.Content
          as="form"
          onSubmit={handleSubmit((form) => {
            if (!updateUserPassword) return;

            updatePassword({
              userId: updateUserPassword?.id,
              password: form.newPassword,
            });
            trackEvent("User password updated");
            setUpdateUserPassword(null);
            reset();
          })}
        >
          <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
            Cambiar contraseña
          </Modal.Header>

          <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
          <Modal.Body className="flex flex-col gap-3">
            <FormControl required>
              <FormControl.Label>Nueva contraseña</FormControl.Label>

              <Input
                {...register("newPassword")}
                autoComplete="off"
                placeholder="******"
                type="password"
              />

              {!!errors.newPassword && (
                <FormControl.ErrorMessage>
                  {t(errors.newPassword.message ?? "")}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="text"
              className="mr-2"
              color="neutral"
              onClick={() => {
                setUpdateUserPassword(null);
                reset();
              }}
            >
              {t("ui.cancel")}
            </Button>
            <Button type="submit" loading={isUpdatingPassword}>
              {t("ui.save")}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Box>
  );
};
