import { fetcher } from "@/utils/fetchers/fetcher";
import { MovementComment } from "../types/MovementComment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET_MOVEMENT_COMMENTS_FETCH_KEY } from "./useGetcomments";
import { useTranslation } from "react-i18next";
import { useToast } from "@suit-ui/react";

interface CreateCommentProps {
  movementId: string;
  comment: MovementComment;
}

const createComment = async (params: CreateCommentProps) => {
  const response = await fetcher.post<MovementComment>(
    `/movements/${params.movementId}/comments`,
    {
      comment: params.comment,
    },
  );

  return response.data;
};

export const useCreateComment = (movementId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toast = useToast();

  return useMutation({
    mutationFn: createComment,
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: [GET_MOVEMENT_COMMENTS_FETCH_KEY, movementId],
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });
};
