import {
  Box,
  Button,
  FormControl,
  Icon,
  Input,
  Modal,
  Text,
  useToast,
} from "@suit-ui/react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { CreateUser, User } from "../types/User";
import { useCreateUser } from "../useCases/useCreateUser";
import { UserRoleSelect, UserRoleSelectProps } from "./UserRoleSelect";
import { USER_ROLES_IDS } from "../consts";
import { useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { trackEvent } from "@/utils/analytics/trackEvent";

const defaultUser: CreateUser = {
  email: "",
  name: "",
  role: USER_ROLES_IDS.ASSISTANT,
  active: true,
};

const schema = z
  .object({
    email: z
      .string({ description: "field_required" })
      .email({ message: "Correo invalido" }),
    name: z.string({ description: "field_required" }),
    role: z.string({ description: "field_required" }),
  })
  .required({
    email: true,
    name: true,
    role: true,
  });

type CaseFormData = z.infer<typeof schema>;

export interface CreateUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUserCreated?: (user: User) => void;
  rolesOpts?: UserRoleSelectProps["rolesOpts"];
}

export const CreateUserModal = ({
  isOpen,
  onClose,
  onUserCreated,
  rolesOpts,
}: CreateUserModalProps) => {
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CaseFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  });
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [guestLink, setGuestLink] = useState<string | null>(null);
  const { createUser, isPending } = useCreateUser();
  const toast = useToast();

  const onSubmit = async (data: CaseFormData) => {
    const res = await createUser({ ...defaultUser, ...data });
    trackEvent("User created");
    if (res.password) setNewPassword(res.password);
    else setGuestLink(res.invite || "");
    onUserCreated?.(res);
  };

  const handleOnClose = () => {
    setNewPassword(null);
    setGuestLink(null);
    onClose();
  };

  const creatingUser = !newPassword && !guestLink;

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      scrollBehavior="outside"
      closeOnOverlayClick={false}
      onAnimationComplete={({ isOpen: isModalOpen }) => {
        !isModalOpen && reset(defaultUser);
      }}
    >
      <Modal.Overlay />

      <Modal.Content as="form" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
          {creatingUser ? "Crear usuario" : "Nuevo usuario"}
        </Modal.Header>

        <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
        <Modal.Body>
          {!creatingUser && newPassword && (
            <Box className="flex flex-col items-center justify-center gap-10 mt-6">
              <Box className="flex flex-col justify-center ">
                <Text className="text-center text-lg font-semibold text-neutral-500">
                  Contraseña del usuario:
                </Text>

                <Button
                  variant="text"
                  className="self-center mt-2 text-2xl font-bold text-primary-400 mt-2 gap-2 items-center"
                  onClick={() => {
                    navigator.clipboard.writeText(newPassword);
                    toast.add({
                      content: "Contraseña copiada",
                      status: "success",
                    });
                  }}
                  title="Copiar contraseña"
                >
                  {newPassword}
                  <Icon as={MdContentCopy} size="5" />
                </Button>
              </Box>
            </Box>
          )}

          {!creatingUser && guestLink && (
            <Box className="flex flex-col items-center justify-center gap-10 mt-6">
              <Box className="flex flex-col justify-center gap-4">
                <Text className="text-center text-lg font-semibold text-neutral-500">
                  Enlace de invitación de usuario:
                </Text>

                <Button
                  variant="text"
                  className="self-center mt-2 text-2xl font-bold text-primary-400 mt-2 gap-2 items-center"
                  onClick={() => {
                    navigator.clipboard.writeText(guestLink);
                    toast.add({
                      content: "Link copiado",
                      status: "success",
                    });
                  }}
                  title={guestLink}
                >
                  Link
                  <Icon as={MdContentCopy} size="5" />
                </Button>
              </Box>
            </Box>
          )}

          {creatingUser && (
            <Box className="flex flex-col gap-3">
              <FormControl required>
                <FormControl.Label>Nombre</FormControl.Label>

                <Input
                  placeholder="Nuevo usuario"
                  autoComplete="off"
                  {...register("name")}
                />

                {!!errors.name && (
                  <FormControl.ErrorMessage>
                    {t(errors.name.message!)}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>

              <FormControl required isInvalid={!!errors.email}>
                <FormControl.Label>Correo</FormControl.Label>

                <Input
                  placeholder="correo@gmail.com"
                  autoComplete="off"
                  {...register("email")}
                />

                {!!errors.email && (
                  <FormControl.ErrorMessage>
                    {t(errors.email.message!)}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>

              <FormControl required>
                <FormControl.Label>Perfil de usuario</FormControl.Label>

                <Controller
                  control={control}
                  name="role"
                  render={({ field: { onChange, value } }) => (
                    <UserRoleSelect
                      value={value}
                      onChange={onChange}
                      rolesOpts={rolesOpts}
                    />
                  )}
                />

                {!!errors.role && (
                  <FormControl.ErrorMessage>
                    {t(errors.role.message!)}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            </Box>
          )}
        </Modal.Body>
        <Modal.Footer>
          {creatingUser ? (
            <>
              <Button
                className="mr-2"
                variant="text"
                color="neutral"
                onClick={handleOnClose}
              >
                {t("events.cancel")}
              </Button>
              <Button type="submit" loading={isPending}>
                {t("ui.create")}
              </Button>
            </>
          ) : (
            <Button onClick={handleOnClose}>{t("ui.agree")}</Button>
          )}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
