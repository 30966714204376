import { Breadcrumb } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { Link, useMatches } from "react-router-dom";

interface IMatches {
  id: string;
  handle: {
    crumb: (customName?: string) => {
      link: string;
      name: string;
      hasCustomName: boolean;
    };
  };
}

interface BreadcumbNavProps {
  customName?: string;
}

export function BreadcrumbNav({ customName }: BreadcumbNavProps) {
  const { t } = useTranslation();
  const matches = useMatches() as IMatches[];
  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => ({ id: match.id, ...match.handle.crumb(customName) }));

  const isCurrentPage = (crumbIndex: number) =>
    crumbs.length - 1 === crumbIndex;

  return (
    <Breadcrumb>
      {crumbs?.map((crumb, index) => (
        <Breadcrumb.Item key={crumb.id} isCurrentPage={isCurrentPage(index)}>
          <Breadcrumb.Link as={Link} to={crumb.link}>
            {crumb.hasCustomName ? customName : t(crumb.name)}
          </Breadcrumb.Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}
