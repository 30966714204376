import { Box, UploadProgress } from "@suit-ui/react";
import { useCreateTaskAttachment } from "../useCases/useCreateTaskAttachment";
import { getAttachmentIcon } from "../utils/getAttachmentIcon";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface FileAttachmentCreateProps {
  taskId: string;
  file: File;
  onFinish: () => void;
}

export function FileAttachmentCreate({
  taskId,
  file,
  onFinish,
}: FileAttachmentCreateProps) {
  const { t } = useTranslation();
  const { mutateAsync, progress, isSuccess, isError, onAbort, reset } =
    useCreateTaskAttachment();

  const stateProgress = () => {
    if (isSuccess) return "success";

    if (isError) return "error";

    return "active";
  };

  const handleCancelUpload = () => {
    onAbort();
    onFinish();
  };

  const uploadFile = async () => {
    await mutateAsync({
      taskId: taskId,
      attachment: {
        file,
      },
    });

    onFinish();
  };

  useEffect(() => {
    uploadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UploadProgress state={stateProgress()} value={progress}>
      <UploadProgress.Image>
        {getAttachmentIcon(file.name)}
      </UploadProgress.Image>
      <UploadProgress.Label>
        <Box className="text-ellipsis whitespace-nowrap overflow-hidden text-sm max-w-[155px]">
          {file.name}
        </Box>
      </UploadProgress.Label>
      <UploadProgress.Value />
      <UploadProgress.ErrorMsg>
        {t("attachments.upload_file_error")}
      </UploadProgress.ErrorMsg>
      <UploadProgress.Bar />
      <UploadProgress.CancelBtn onClick={handleCancelUpload} />
      <UploadProgress.RetryBtn
        onClick={() => {
          reset();
          uploadFile();
        }}
      />
    </UploadProgress>
  );
}
