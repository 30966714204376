import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetcher } from "../../../utils/fetchers/fetcher.ts";
import { useToast } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { Credential } from "../types/Credential.ts";
import { CredentialFormData } from "../types/CredentialFormData.ts";
import { GET_CREDENTIALS_KEY } from "./useGetCredentials.ts";

interface UpdateCredentialsParams {
  credParams: CredentialFormData;
  id: string;
  validatedPath: string;
}

const updateCredentials = async ({
  credParams,
  id,
  validatedPath,
}: UpdateCredentialsParams) => {
  const response = await fetcher.patch<Credential>(`/credentials/${id}`, {
    credential: { ...credParams, validated_path: validatedPath },
  });
  return response.data;
};

export const useUpdateCredentials = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCredentials,
    onSuccess: (cred) => {
      const credentials = queryClient.getQueryData([
        GET_CREDENTIALS_KEY,
      ]) as Credential[];
      const idx = credentials.findIndex((c) => c.id === cred.id);
      if (idx !== -1) {
        credentials[idx] = cred;
      } else {
        credentials.unshift(cred);
      }

      queryClient.setQueryData([GET_CREDENTIALS_KEY], credentials);

      if (!cred.is_duplicate) {
        toast.add({
          content: t("credentials.credential_updated_successfully"),
          status: "success",
        });
      }
    },

    onError: (args) => {
      console.error(args);
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });
};
