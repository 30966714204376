import { Button, Dialog, Dropdown, useDisclosure } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { Case } from "@/features/cases/types/Case";
import { useDeleteCase } from "../useCases/useDeleteCase";
import { trackEvent } from "@/utils/analytics/trackEvent";

interface DeleteCaseDropdownItemProps {
  kase: Case;
}

export const DeleteCaseDropdownItem: React.FC<
  DeleteCaseDropdownItemProps
> = ({ kase }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync, isPending } = useDeleteCase();

  const handleOnClose = () => {
    if (isPending) return;

    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync({ caseId: kase.id });
    trackEvent("Case deleted");
    onClose();
  };

  const handleOnOpen = (event: React.MouseEvent) => {
    event.stopPropagation();

    onOpen();
  };

  return (
    <>
      <Dropdown.Item
        className="text-danger-600 hover:bg-danger-100 active:(bg-danger-200 text-danger-700)"
        onClick={handleOnOpen}
      >
        {t("cases.delete")}
      </Dropdown.Item>

      <Dialog
        isOpen={isOpen}
        onClose={handleOnClose}
        status="danger"
        size="sm"
      >
        <Dialog.Overlay />

        <Dialog.Content>
          <Dialog.Header>{t("cases.delete")} {kase.code}</Dialog.Header>
          <Dialog.CloseButton />

          <Dialog.Body>{t("cases.delete_warning")}</Dialog.Body>
          <Dialog.Footer>
            <Button
              onClick={handleOnClose}
              variant="text"
              color="neutral"
              className="mr-2"
              size="sm"
            >
              {t("ui.cancel")}
            </Button>
            <Button size="sm" onClick={handleDelete} loading={isPending}>
              {t("cases.delete_confirm")}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
