import {
  Box,
  Button,
  FormControl,
  Icon,
  IconButton,
  Modal,
  Radio,
  TextArea,
  Tooltip,
  useDisclosure,
} from "@suit-ui/react";
import {
  MdOutlineAttachFile,
  MdOutlineFeedback,
  MdOutlineThumbDown,
  MdOutlineThumbUp,
} from "react-icons/md";
import { useFeedback } from "../useCases/useFeedback";
import { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface FeedbackSummaryAIProps {
  summary: JSX.Element;
  filename: string;
  attachmentId: string;
}

const schema = z.object({
  comment: z.string().min(1),
  feedback_type: z.string().min(1),
});

type FormData = z.infer<typeof schema>;

export function FeedbackSummaryAI({
  summary,
  filename,
  attachmentId,
}: FeedbackSummaryAIProps) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose: close } = useDisclosure();
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const { mutate, mutateAsync, isPending } = useFeedback();
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onSubmit",
  });

  const handleThumbs = (feedback_type: boolean) => {
    mutate({
      feedback_type,
      attachmentId,
    });

    if (feedback_type) {
      setIsLiked(true);
    } else {
      setIsDisliked(true);
    }
  };

  const onClose = () => {
    if (isPending) return;

    close();
  };

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      feedback_type: data.feedback_type === "yes",
      attachmentId,
      comment: data.comment,
    });

    close();
    reset();
  });

  return (
    <>
      <Tooltip label={t("attachments.leave_comments")} placement="bottom">
        <IconButton
          icon={<MdOutlineFeedback />}
          variant="text"
          size="sm"
          onClick={onOpen}
        />
      </Tooltip>

      <Tooltip label={t("attachments.good_info")} placement="bottom">
        <IconButton
          icon={<MdOutlineThumbUp />}
          variant="text"
          size="sm"
          disabled={isLiked}
          onClick={() => handleThumbs(true)}
        />
      </Tooltip>

      <Tooltip label={t("attachments.bad_info")} placement="bottom">
        <IconButton
          icon={<MdOutlineThumbDown />}
          variant="text"
          size="sm"
          disabled={isDisliked}
          onClick={() => handleThumbs(false)}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
        <Modal.Overlay />

        <Modal.Content as="form" onSubmit={onSubmit} noValidate>
          <Modal.Header className="bg-primary-800 flex gap-1 items-center text-neutral-50 rounded-t-md">
            <Icon as={MdOutlineAttachFile} size="5" className="mr-1" />{" "}
            {filename}
          </Modal.Header>

          <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
          <Modal.Body>
            {summary}

            <Box className="mt-8">
              <FormControl
                required
                isInvalid={!!errors.feedback_type}
                className="mb-4"
              >
                <FormControl.Label>
                  {t("attachments.feedback_type")}
                </FormControl.Label>

                <Controller
                  control={control}
                  name="feedback_type"
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  render={({ field: { ref, onBlur, ...field } }) => (
                    <Radio.Group {...field}>
                      <Box className="flex gap-6 mt-2">
                        <Radio value="yes">{t("ui.yes")}</Radio>
                        <Radio value="no">{t("ui.no")}</Radio>
                      </Box>
                    </Radio.Group>
                  )}
                />

                {!!errors.feedback_type && (
                  <FormControl.ErrorMessage>
                    {t("form_errors.field_required")}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>

              <FormControl required isInvalid={!!errors.comment}>
                <FormControl.Label>
                  {t("attachments.comments")}
                </FormControl.Label>

                <TextArea {...register("comment")} />
                {!!errors.comment && (
                  <FormControl.ErrorMessage>
                    {t("form_errors.field_required")}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            </Box>
          </Modal.Body>
          <Modal.Footer className="gap-2">
            <Button
              size="sm"
              variant="text"
              className="flex gap-1"
              onClick={onClose}
            >
              {t("ui.cancel")}
            </Button>
            <Button size="sm" type="submit" className="flex gap-1">
              {t("attachments.send_comments")}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}
