import { Select } from "@suit-ui/react";
import { useGetCourts } from "../useCases/useGetCourts";
import { useMemo } from "react";

interface CourtSelectProps {
  onChange?: (value: string) => void;
  onBlur?: () => void;
  value?: string;
  disabled?: boolean;
}

const CourtSelect: React.FC<CourtSelectProps> = ({
  onChange,
  onBlur,
  value,
  disabled,
}) => {
  const { data: courts } = useGetCourts();
  const courtsOptions = useMemo(() => {
    return courts?.map((court) => ({
      label: court.name,
      value: court.id,
    }));
  }, [courts]);

  return (
    <Select
      menuPosition="fixed"
      valueAsObject={false}
      value={value}
      options={courtsOptions || []}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default CourtSelect;
