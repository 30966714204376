import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { Movement } from "../../cases/types/Movement";
import { fetcher } from "../../../utils/fetchers/fetcher.ts";
import { PaginatedResource } from "@/utils/pagination/PaginatedResource.ts";
import { usePagination } from "@/utils/pagination/usePagination";
import { FilterOperators } from "@/utils/filter/index.ts";
import qs from "qs";

export const GET_CASE_MOVEMENTS_FETCH_KEY = "get-case-movements";

export type MovementFilter = {
  case__active?: {
    [key in FilterOperators]?: boolean;
  };
  case__court_id?: {
    [key in FilterOperators]?: string[];
  };
  case__user_ids?: {
    [key in FilterOperators]?: string[];
  };
  official_date?: {
    [key in FilterOperators]?: string;
  };
  title?: {
    [key in FilterOperators]?: string;
  };
  case__code?: {
    [key in FilterOperators]?: string;
  };
  sort?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  custom_data?: any;
  q?: string;
};

const getMovements = async ({
  page = 1,
  pageSize,
  caseId: caseId,
  filter,
}: {
  page?: number;
  pageSize?: number;
  caseId?: string;
  filter?: MovementFilter;
}) => {
  const response = await fetcher<PaginatedResource<Movement[]>>(
    caseId ? `/cases/${caseId}/movements` : `/movements`,
    {
      params: {
        filter,
        pagination: {
          page,
          pageSize,
        },
      },
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: "brackets" }),
    },
  );
  return response.data;
};

interface UseGetMovementsProps {
  caseId?: string;
  filters?: MovementFilter;
  pagination?: {
    defaultValues?: {
      page?: number;
      pageSize?: number;
    };
  };
}

export const useGetMovements = ({
  caseId,
  filters,
  pagination,
}: UseGetMovementsProps = {}) => {
  const {
    page,
    pageSize,
    nextPage,
    prevPage,
    onChangePageRows,
    goToFirstPage,
  } = usePagination(pagination?.defaultValues);

  const queryKey = caseId
    ? [GET_CASE_MOVEMENTS_FETCH_KEY, caseId, page, pageSize, filters]
    : [GET_CASE_MOVEMENTS_FETCH_KEY, page, pageSize, filters];
  const query = useQuery({
    queryKey,
    queryFn: () =>
      getMovements({
        page,
        caseId,
        pageSize,
        filter: filters,
      }),
    placeholderData: keepPreviousData,
  });

  return {
    ...query,
    movements: query.data?.data || [],
    pagination: query.data?.meta.pagination,
    nextPage,
    prevPage,
    onChangePageRows,
    page,
    pageSize,
    queryKey,
    goToFirstPage,
  };
};
