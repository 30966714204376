import { ReactNode, useEffect, JSX } from "react";
import { Button, Checkbox, Dialog, useDisclosure } from "@suit-ui/react";

interface ConfirmCheckProps {
  isLoading?: boolean;
  onConfirm: () => void | Promise<unknown>;
  onConfirmAll?: () => void | Promise<unknown>;
  onUncheck: () => void | Promise<unknown>;
  onConfirmText?: string;
  onConfirmAllText?: string;
  onCancel?: () => void;
  title: string;
  body?: ReactNode;
  button?: JSX.Element | null;
  forceOpen?: boolean;
  buttonDisabled?: boolean;
}
const ConfirmCheck: React.FC<ConfirmCheckProps> = ({
  isLoading = false,
  onConfirm,
  onConfirmText,
  onConfirmAll,
  onUncheck,
  onConfirmAllText,
  title,
  body,
  button,
  forceOpen,
  onCancel,
  buttonDisabled = false,
}) => {
  const { onClose, onOpen: _onOpen, isOpen } = useDisclosure();

  const conditionalOnclose = () => {
    if (isLoading) return;
    onCancel?.();
    onClose();
  };

  const conditionalOnConfirmAll = () => {
    if (isLoading) return;

    onConfirmAll?.();
    onClose();
  };

  useEffect(() => {
    if (forceOpen) {
      _onOpen();
    }
  }, [forceOpen, _onOpen]);

  return (
    <>
      {button && (
        <Checkbox
          {...button.props}
          onChange={(e) => {
            if (!e.target.checked) {
              return onUncheck();
            }
            e.stopPropagation();
            _onOpen();
          }}
          disabled={buttonDisabled}
        />
      )}

      <Dialog
        isOpen={isOpen}
        onClose={conditionalOnclose}
        status="warning"
        size="sm"
      >
        <Dialog.Overlay />
        <Dialog.Content>
          <Dialog.Header>{title}</Dialog.Header>
          <Dialog.CloseButton />
          <Dialog.Body>{body}</Dialog.Body>
          <Dialog.Footer>
            {onConfirmAll && (
              <Button
                data-testid="cancel"
                onClick={conditionalOnConfirmAll}
                variant="text"
                color="neutral"
                className="mr-2"
              >
                {onConfirmAllText}
              </Button>
            )}
            <Button
              onClick={async () => {
                await onConfirm();
                onClose();
              }}
              loading={isLoading}
            >
              {onConfirmText}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default ConfirmCheck;
