import { Box, Placeholder, Text } from "@suit-ui/react";
import {
  MovementFilter,
  useGetMovements,
} from "@/features/movements/useCases/useGetMovements";
import { useEffect, useState } from "react";
import { groupBy } from "@/utils/groupBy";
import { parseOfficialDate } from "@/features/movements/useCases/parseOfficialDate";
import { useDateLocale } from "@/utils/i18n/useDateLocale";
import { Link } from "react-router-dom";
import { routes } from "@/router";
import { PlaceholderLoader } from "@/ui/PlaceholderLoader";
import { useTranslation } from "react-i18next";
import { trackEvent } from "@/utils/analytics/trackEvent";

export const OverviewMovementsTable = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<MovementFilter>({
    case__active: {
      eq: true,
    },
    custom_data: {},
  });
  const { movements, isLoading } = useGetMovements({
    pagination: {
      defaultValues: {
        pageSize: 6,
      },
    },
    filters,
  });
  const dateLocale = useDateLocale();

  useEffect(() => {
    setFilters({
      ...filters,
      case__active: { eq: true },
      custom_data: {},
      sort: ["official_date:desc"],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupedByOfficialDate = groupBy(
    movements,
    ({ official_date }) => official_date,
  );

  if (isLoading) {
    return (
      <Box className="flex flex-col h-[420px] overflow-hidden">
        {Array.from({ length: 3 }).map((_, index) => (
          <Box
            key={`last_movements_loader_${index}`}
            className="flex flex-col p-6 gap-2"
          >
            <PlaceholderLoader className="w-full h-4 mb-2" />
            <PlaceholderLoader className="w-full h-2" />
            <PlaceholderLoader className="w-full h-2" />
            <PlaceholderLoader className="w-full h-2" />
            <PlaceholderLoader className="w-full h-2" />
          </Box>
        ))}
      </Box>
    );
  }

  if (!movements.length) {
    return (
      <Box className="flex flex-col h-[420px] justify-center items-center px-8">
        <Placeholder>
          <Placeholder.Image>
            <Placeholder.EmptyIcon />
          </Placeholder.Image>
          <Placeholder.Content>
            <Placeholder.Title>{t("ui.empty_data")}</Placeholder.Title>
            <Placeholder.Description>
              {t("ui.empty_data_description")}
            </Placeholder.Description>
          </Placeholder.Content>
        </Placeholder>
      </Box>
    );
  }

  return (
    <Box className="max-h-[420px] overflow-y-auto">
      {Array.from(groupedByOfficialDate).map(([officialDate, movements]) => (
        <Box
          key={`last_movements_${officialDate}`}
          className="pt-4.5 pb-3 px-6 border-b border-neutral-300 last:border-b-0"
        >
          <Text as="h2" className="font-semibold mb-3 text-neutral-600">
            {parseOfficialDate(officialDate, "dd MMMM yyyy", dateLocale)}
          </Text>

          {movements.map((movement) => (
            <Box
              as={Link}
              to={`${routes.cases}/${movement.case?._id}`}
              key={`last_movements_movement_${movement.id}`}
              onClick={() => trackEvent("Home Page - Movement clicked")}
              className="text-sm block leading-5 my-2 text-neutral-900 hover:text-primary-600"
            >
              <Text as="span" className="font-semibold text-sm">
                {movement.title} -{" "}
              </Text>
              <Text as="span" className="text-sm">
                {movement.case?.title} ROL {movement.case?.code}
              </Text>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};
