import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const deleteCourt = async (id: string) => {
  const response = await fetcher.delete(`/courts/${id}`);

  return response.data;
};

export const useDeleteCourt = (id: string) => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteCourt(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["delete-court"],
      });

      toast.add({
        content: t("cases_data.courts.delete_success"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("cases_data.courts.delete_failed"),
        status: "danger",
      });
    },
  });
};