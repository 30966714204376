import Confirm from "@/ui/Confirm";
import { useTranslation } from "react-i18next";
import { Task } from "../types/Task";
import { useDeleteTask } from "../useCases/useDeleteTask";
import { TaskTypes } from "../useCases/useGetTasks";
import { trackEvent } from "@/utils/analytics/trackEvent";
import { JSX } from "react";

interface DeleteTaskProps {
  button: JSX.Element;
  task: Task;
  onConfirm?: () => void;
}

export function DeleteTask({ task, button, onConfirm }: DeleteTaskProps) {
  const { t } = useTranslation();
  const deleteTask = useDeleteTask({
    type: task.resource_type as TaskTypes,
    taskId: task._id,
    resourceId: task.resource_id,
  });

  return (
    <Confirm
      title={`${t("ui.delete")} ${task.title}`}
      onConfirm={async () => {
        await deleteTask.mutateAsync();
        trackEvent("Task deleted");
        onConfirm?.();
      }}
      onConfirmText={t("ui.delete")}
      button={button}
      body={t("tasks.confirm_delete_description")}
      isLoading={deleteTask.isPending}
    />
  );
}
