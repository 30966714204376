import { IconButton, IconButtonProps, useDisclosure } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { EventModal } from "./EventModal";
import { Event, EventForm } from "../types/Event";
import { useEditEvent } from "../useCases/useEditEvent";
import { MdOutlineEdit } from "react-icons/md";
import { trackEvent } from "@/utils/analytics/trackEvent";

interface EditEventIconButtonProps {
  event: Event;
  size?: IconButtonProps["size"];
  availableEventUsers?: string[];
}

export const EditEventIconButton: React.FC<EditEventIconButtonProps> = ({
  event,
  size = "sm",
  availableEventUsers = [],
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync, isPending } = useEditEvent(
    event.resource_type,
    event.resource_id,
    event.id,
  );

  const onSubmit = async (data: EventForm) => {
    await mutateAsync(data);
    trackEvent("Event updated");
  };

  const { start_date, end_date, summary, description, attendees, all_day } =
    event;

  const defaultValues: EventForm = {
    start_date: start_date ? new Date(start_date) : null,
    end_date: end_date ? new Date(end_date) : null,
    summary,
    description,
    attendees,
    all_day,
  };

  const handleOnOpen = (e: React.MouseEvent) => {
    e.stopPropagation();

    onOpen();
  };

  return (
    <>
      <IconButton
        onClick={handleOnOpen}
        variant="text"
        icon={<MdOutlineEdit />}
        color="neutral"
        size={size}
      />

      <EventModal
        title={t("events.edit_event")}
        submitButtonText={t("events.edit")}
        defaultValues={defaultValues}
        availableEventUsers={availableEventUsers}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        isPending={isPending}
      />
    </>
  );
};
