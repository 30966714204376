import { Box, Checkbox, Select, Table, Text } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { useUpdateSetting } from "../useCases/useUpdateSetting";
import { format, parse, addMinutes } from "date-fns";
import { NOTIFICATIONS_OPTS } from "../conts";
import { Setting } from "../types/Setting";

export interface NotificationsTableProps {
  settings: Setting | undefined;
}

// There are both times with leading zero and without it, so we need to sanitize the time
// Example: 9:00 and 09:00
const sanitizeTime = (time?: string) => {
  if (!time) return undefined;
  const parsedTime = parse(time, "HH:mm", new Date());
  return format(parsedTime, "HH:mm");
};

const generateTimeOptions = (
  notification: (typeof NOTIFICATIONS_OPTS)[number],
  startTime?: string,
) => {
  if (!notification.timeSelectable) return [];

  if (notification.timeRestricted) {
    if (!startTime) return [];

    const options = [];
    let currentTime = parse(startTime, "HH:mm", new Date());
    // start 1 hour later, to give space to the scraping process to finish
    currentTime = addMinutes(currentTime, 60);

    for (let i = 0; i <= 6; i += 1) {
      const formattedTime = format(currentTime, "HH:mm");
      options.push({ label: formattedTime, value: formattedTime });
      currentTime = addMinutes(currentTime, 30);
    }
    return options;
  }

  let currentTime: Date = new Date(0, 0, 0, 0, 0);
  const options = [];
  for (let i = 0; i < 48; i += 1) {
    const formattedTime = format(currentTime, "HH:mm");
    options.push({ label: formattedTime, value: formattedTime });
    currentTime = addMinutes(currentTime, 30);
  }
  return options;
};

export const NotificationsTable = ({ settings }: NotificationsTableProps) => {
  const { t } = useTranslation();
  const { updateSetting } = useUpdateSetting();

  const scrapeTime = sanitizeTime(settings?.merged.times_case_update);

  return (
    <Table className="w-full space-x-4">
      <Table.Row className="border-0 ">
        <Table.Header className="w-[600px]">
          <Text className="font-[700]">
            {t("notifications.configurable_notifications")}
          </Text>
        </Table.Header>
        <Table.Header>
          <Box className="flex flex-1 justify-center items-center">
            <Text className="font-[700]">
              {t("notifications.reception_time")}
            </Text>
          </Box>
        </Table.Header>
        <Table.Header>
          <Box className="flex flex-1 justify-center items-center">
            <Text className="font-[700]">{t("notifications.suscribed")}</Text>
          </Box>
        </Table.Header>
      </Table.Row>
      <Table.Body>
        {NOTIFICATIONS_OPTS.map((notification) => (
          <Table.Row className="border-0" key={notification.name}>
            <Table.Cell className="py-4">
              <Box>
                <Text className="font-semibold">{notification.name}</Text>
                <Text className="text-neutral-600 mt-0.5" size="sm">
                  {notification.description}
                </Text>
              </Box>
            </Table.Cell>
            <Table.Cell className="min-w-[140px] max-w-[180px]">
              <Box
                className="flex flex-1 justify-center items-center"
                data-testid={`select-${notification.alertCode}`}
              >
                {notification.timeSelectable && notification.alertCode && (
                  <Select
                    placeholder={t("ui.hour")}
                    valueAsObject={false}
                    value={
                      sanitizeTime(
                        settings?.merged[notification.alertCode] as string,
                      ) || ""
                    }
                    onChange={(value: string) => {
                      notification.alertCode &&
                        updateSetting({
                          code: notification.alertCode,
                          value,
                        });
                    }}
                    options={generateTimeOptions(notification, scrapeTime)}
                  />
                )}
              </Box>
            </Table.Cell>
            <Table.Cell>
              <Box className="flex flex-1 justify-center items-center">
                <Checkbox
                  checked={!!settings?.merged[notification.code]}
                  onChange={(e) => {
                    updateSetting({
                      code: notification.code,
                      value: e.target.checked,
                    });
                  }}
                />
              </Box>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
