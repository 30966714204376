import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { GET_CLIENTS_FETCH_KEY } from "./useGetClients";

const deleteClient = async (id: string) => {
  const response = await fetcher.delete(`/clients/${id}`);
  return response.data;
};

export const useDeleteClient = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (id: string) => {
      await deleteClient(id);
      return id;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_CLIENTS_FETCH_KEY],
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return {
    deleteClient: mutateAsync,
    isPending,
  };
};

