import { useState, useEffect } from "react";
import { useToast } from "@suit-ui/react";
import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";
import { CredentialValidationResult } from "../types/CredentialValidationResult";

export const GET_CREDENTIAL_VALIDATION_RESULT_KEY =
  "get-credential-validation-result";

export const useGetCredentialValidationResult = (key: string | null) => {
  const toast = useToast();
  const [refetchInterval, setRefetchInterval] = useState<number>(2000);
  const query = useQuery({
    queryKey: [GET_CREDENTIAL_VALIDATION_RESULT_KEY, key],
    queryFn: async () => {
      if (key == "skip") {
        return { jid: "", error: "" } as CredentialValidationResult;
      }

      const response = await fetcher<CredentialValidationResult>(
        `/scrapers/poll_credential_result?key=${key}`,
      );
      const result = response.data;

      if (result?.error) {
        setRefetchInterval(0);
        toast.add({
          content: result?.error,
          status: "danger",
        });
      }

      return result;
    },
    enabled: !!key,
    refetchInterval,
  });

  useEffect(() => {
    if (!key) {
      return;
    }

    setRefetchInterval(2000);
  }, [key]);

  return {
    ...query,
    credentialResult: query.data,
  };
};
