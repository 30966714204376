import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetcher } from "../../../utils/fetchers/fetcher.ts";
import { useToast } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { Credential } from "../types/Credential.ts";
import { CredentialFormData } from "../types/CredentialFormData.ts";
import { GET_CREDENTIALS_KEY } from "./useGetCredentials.ts";

interface CreateCredentialsParams {
  params: CredentialFormData;
  validatedPath: string;
}

const createCredentials = async ({
  params,
  validatedPath,
}: CreateCredentialsParams) => {
  const response = await fetcher.post<Credential>("/credentials", {
    credential: { ...params, validated_path: validatedPath },
  });
  return response.data;
};

export const useCreateCredentials = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCredentials,
    onSuccess: (cred) => {
      const credentials = queryClient.getQueryData([
        GET_CREDENTIALS_KEY,
      ]) as Credential[];
      const updatedCredentials = [
        cred,
        ...credentials.filter((c) => c.id !== cred.id),
      ];

      queryClient.setQueryData([GET_CREDENTIALS_KEY], updatedCredentials);

      if (!cred.is_duplicate) {
        toast.add({
          content: t("credentials.credential_created_successfully"),
          status: "success",
        });
      }
    },

    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });
};
