import { Select } from "@suit-ui/react";
import { forwardRef, useMemo } from "react";
import { useGetCredentials } from "@/features/credentials/useCases/useGetCredentials";

interface CredentialsTypesSelectProps {
  onChange?: (value: string[]) => void;
  value?: string;
  credentialTypeFilter?: string | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CredentialsSelect = forwardRef<any, CredentialsTypesSelectProps>(
  ({ onChange, value, credentialTypeFilter }, ref) => {
    const { data: credentials, isLoading } = useGetCredentials();
    const credentialsOptions = useMemo(() => {
      if (credentialTypeFilter) {
        return credentials
          ?.filter(
            ({ credential_type }) => credential_type === credentialTypeFilter,
          )
          .map(({ name, id }) => ({
            label: name,
            value: id,
          }));
      }

      return credentials?.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }, [credentials, credentialTypeFilter]);

    return (
      <Select
        ref={ref}
        menuPosition="fixed"
        valueAsObject={false}
        value={value}
        options={credentialsOptions}
        onChange={onChange}
        isLoading={isLoading}
        clearable
      />
    );
  },
);

export { CredentialsSelect };
