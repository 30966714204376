import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";
import { CaseSearch } from "../types/CaseSearch";
import { SearchParams } from "../types/SearchParams";

const getValidateSearch = async ({
  finder,
  search_params,
  credential_id,
}: SearchParams) => {
  const response = await fetcher.get<CaseSearch>("/scrapers/validate_search", {
    params: {
      ...search_params,
      finder,
      credential_id,
    },
  });
  return response.data;
};

export function useValidateSearch() {
  return useMutation({
    mutationFn: getValidateSearch,
  });
}
