import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControl,
  Input,
  Modal,
  Checkbox,
  Select,
  Card,
  Icon,
  Spinner,
} from "@suit-ui/react";
import { MdInfoOutline } from "react-icons/md";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CredentialFormData,
  credentialFormSchema,
} from "../types/CredentialFormData";
import { useEffect, useMemo, useState } from "react";
import UsersSelect from "./UsersSelect";
import { useGetCredentialsTypes } from "../useCases/useGetCredentialsTypes";
import { useValidateCredential } from "../useCases/useValidateCredential";
import { useGetCredentialValidationResult } from "../useCases/useGetCredentialValidationResult";

interface CredentialModalProps {
  credential: CredentialFormData;
  onSubmit: (
    cred: CredentialFormData,
    validatedKeyPath: string,
  ) => Promise<void>;
  isOpen?: boolean;
  onClose: () => void;
  isSaving?: boolean;
  isCreationMode: boolean;
}

const CredentialModal: React.FC<CredentialModalProps> = ({
  credential,
  isOpen,
  onClose,
  onSubmit,
  isCreationMode,
  isSaving = false,
}) => {
  const { t } = useTranslation();
  const [credentialResultKey, setCredentialResultKey] = useState<string | null>(
    null,
  );
  const [isWaitingCredResult, setIsWaitingCredResult] =
    useState<boolean>(false);
  const { credentialsTypes = {}, isLoading } = useGetCredentialsTypes();
  const { mutateAsync: validateCredential, isPending: isValidating } =
    useValidateCredential();
  const { credentialResult } =
    useGetCredentialValidationResult(credentialResultKey);
  const credentialTypesOptions = useMemo(() => {
    return Object.keys(credentialsTypes).map((credKey) => ({
      label: credentialsTypes[credKey].full_name,
      value: credentialsTypes[credKey].name,
    }));
  }, [credentialsTypes]);
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<CredentialFormData>({
    resolver: zodResolver(credentialFormSchema),
    mode: "onBlur",
    defaultValues: credential,
  });
  const { credential_type, global } = watch();
  const credentialTypeMetadata = useMemo(() => {
    return credentialsTypes[credential_type];
  }, [credentialsTypes, credential_type]);
  const findersOptions = useMemo(() => {
    return credentialTypeMetadata?.finders_data.map((finder) => ({
      label: finder.name,
      value: finder.code,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credential_type]);
  const credIsValidating = isValidating || isWaitingCredResult;

  const onSubmitModal = async (cred: CredentialFormData) => {
    setIsWaitingCredResult(true);
    const { credential_type: cred_type, username, password } = cred;
    if (password || isCreationMode) {
      const { key } = await validateCredential({
        credential_type: cred_type,
        username,
        password,
      });
      setCredentialResultKey(key);
    } else {
      setCredentialResultKey("skip");
    }
  };

  const handleSaveCredential = async () => {
    setIsWaitingCredResult(false);
    setCredentialResultKey(null);
    const cred = getValues();
    await onSubmit(cred, credentialResultKey || "");
    onClose();
  };

  useEffect(() => {
    setValue("finders", (credentialTypeMetadata?.finders || []) as never);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credential_type]);

  useEffect(() => {
    if (credentialResult === undefined) {
      return;
    }

    if (credentialResult === null) {
      setIsWaitingCredResult(true);
      return;
    }

    if (credentialResult?.error) {
      setIsWaitingCredResult(false);
      setCredentialResultKey(null);

      return;
    }

    handleSaveCredential();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialResult]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={!credIsValidating}
      closeOnOverlayClick={!credIsValidating}
      onAnimationComplete={({ isOpen: modalIsOpen }) => {
        if (!modalIsOpen) {
          reset({ ...credential });
        }
      }}
    >
      <Modal.Overlay />

      <Modal.Content as="form" onSubmit={handleSubmit(onSubmitModal)}>
        <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
          {isCreationMode
            ? t("credentials.create_modal.new_title")
            : t("credentials.create_modal.edit_title")}
        </Modal.Header>

        <Modal.CloseButton
          className="text-neutral-50 hover:text-primary-200 active:text-primary-300"
          disabled={credIsValidating}
        />
        <Modal.Body className="flex flex-col gap-3">
          <FormControl
            isInvalid={!!errors.credential_type}
            disabled={credIsValidating}
            required
          >
            <FormControl.Label className="mb-2">
              {t("credentials.create_modal.credential_type")}
            </FormControl.Label>

            <Controller
              control={control}
              name="credential_type"
              render={({ field }) => (
                <Select
                  {...field}
                  options={credentialTypesOptions}
                  isLoading={isLoading}
                  disabled={!isCreationMode}
                  menuPosition="fixed"
                  valueAsObject={false}
                  isSearchable
                  onChange={(value: string) => {
                    field.onChange(value);
                  }}
                />
              )}
            />

            <FormControl.ErrorMessage>
              {t(errors.credential_type?.message || "")}
            </FormControl.ErrorMessage>
          </FormControl>

          {credential_type?.length !== 0 && (
            <>
              <FormControl
                isInvalid={!!errors.finders}
                disabled={credIsValidating}
                required
              >
                <FormControl.Label className="mb-2">
                  {t("credentials.create_modal.finders_label")}
                </FormControl.Label>

                <Controller
                  control={control}
                  name="finders"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={findersOptions}
                      isLoading={isLoading}
                      menuPosition="fixed"
                      valueAsObject={false}
                      onChange={(value: string) => {
                        field.onChange(value);
                      }}
                      isSearchable
                      isMulti
                    />
                  )}
                />

                <FormControl.ErrorMessage>
                  {t(errors.finders?.message || "")}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!errors.name}
                disabled={credIsValidating}
                required
              >
                <FormControl.Label className="mb-2">
                  {t("credentials.create_modal.name_label")}
                </FormControl.Label>

                <Input {...register("name")} formNoValidate />

                <FormControl.ErrorMessage>
                  {t(errors.name?.message || "")}
                </FormControl.ErrorMessage>
              </FormControl>

              <Box className="flex w-full">
                <FormControl
                  className="w-1/2 mr-2"
                  isInvalid={!!errors.username}
                  disabled={credIsValidating || !isCreationMode}
                  required
                >
                  <FormControl.Label className="mb-2">
                    {credentialTypeMetadata?.username}
                  </FormControl.Label>

                  <Input {...register("username")} />

                  <FormControl.ErrorMessage>
                    {t(errors.username?.message || "")}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl
                  className="w-1/2 ml-2"
                  isInvalid={!!errors.password}
                  disabled={credIsValidating}
                  required={isCreationMode}
                >
                  <FormControl.Label className="mb-2">
                    {credentialTypeMetadata?.password}
                  </FormControl.Label>

                  <Input {...register("password")} type="password" />

                  <FormControl.ErrorMessage>
                    {t(errors.password?.message || "")}
                  </FormControl.ErrorMessage>
                </FormControl>
              </Box>

              <Box className="grid grid-cols-2 grid-flow-col gap-4">
                {credentialTypeMetadata?.search_params?.map((searchParam) => (
                  <FormControl key={`searchParams-${searchParam}`}>
                    <FormControl.Label className="mb-2">
                      {searchParam}
                    </FormControl.Label>

                    <Input
                      {...register(`search_params.${searchParam}`)}
                      disabled={credIsValidating}
                    />
                  </FormControl>
                ))}
              </Box>

              <FormControl className="mt-6" disabled={credIsValidating}>
                <Box className="text-lg font-semibold mb-4">
                  {t("credentials.shared_credential_title")}
                </Box>

                <FormControl.Label className="mb-2">
                  {t("credentials.shared_credential_users_label")}
                </FormControl.Label>

                <Box className="flex">
                  <Box className="w-2/3">
                    <Controller
                      control={control}
                      name="user_ids"
                      render={({ field }) => (
                        <UsersSelect
                          {...field}
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                          disabled={global}
                        />
                      )}
                    />
                  </Box>

                  <Checkbox
                    className="w-1/3 ml-5"
                    checked={global}
                    {...register("global")}
                    onChange={(e) => {
                      const value = e.target.checked;
                      setValue("global", value);
                    }}
                    disabled={credIsValidating}
                  >
                    {t("credentials.global_label")}
                  </Checkbox>
                </Box>
              </FormControl>
            </>
          )}
        </Modal.Body>

        <Modal.Footer className="items-center">
          {credIsValidating && (
            <Card className="flex flex-row mr-auto bg-primary-100 border-primary-400 p-2 justify-center items-center">
              <Icon as={MdInfoOutline} className="flex" />
              <Box
                as="span"
                className="flex ml-2 text-primary-800 font-regular"
              >
                {t("credentials.validating_credential")}
              </Box>
              <Spinner size="8" className="ml-2" />
            </Card>
          )}

          <Button
            onClick={onClose}
            variant="text"
            color="neutral"
            disabled={credIsValidating || isSaving}
            className="mr-2"
          >
            {t("ui.cancel")}
          </Button>

          <Button type="submit" disabled={credIsValidating || isSaving}>
            {isCreationMode ? t("ui.create") : t("ui.save")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default CredentialModal;
