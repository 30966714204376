import { Box, IconButton, Modal, Text, useDisclosure } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { PriorityDots } from "./PriorityDots";
import { AvatarUsersGroup } from "./AvatarUsersGroup";
import { Task } from "../types/Task";
import { formatDate } from "../utils/formatDate";
import { DeleteTask } from "./DeleteTask";
import { EditTask } from "./EditTask";
import { EditableTaskStatus } from "./EditableTaskStatus";
import { UserAccess } from "@/features/session/components/UserAccess";
import { useGetCase } from "@/features/cases/useCases/useGetCase";
import { useState, JSX } from "react";
import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import { useGetCourts } from "@/features/cases/useCases/useGetCourts";
import { Comments } from "./Comments";
import { TaskAttachments } from "./TaskAttachments";
import { trackEvent } from "@/utils/analytics/trackEvent";

interface ViewTaskProps {
  button: JSX.Element;
  task: Task;
}

export function ViewTask({ button, task }: ViewTaskProps) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: currentUser } = useCurrentUser();
  const [isEnableCase, setIsEnableCase] = useState(false);
  const { courtMap } = useGetCourts();
  const kase = useGetCase(task?.case?.id, {
    refetchOnMount: false,
    enabled: isEnableCase,
  });

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOpen();
    setIsEnableCase(true);
    trackEvent("Task details opened");
  };

  const isCaseActive = kase?.data?.active;

  const isEditableCase = isCaseActive && currentUser?.role.type !== "guest";

  return (
    <>
      <button.type {...button.props} onClick={handleOpen} />

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
        <Modal.Overlay />

        <Modal.Content>
          <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
            {t("tasks.view_task")}
          </Modal.Header>
          <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />

          <Modal.Body>
            <Box className="flex justify-between">
              <Box>
                <Text as="h2" className="text-sm">
                  {`${task.case?.code} - ${
                    courtMap.get(task.case?.court_id as string)?.name || ""
                  }`}
                  <Text as="span" className="block">
                    {task.case?.title}
                  </Text>
                </Text>
                <Text as="h1" className="mt-3 font-semibold text-lg">
                  {task?.title}
                </Text>
              </Box>

              <Box className="flex gap-1 shrink-0 grow-0">
                {isEditableCase && (
                  <>
                    <UserAccess permission="tasks-update">
                      <EditTask
                        task={task}
                        button={
                          <IconButton
                            color="neutral"
                            variant="text"
                            icon={<MdOutlineEdit />}
                          />
                        }
                      />
                    </UserAccess>

                    <UserAccess permission="tasks-destroy">
                      <DeleteTask
                        task={task}
                        onConfirm={onClose}
                        button={
                          <IconButton
                            color="neutral"
                            variant="text"
                            icon={<MdOutlineDelete />}
                          />
                        }
                      />
                    </UserAccess>
                  </>
                )}
              </Box>
            </Box>

            <Box className="flex gap-5 items-center mt-5">
              <EditableTaskStatus isEnabled={isEditableCase} task={task} />

              <PriorityDots priority={task?.priority} />
            </Box>

            <Box className="mt-2.5 flex items-center justify-between">
              <Box className="flex text-xs gap-1">
                <Box as="span">
                  <Box as="strong" className="font-semibold">
                    {t("tasks.start_date")}:
                  </Box>{" "}
                  {formatDate(task.start_date, t("date_formats.date"))}
                </Box>
                <Box as="span">/</Box>
                <Box as="span">
                  <Box as="strong" className="font-semibold">
                    {t("tasks.end_date")}:
                  </Box>{" "}
                  {formatDate(task.end_date, t("date_formats.date"))}
                </Box>
              </Box>

              <AvatarUsersGroup
                className="flex"
                size="xs"
                ids={task?.asignees}
              />
            </Box>

            {!!task?.description && (
              <Text className="mt-8 text-sm">{task?.description}</Text>
            )}

            <UserAccess permission="attachments-read">
              <TaskAttachments isEditable={isCaseActive} task={task} />
            </UserAccess>

            <UserAccess permission="comments-read">
              <Comments isEditable={isCaseActive} task={task} />
            </UserAccess>
          </Modal.Body>
          <Modal.Footer />
        </Modal.Content>
      </Modal>
    </>
  );
}
