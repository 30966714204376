import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { CurrentUser } from "../types/CurrentUser";

export const GET_CURRENT_USER_FETCH_KEY = "get_current_user";

const getCurrentUser = async () => {
  const response = await fetcher.get<CurrentUser>("/users/profile");
  return response.data;
};

interface UseCurrentUserProps {
  enabled?: boolean;
}

export const useCurrentUser = ({ enabled = true }: UseCurrentUserProps = {}) =>
  useQuery({
    queryKey: [GET_CURRENT_USER_FETCH_KEY],
    queryFn: getCurrentUser,
    refetchOnMount: false,
    enabled,
  });
