import { Box } from "@suit-ui/react";
import { Sidebar } from "./Sidebar";

interface ProfileLayoutProps {
  children?: React.ReactNode;
}

export function ProfileLayout({ children }: ProfileLayoutProps) {
  return (
    <Box className="flex flex-wrap h-full w-full bg-neutral-100">
      <Sidebar />

      <Box className="flex items-center justify-center flex-1">{children}</Box>
    </Box>
  );
}
