import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface createFeedbackProps {
  feedback_type: boolean;
  comment?: string;
  attachmentId: string;
}

export const createFeedback = async ({
  attachmentId,
  ...data
}: createFeedbackProps) => {
  const res = await fetcher.post(
    `/attachments/${attachmentId}/feedbacks`,
    data,
  );
  return res.data;
};

export function useFeedback() {
  const toast = useToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: createFeedback,
    onSuccess: async () => {
      toast.add({
        content: t("attachments.feedback_success"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("attachments.feedback_error"),
        status: "danger",
      });
    },
  });
}
