import { fetcher } from "@/utils/fetchers/fetcher";
import { Court, CreateCourt } from "../types/Court";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface CreateCourtProps {
  court: CreateCourt;
}

const createCourt = async (params: CreateCourtProps) => {
  const response = await fetcher.post<Court>(`/courts`, {
    court: params.court,
  });

  return response.data;
};

export const useCreateCourt = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCourt,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["create-court"],
      });

      toast.add({
        content: t("cases_data.courts.create_success"),
        status: "success",
      });
    },

    onError: () => {
      toast.add({
        content: t("cases_data.courts.create_failed"),
        status: "danger",
      });
    },
  });
};
