import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetcher } from "../../../utils/fetchers/fetcher.ts";
import { useToast } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { Credential } from "../types/Credential.ts";
import { GET_CREDENTIALS_KEY } from "./useGetCredentials.ts";

const deleteCredentials = async (id: string) => {
  const response = await fetcher.delete<Credential>(`/credentials/${id}`);
  return response.data;
};

export const useDeleteCredentials = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCredentials,
    onSuccess: (cred) => {
      const credentials = queryClient.getQueryData([
        GET_CREDENTIALS_KEY,
      ]) as Credential[];
      const filteredCredentials = credentials.filter((c) => c.id !== cred.id);

      queryClient.setQueryData([GET_CREDENTIALS_KEY], filteredCredentials);

      toast.add({
        content: t("credentials.credential_deleted_successfully"),
        status: "success",
      });
    },

    onError: (args) => {
      console.error(args);
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });
};
