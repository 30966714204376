import { Case } from "@/features/cases/types/Case";
import { FilterOperators } from "@/utils/filter";

export interface Event {
  id: string;
  resource_id: string;
  resource_type: EventTypes;
  summary: string;
  description: string;
  start_date: Date | null;
  end_date?: Date | null;
  all_day?: boolean;
  attendees?: string[];
  case?: Case;
  created_by?: string;
}

export type EventForm = Omit<Event, "id" | "resource_id" | "resource_type">;

export type EventTypes = "all" | "cases" | "movements";

export type EventUrl = {
  [key: string]: string;
};

export const GetEventUrl = (type: EventTypes, id?: string) => {
  const EventUrlsByType: EventUrl = {
    all: "/events",
    cases: `/cases/${id}/events`,
    movements: `/movements/${id}/events`,
  };

  return EventUrlsByType[type];
};

export type EventQueryParams = {
  filter?: {
    resourceId?: {
      [key in FilterOperators]?: string;
    };
    resourceType?: {
      [key in FilterOperators]?: EventTypes;
    };
    startDate?: {
      [key in FilterOperators]?: string;
    };
    endDate?: {
      [key in FilterOperators]?: string;
    };
  };
  sort?: string;
};

export const GetEventQueryParams = (params: EventQueryParams) => {
  let result = {};

  const { filter, sort } = params;

  if (filter) {
    const { resourceId, resourceType, startDate, endDate } = filter;

    if (resourceId) {
      for (const [key, value] of Object.entries(resourceId)) {
        result = {
          ...result,
          [`filter[resourceId][${key}]`]: value,
        };
      }
    }

    if (resourceType) {
      for (const [key, value] of Object.entries(resourceType)) {
        result = {
          ...result,
          [`filter[resourceType][${key}]`]: value,
        };
      }
    }

    if (startDate) {
      for (const [key, value] of Object.entries(startDate)) {
        result = {
          ...result,
          [`filter[startDate][${key}]`]: value,
        };
      }
    }

    if (endDate) {
      for (const [key, value] of Object.entries(endDate)) {
        result = {
          ...result,
          [`filter[endDate][${key}]`]: value,
        };
      }
    }
  }

  if (sort) {
    result = {
      ...result,
      sort,
    };
  }

  return result;
};
