import { Dropdown, Tag } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { Task, TaskStatus } from "../types/Task";
import { useMemo } from "react";
import { MdOutlineExpandMore } from "react-icons/md";
import { useEditTask } from "../useCases/useEditTask";
import { TaskTypes } from "../useCases/useGetTasks";
import { useUserAccess } from "@/features/session/useCases/useUserAccess";

const statusColors: Record<TaskStatus, string> = {
  todo: "danger",
  in_progress: "info",
  done: "success",
};

interface EditableTaskStatusProps {
  task: Task;
  isEnabled?: boolean;
}

export function EditableTaskStatus({
  task,
  isEnabled = true,
}: EditableTaskStatusProps) {
  const { t } = useTranslation();
  const { mutate, variables } = useEditTask({
    type: task.resource_type as TaskTypes,
    resourceId: task.resource_id,
    id: task._id,
  });

  const currentState = variables?.state || task.state;
  const canEdit = useUserAccess("tasks-update");

  const statusOptions: {
    value: TaskStatus;
    label: string;
  }[] = useMemo(
    () => [
      {
        value: "todo",
        label: t("tasks.status_map.todo"),
      },
      {
        value: "in_progress",
        label: t("tasks.status_map.in_progress"),
      },
      {
        value: "done",
        label: t("tasks.status_map.done"),
      },
    ],
    [t],
  );

  if (!canEdit || !isEnabled) {
    return (
      <Tag
        color={statusColors[currentState]}
        size="sm"
        className="whitespace-nowrap"
      >
        <Tag.Label>{t(`tasks.status_map.${currentState}`)}</Tag.Label>
      </Tag>
    );
  }

  return (
    <Dropdown placement="bottom">
      <Dropdown.Button>
        <Tag
          color={statusColors[currentState]}
          size="sm"
          className="whitespace-nowrap"
        >
          <Tag.Label>{t(`tasks.status_map.${currentState}`)}</Tag.Label>
          <Tag.Icon as={MdOutlineExpandMore} />
        </Tag>
      </Dropdown.Button>

      <Dropdown.List className="w-fit min-w-0">
        {statusOptions.map((option) => (
          <Dropdown.Item
            key={`tag-option-${option.value}`}
            className="flex justify-center"
            isFocused={currentState === option.value}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(e: any) => {
              e.stopPropagation();

              if (currentState === option.value) return;

              mutate({ state: option.value });
            }}
          >
            <Tag color={statusColors[option.value]} size="sm">
              <Tag.Label>{option.label}</Tag.Label>
            </Tag>
          </Dropdown.Item>
        ))}
      </Dropdown.List>
    </Dropdown>
  );
}
