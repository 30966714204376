import { IconButton, Table, Text, Button, Box } from "@suit-ui/react";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { useState } from "react";
import { Case } from "@/features/cases/types/Case";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { useGetCase } from "../useCases/useGetCase";
import { User } from "@/features/users/types/User";
import { AddCaseUserModal } from "./AddCaseUserModal";
import { useGetRoles } from "@/features/users/useCases/useGetRoles";
import { useUpdateCase } from "../useCases/useUpdateCase";
import { UserAccess } from "@/features/session/components/UserAccess";
import { AddCasePartyModal } from "./AddCasePartyModal";
import { useDeleteCaseParty } from "../useCases/useDeleteCaseParty";
import { CaseParty } from "../types/CaseParty";
import { EditCasePartyModal } from "./EditCasePartyModal";
import { useTenantData } from "@/features/session/useCases/useTenantData";
import clsx from "clsx";
import { TableBody } from "@/ui/TableBody";
import { useTranslation } from "react-i18next";
import Confirm from "@/ui/Confirm";
import { trackEvent } from "@/utils/analytics/trackEvent";

export interface CaseUsersTableProps {
  caseId: Case["id"];
  isEditable?: boolean;
}

export const CaseUsersTable = ({ caseId, isEditable }: CaseUsersTableProps) => {
  const [isEditMovementModalOpen, setIsEditMovementModalOpen] = useState<
    Case["id"] | null
  >(null);
  const [isEditPartyModalOpen, setIsEditPartyModalOpen] = useState<
    Case["id"] | null
  >(null);
  const { t } = useTranslation();
  const { deleteCaseParty } = useDeleteCaseParty();
  const { data: caseData, isLoading } = useGetCase(caseId);
  const { users } = useGetUsers();
  const { roles } = useGetRoles();
  const { updateCase } = useUpdateCase();
  const { getFFValue } = useTenantData();
  const [editCaseParty, setEditCaseParty] = useState<CaseParty | null>(null);
  const canShowInactiveUsers = getFFValue("show_inactive_users");

  const caseUsers =
    (
      caseData?.user_ids
        .map((userId) => users.find((u) => u.id === userId))
        .filter(Boolean) as User[]
    )?.filter((item) => !!item.active || canShowInactiveUsers) || [];

  const participants = caseData?.case_parties || [];

  return (
    <Box className="flex flex-col gap-10">
      <Box className="flex flex-col gap-4">
        <Box className="flex justify-between items-center gap-3">
          <Text className="text-lg font-semibold">Usuarios</Text>
          {isEditable && (
            <UserAccess permission="cases-update">
              <Button
                className="self-end"
                size="sm"
                onClick={() => setIsEditMovementModalOpen(caseId)}
              >
                {t("users.add_user")}
              </Button>
            </UserAccess>
          )}
        </Box>

        <Table.Container className="rounded-md" isLoading={isLoading}>
          <Table>
            <Table.Head className="bg-primary-200 text-neutral-900 font-semibold">
              <Table.Row>
                <Table.Header>Nombre</Table.Header>
                <Table.Header>Perfil de usuario</Table.Header>
                <Table.Header>Correo</Table.Header>
                {isEditable && <Table.Header>Opciones</Table.Header>}
              </Table.Row>
            </Table.Head>
            <TableBody
              title={t("ui.empty_data")}
              isEmpty={!isLoading && caseUsers.length === 0}
            >
              {caseUsers.map((item) => (
                <Table.Row
                  key={item.id}
                  className={clsx(
                    "even:bg-neutral-100 odd:bg-neutral-50",
                    !item.active && "opacity-30",
                  )}
                  {...{
                    title: item.active ? "" : "Usuario inactivo",
                  }}
                >
                  <Table.Cell className="w-[200px]">{item.name}</Table.Cell>
                  <Table.Cell>
                    {roles.find((role) => role.id === item.role_id)?.name}
                  </Table.Cell>
                  <Table.Cell>{item.email}</Table.Cell>
                  {isEditable && (
                    <Table.Cell>
                      <Confirm
                        title={t("ui.confirm_delete_title")}
                        onConfirm={() => {
                          if (!caseData) return;
                          updateCase({
                            ...caseData,
                            user_ids: caseData?.user_ids.filter(
                              (id) => id !== item.id,
                            ),
                          });
                          trackEvent("User deleted from case");
                        }}
                        onConfirmText={t("ui.delete")}
                        button={
                          <IconButton
                            icon={<MdDeleteOutline />}
                            size="sm"
                            variant="text"
                            color="neutral"
                            title="Eliminar usuario del caso"
                          />
                        }
                        body={t("ui.confirm_delete_description")}
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </TableBody>
          </Table>
        </Table.Container>
      </Box>

      <Box className="flex flex-col gap-4">
        <Box className="flex justify-between items-center gap-3">
          <Text className="text-lg font-semibold">Partes involucradas</Text>

          {isEditable && (
            <UserAccess permission="cases-update">
              <Button
                className="self-end"
                size="sm"
                onClick={() => setIsEditPartyModalOpen(caseId)}
              >
                Añadir partes
              </Button>
            </UserAccess>
          )}
        </Box>

        <Table.Container className="rounded-md" isLoading={isLoading}>
          <Table>
            <Table.Head className="bg-primary-200 text-neutral-900 font-semibold">
              <Table.Row>
                <Table.Header>Nombre</Table.Header>
                <Table.Header>Identificador</Table.Header>
                <Table.Header>Tipo</Table.Header>
                <Table.Header>Comentarios</Table.Header>
                {isEditable && <Table.Header>Opciones</Table.Header>}
              </Table.Row>
            </Table.Head>
            <TableBody
              title={t("ui.empty_data")}
              isEmpty={!isLoading && participants.length === 0}
            >
              {participants.map((item) => (
                <Table.Row
                  key={item._id}
                  className="even:bg-neutral-100 odd:bg-neutral-50"
                >
                  <Table.Cell className="w-[200px]">{item.name}</Table.Cell>
                  <Table.Cell>{item.identifier}</Table.Cell>
                  <Table.Cell>{item.party_type}</Table.Cell>
                  <Table.Cell>{item.comments}</Table.Cell>
                  {isEditable && (
                    <Table.Cell>
                      <IconButton
                        size="sm"
                        icon={<MdOutlineEdit />}
                        onClick={() => setEditCaseParty(item)}
                        variant="text"
                        color="neutral"
                        className="mr-1"
                      />
                      <Confirm
                        title={t("ui.confirm_delete_title")}
                        onConfirm={() => {
                          deleteCaseParty({
                            caseId: caseId,
                            casePartyId: item._id,
                          });
                          trackEvent("Case party deleted");
                        }}
                        onConfirmText={t("ui.delete")}
                        button={
                          <IconButton
                            icon={<MdDeleteOutline />}
                            size="sm"
                            variant="text"
                            color="neutral"
                          />
                        }
                        body={t("ui.confirm_delete_description")}
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </TableBody>
          </Table>
        </Table.Container>
      </Box>

      <AddCaseUserModal
        isOpen={isEditMovementModalOpen !== null}
        onClose={() => setIsEditMovementModalOpen(null)}
        caseId={caseId}
      />

      <AddCasePartyModal
        isOpen={!!isEditPartyModalOpen}
        onClose={() => setIsEditPartyModalOpen(null)}
        caseId={caseId}
      />

      <EditCasePartyModal
        isOpen={!!editCaseParty}
        onClose={() => setEditCaseParty(null)}
        caseId={caseId}
        defaultValues={editCaseParty}
      />
    </Box>
  );
};
