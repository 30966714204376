import { CaseForm } from "../CaseForm";
import { useCreateCase } from "../../useCases/useCreateCase";
import { useFormContext } from "react-hook-form";
import { CaseWithConnectionForm } from "../../types/CaseWithConnectionForm";
import { useNavigate } from "react-router-dom";
import { routes } from "@/router";

interface CaseDataStepProps {
  onStepComplete?: () => void;
  onSaveFailed: (error: string | null) => void;
}

const CaseDataStep = ({ onStepComplete, onSaveFailed }: CaseDataStepProps) => {
  const { mutateAsync, isPending } = useCreateCase();
  const { watch } = useFormContext<CaseWithConnectionForm>();
  const navigate = useNavigate();
  const ccase = watch("case_data");

  if (!ccase) {
    return;
  }
  const kase = {
    title: ccase.title,
    code: ccase.code,
    court_id: ccase.court_id,
    user_ids: ccase.user_ids,
    custom_data: ccase.custom_data,
    credential_id: ccase.credential_id,
  };

  return (
    <>
      <CaseForm
        onSave={async (formCase) => {
          const { id } = await mutateAsync({
            ...ccase,
            ...formCase,
            scraper_params: ccase?.scraper_params,
            key: ccase?.key,
          });
          onStepComplete?.();
          navigate(`${routes.cases}/${id}`);
        }}
        isLoading={isPending}
        ccase={kase}
        readonlyFields={ccase.readonly_fields}
        onSaveFailed={onSaveFailed}
      />
    </>
  );
};

export { CaseDataStep };
