import { Button, FormControl, Modal } from "@suit-ui/react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import UsersSelect from "@/features/credentials/components/UsersSelect";
import { useUpdateCase } from "../useCases/useUpdateCase";
import { Case } from "../types/Case";
import { useGetCase } from "../useCases/useGetCase";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { MdAdd } from "react-icons/md";
import { CreateUserModal } from "@/features/users/components/CreateUserModal";
import { useState } from "react";
import { useGetRoles } from "@/features/users/useCases/useGetRoles";
import { trackEvent } from "@/utils/analytics/trackEvent";

const defaultUser = {
  users: [],
};

const schema = z
  .object({
    users: z.string({ description: "field_required" }).array(),
  })
  .required({
    users: true,
  });

type CaseFormData = z.infer<typeof schema>;

export const AddCaseUserModal = ({
  isOpen,
  onClose,
  caseId,
}: {
  caseId: Case["id"];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm<CaseFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  });
  const { data: caseData } = useGetCase(caseId);
  const { updateCase, isPending } = useUpdateCase();
  const { users = [] } = useGetUsers();
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const { roles } = useGetRoles();

  const systemRoleId = roles.find((role) => role.type === "system")?.id;

  const filteredUsers = users.filter(
    (user) =>
      !caseData?.user_ids.includes(user.id) &&
      user.active &&
      user.role_id !== systemRoleId,
  );

  const onSubmit = async (data: CaseFormData) => {
    if (!caseData) return;

    const userIds = new Set([...caseData.user_ids, ...data.users]);

    await updateCase({
      ...caseData,
      user_ids: Array.from(userIds) as Case["user_ids"],
    });

    trackEvent("User added to case");
    handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        scrollBehavior="outside"
        onAnimationComplete={({ isOpen: isModalOpen }) => {
          if (!isModalOpen) {
            reset(defaultUser);
          }
        }}
      >
        <Modal.Overlay />

        <Modal.Content as="form" onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
            {t("users.add_user")}
          </Modal.Header>

          <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
          <Modal.Body className="flex flex-col gap-3">
            <FormControl required>
              <FormControl.Label>{t("users.users")}</FormControl.Label>

              <Controller
                control={control}
                name="users"
                render={({ field: { onChange, value } }) => (
                  <UsersSelect
                    value={value}
                    onChange={onChange}
                    users={filteredUsers}
                    required={true}
                  />
                )}
              />

              {!!errors.users && (
                <FormControl.ErrorMessage>
                  {t(errors.users.message!)}
                </FormControl.ErrorMessage>
              )}
            </FormControl>

            <Button
              variant="text"
              className="w-fit"
              leftIcon={<MdAdd />}
              onClick={() => setIsCreateUserModalOpen(true)}
            >
              {t("users.create_user")}
            </Button>
          </Modal.Body>
          <Modal.Footer className="gap-2">
            <Button variant="text" color="neutral" onClick={handleOnClose}>
              {t("ui.cancel")}
            </Button>

            <Button type="submit" loading={isPending}>
              {t("ui.add")}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <CreateUserModal
        isOpen={isCreateUserModalOpen}
        onUserCreated={(d) => {
          reset({ users: [...getValues("users"), d.id] });
        }}
        onClose={() => setIsCreateUserModalOpen(false)}
        rolesOpts={roles
          .filter((role) => role.type !== "system" && role.type !== "guest")
          .map((role) => ({
            label: role.name,
            value: role.id,
          }))}
      />
    </>
  );
};
