import { Box, Icon } from "@suit-ui/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";
import { Task } from "../types/Task";
import { FileAttachmentCreate } from "./FileAttachmentCreate";
import { ChangeEvent, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useGetTaskAttachments } from "../useCases/useGetTaskAttachments";
import { FileAttachment } from "./FileAttachment";
import { PlaceholderLoader } from "@/ui/PlaceholderLoader";
import { UserAccess } from "@/features/session/components/UserAccess";
import { trackEvent } from "@/utils/analytics/trackEvent";

interface TaskAttachmentsProps {
  task: Task;
  isEditable?: boolean;
}

interface ITempFiles {
  file: File;
  uploadId: string;
  isUploading?: boolean;
}

export function TaskAttachments({ task, isEditable }: TaskAttachmentsProps) {
  const { t } = useTranslation();
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const [uploadFiles, setUploadFiles] = useState<ITempFiles[] | []>([]);
  const attachments = useGetTaskAttachments(task._id);

  const inputId = `fileInput-${task._id}`;

  const handleFileUpload = async (filesList: FileList) => {
    const file = filesList[0];

    const fileUpdate: ITempFiles = {
      file,
      uploadId: uuidv4(),
    };
    setUploadFiles((prev) => [...prev, fileUpdate]);
    uploadRef.current!.value = "";
  };

  const handleUploadFinish = (uploadId: string) => () => {
    trackEvent("Attachment uploaded");
    setUploadFiles((prev) => prev.filter((file) => file.uploadId !== uploadId));
  };

  return (
    <Box className="my-5">
      {isEditable && (
        <UserAccess permission="attachments-create">
          <Box className="flex justify-end">
            <Box
              as="label"
              htmlFor={inputId}
              className={clsx(
                "inline-flex justify-center items-center border border-solid border-transparent bg-transparent rounded-sm whitespace-nowrap font-semibold space-x-1",
                "text-sm h-9 px-4 min-w-22 cursor-pointer text-primary-600",
                "hover:(text-primary-700 bg-primary-600/10)",
                "active:(text-primary-800 bg-primary-600/15)",
                "focus:outline-none focus-visible:outline-none",
                "focus-visible:(text-primary-800 bg-primary-600/20 ring-[1.5px] ring-offset-2 ring-primary-800/100)",
                "disabled:(bg-transparent text-neutral-400 cursor-not-allowed)",
              )}
            >
              <Box as="span" className="grid place-content-center">
                <Icon size="4.5" as={MdAdd} />
              </Box>

              {t("tasks.add_attachment")}
            </Box>

            <Box
              as="input"
              ref={uploadRef}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFileUpload(e.target.files!)
              }
              type="file"
              id={inputId}
              className="hidden"
            />
          </Box>
        </UserAccess>
      )}

      {attachments.isLoading && !uploadFiles.length && (
        <Box className="grid grid-cols-2 gap-3 mt-3">
          <PlaceholderLoader className="w-full h-[72px]" />
          <PlaceholderLoader className="w-full h-[72px]" />
        </Box>
      )}

      {(!!uploadFiles.length || !!attachments?.data) && (
        <Box className="grid grid-cols-2 gap-3 mt-3">
          {attachments?.data?.map((attachment) => (
            <FileAttachment
              taskId={task._id}
              file={attachment}
              key={attachment.id}
            />
          ))}

          {uploadFiles.map(({ file, uploadId }) => (
            <FileAttachmentCreate
              taskId={task._id}
              file={file}
              key={`${task._id}-${uploadId}`}
              onFinish={handleUploadFinish(uploadId)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
