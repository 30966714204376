import { useCurrentUser } from "../useCases/useCurrentUser";
import { UserPermission } from "@/features/users/types/UserPermission";

export interface UserAccessProps {
  children?: React.ReactNode;
  /**
   * - The permission required to access the children.
   * - If the permission is an array, the user will be able to access the children if they have at least one of the permissions.
   * - If the permission is not provided, the children will be accessible.
   */
  permission?: UserPermission | UserPermission[] | null;
  /**
   * Component to render when the user does not have the required permission.
   */
  fallback?: React.ReactNode;
}

export const UserAccess = ({
  children,
  permission,
  fallback,
}: UserAccessProps) => {
  const { data: currentUser } = useCurrentUser();

  const permissionList = Array.isArray(permission) ? permission : [permission];
  const hasSomePermission = permissionList?.some(
    (p) => p && currentUser?.role?.permissions?.includes(p),
  );

  if (!permission || hasSomePermission) {
    return children;
  }

  return fallback;
};

