import { User } from "@/features/users/types/User";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { Avatar, AvatarSizes, Tooltip } from "@suit-ui/react";

interface AvatarUsersGroupProps {
  ids: string[];
  className?: string;
  size?: AvatarSizes;
}

export function AvatarUsersGroup({
  ids = [],
  className,
  size = "sm",
}: AvatarUsersGroupProps) {
  const { findById } = useGetUsers();
  const users = ids.map((id) => findById(id)).filter(Boolean) as User[];

  return (
    <Avatar.Group size={size} variant="dark" max={3} className={className}>
      {users.map((user) => (
        <Tooltip key={`user_ids-id-${user?.id}`} label={user?.name}>
          <Avatar name={user?.name} />
        </Tooltip>
      ))}
    </Avatar.Group>
  );
}
