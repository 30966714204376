import { Box, Icon } from "@suit-ui/react";
import clsx from "clsx";

interface OverviewCardProps {
  children?: React.ReactNode;
  headerActions?: React.ReactNode;
  title?: string;
  headerIcon?: React.ElementType;
  headerColor?: string;
}

export function OverviewCard({
  children,
  headerActions,
  title,
  headerIcon,
  headerColor = "primary-200",
}: OverviewCardProps) {
  return (
    <Box className="bg-neutral-50 rounded-md shadow-lg overflow-hidden">
      <Box
        className={clsx(
          "flex justify-between py-3 px-6 items-center",
          `bg-${headerColor}`,
        )}
      >
        <Box className="flex items-center gap-2 text-primary-900 font-bold">
          {headerIcon && <Icon size="5" as={headerIcon} />}
          {title}
        </Box>
        {headerActions}
      </Box>

      {children}
    </Box>
  );
}
