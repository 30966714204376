import { PageContainer } from "@/ui/containers/PageContainer";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import { PageTitle } from "@/ui/texts/PageTitle";
import { Box, Tabs } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { CaseDataCourts } from "../components/CaseDataCourts";
import { useGetCustomFields } from "@/features/customFields/useCases/useGetCustomFields";
import { CustomFieldValuesTable } from "@/features/customFields/components/CustomFieldValuesTable";

export function CasesDataPage() {
  const { t } = useTranslation();

  const { customFields, isPending } = useGetCustomFields("Case");

  const variableSelectCustomFields =
    customFields?.filter((field) => field.type === "VARIABLE_SELECT") || [];

  if (isPending) return null;

  return (
    <PageContainer className="flex flex-col">
      <Box className="flex justify-between mb-4.5">
        <BreadcrumbNav />
      </Box>

      <PageTitle>{t("cases_data.title")}</PageTitle>

      <Box className="mt-4">
        <Box className="mr-auto text-neutral-600">
          {t("cases_data.description")}
        </Box>

        <Box className="mt-4">
          <Tabs>
            <Tabs.List>
              <Tabs.Tab>{t("cases_data.courts.title")}</Tabs.Tab>

              {variableSelectCustomFields.map((field) => (
                <Tabs.Tab key={field.id}>{field.name}</Tabs.Tab>
              ))}
            </Tabs.List>

            <Tabs.Panels className="bg-neutral-50">
              <Tabs.Panel>
                <CaseDataCourts />
              </Tabs.Panel>

              {variableSelectCustomFields.map((field) => (
                <Tabs.Panel key={field.id}>
                  <CustomFieldValuesTable customFieldId={field.id} />
                </Tabs.Panel>
              ))}
            </Tabs.Panels>
          </Tabs>
        </Box>
      </Box>
    </PageContainer>
  );
}
