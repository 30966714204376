import { useTranslation } from "react-i18next";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";

export const useDateLocale = () => {
  const { i18n } = useTranslation();

  switch (i18n.language) {
    case "es":
      return es;
    case "en":
      return en;
    default:
      return es;
  }
};
