import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateMovement, Movement } from "../../cases/types/Movement";
import { fetcher } from "../../../utils/fetchers/fetcher.ts";
import { useToast } from "@suit-ui/react";
import { GET_CASE_MOVEMENTS_FETCH_KEY } from "./useGetMovements.ts";

interface CreateMovementProps {
  movement: CreateMovement;
  caseId: string;
}

const createMovement = async (params: CreateMovementProps) => {
  const response = await fetcher.post<Movement>(
    `/cases/${params.caseId}/movements`,
    {
      movement: params.movement,
    },
  );

  return response.data;
};

export const useCreateMovement = (caseId?: string) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createMovement,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_CASE_MOVEMENTS_FETCH_KEY, caseId],
      });

      toast.add({
        content: "Movimiento creado exitosamente",
        status: "success",
      });
    },

    onError: () => {
      toast.add({
        content: "Error inesperado",
        status: "danger",
      });
    },
  });
};
