import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { TaskAttachment } from "../types/TaskAttachment";

export const GET_TASK_ATTACHMENTS_FETCH_KEY = "GET_TASK_ATTACHMENTS_FETCH_KEY";

const getAttachments = async (taskId: string) => {
  const response = await fetcher.get<TaskAttachment[]>(
    `/tasks/${taskId}/attachments`,
  );
  return response.data;
};

export const useGetTaskAttachments = (taskId: string) => {
  return useQuery({
    queryKey: [GET_TASK_ATTACHMENTS_FETCH_KEY, taskId],
    queryFn: () => getAttachments(taskId),
  });
};
