import { useTranslation } from "react-i18next";
import { Court } from "../types/Court";
import { useGetCourt } from "../useCases/useGetCourt";
import { useUpdateCourt } from "../useCases/useUpdateCourt";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { Button, FormControl, Input, Modal } from "@suit-ui/react";

const schema = z.object({
  name: z.string({ description: "field_required"}),
}).required({ name: true })

type CourtFormData = z.infer<typeof schema>;

export interface EditCourtModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  courtId?: Court["id"] | null;
}

export const EditCourtModal: React.FC<EditCourtModalProps> = ({
  isOpen,
  onClose,
  courtId,
}) => {
  const { t } = useTranslation();
  const { data, isLoading: isCourtLoading } = useGetCourt(courtId ?? "")
  const { updateCourt, isPending: isUpdating } = useUpdateCourt();

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<CourtFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  })

  const onSubmit = handleSubmit(async (form: CourtFormData) => {
    if (!data) return;

    await updateCourt({
      ...data,
      ...form,
    });

    onClose?.();
  })

  useEffect(() => {
    if (data) reset(data)

    // eslint-disable-next-line
  }, [data])

  return (
    <>
      {!isCourtLoading && 
        <Modal
          isOpen={isOpen}
          onClose={onClose}
        >
          <Modal.Overlay />
          
          <Modal.Content>
            <form onSubmit={onSubmit}>
              <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
                {t('cases_data.courts.edit_court_header')}
              </Modal.Header>

              <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
              <Modal.Body className="flex flex-col gap-3">
                <FormControl required className="flex-1">
                  <FormControl.Label>{t('cases_data.courts.name')}</FormControl.Label>

                  <Input {...register("name")} />
                </FormControl>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  onClick={onClose}
                  variant="text"
                  disabled={isUpdating}
                  className="mr-2"
                  color="neutral"
                >
                  {t('ui.cancel')}
                </Button>
                <Button type="submit" loading={isUpdating}>
                  {t('ui.save')}
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Content>
        </Modal>
      }
    </>
  );
};