import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import { User } from "@/features/users/types/User";
import { Avatar, Box, useTw, Text, Button, useTheme } from "@suit-ui/react";
import clsx from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Mention, MentionsInput } from "react-mentions";

interface CommentInputProps {
  usersSuggestions: User[];
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
}

export function CommentInput({
  usersSuggestions = [],
  value,
  onChange,
  onSubmit,
}: CommentInputProps) {
  const currentUser = useCurrentUser();
  const { t } = useTranslation();
  const tw = useTw();
  const { getProp } = useTheme();

  const suggestions = useMemo(() => {
    return usersSuggestions.map(({ id, name }) => ({
      id,
      name,
      display: `@${name}`,
    }));
  }, [usersSuggestions]);

  return (
    <Box className="mt-2.5 flex items-center gap-3">
      <Avatar
        size="xs"
        variant="dark"
        className="grow-0 shrink-0"
        name={currentUser?.data?.name}
      />

      <MentionsInput
        onChange={(_, newValue) => onChange(newValue)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSubmit();
          }
        }}
        value={value}
        placeholder={t("comments.placeholder")}
        style={{
          suggestions: {
            zIndex: getProp("zIndex.popover"),
            backgroundColor: "transparent",
          },
        }}
        className={tw(
          clsx(
            "h-10 text-md border bg-neutral-50 border-neutral-600 font-regular outline-none text-neutral-600 rounded-sm w-full",
            "placeholder:text-neutral-400",
            "hover:shadow-sm",
            "focus-visible:(border-[1.5px] border-primary-800)",
            "disabled:(text-neutral-400 border-neutral-300 bg-neutral-100 shadow-none ring-0)",
            "[&_input]:px-4 [&_input]:py-2 [&_input]:focus:(outline-0)",
            "[&>div>div]:(mt-2 pl-2) [&>div]:(pl-[7px])",
            "[&>*:nth-child(2)]:(pl-0 pt-0) [&>*:nth-child(2)>div]:(pl-0)",
          ),
        )}
        customSuggestionsContainer={(children) => {
          return (
            <Box
              className={clsx(
                "bg-neutral-50 shadow-md mt-0 overflow-hidden text-neutral-900",
                "border border-solid border-neutral-100 rounded-md",
                "overflow-y-auto max-h-[300px]",
                "[&_li[aria-selected='true']]:(:bg-primary-400 text-neutral-50)",
              )}
            >
              {children}
            </Box>
          );
        }}
        allowSuggestionsAboveCursor
        singleLine
        suggestionsPortalHost={document.querySelector("#root")!}
      >
        <Mention
          markup="@[__display__](user:__id__)"
          trigger="@"
          data={suggestions}
          renderSuggestion={(suggestion) => {
            const { name } = suggestion as User;

            return (
              <Box className="flex items-center px-2 py-2">
                <Avatar
                  size="xs"
                  variant="dark"
                  className="grow-0 shrink-0"
                  name={name}
                />

                <Text className="ml-2">{name}</Text>
              </Box>
            );
          }}
          className={tw("text-primary-600 relative z-[1]")}
          style={{
            textShadow:
              "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
          }}
          appendSpaceOnAdd
        />
      </MentionsInput>

      <Button variant="outline" onClick={onSubmit}>
        {t("comments.send")}
      </Button>
    </Box>
  );
}
