import { Box, FormControl, Input, Select } from "@suit-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { CaseSearch } from "../types/CaseSearch";
import { useMemo, useState } from "react";

interface FormFieldsType {
  name: string;
  type: string;
  options?: {
    value: string;
    label: string;
  }[];
}

function getSelectField(key: string, obj: { [key: string]: string }) {
  return {
    name: key,
    type: "select",
    options: Object.entries(obj).map(([value, label]) => ({
      value,
      label,
    })),
  };
}

function getFormFields(
  params: CaseSearch["params"],
  selectors: CaseSearch["selectors"],
): FormFieldsType[] {
  const fields = Object.keys(params).map((key) => {
    return {
      name: key,
      type: "text",
    };
  });

  const withSelectFields = fields.map((field) =>
    Object.getOwnPropertyDescriptor({ ...selectors }, field.name)
      ? getSelectField(field.name, selectors[field.name])
      : field,
  );

  return withSelectFields;
}

interface DynamicFormProps {
  id: string;
  params: CaseSearch["params"];
  selectors: CaseSearch["selectors"];
  events: CaseSearch["events"];
  onValidateDependentFields?: () => void;
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  id,
  params,
  selectors,
  events,
  onValidateDependentFields,
}) => {
  const { register, control } = useFormContext();
  const formFields = useMemo(
    () => getFormFields(params, selectors),
    [params, selectors],
  );
  const [dependentFieldsOnValidating, setDependentFieldsOnValidating] =
    useState<string[]>([]);
  const dependentFields = useMemo(() => {
    return events ? Object.keys(events) : [];
  }, [events]);

  return (
    <Box className="flex flex-col gap-6">
      {formFields.map((formField) => (
        <FormControl key={`${id}-${formField.name}`}>
          <FormControl.Label>{formField.name}</FormControl.Label>

          {formField.type === "text" && (
            <Input {...register(`search_params[${formField.name}]`)} />
          )}
          {formField.type === "select" && (
            <Controller
              name={`search_params[${formField.name}]`}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={formField?.options}
                  valueAsObject={false}
                  menuPosition="fixed"
                  isLoading={dependentFieldsOnValidating.includes(
                    formField.name,
                  )}
                  disabled={dependentFieldsOnValidating.includes(
                    formField.name,
                  )}
                  {...(dependentFields.includes(formField.name) && {
                    onChange: async (e: string) => {
                      field.onChange(e);
                      const fieldsRevalidating = events?.[formField.name][1];
                      setDependentFieldsOnValidating(fieldsRevalidating || []);
                      await onValidateDependentFields?.();
                      setDependentFieldsOnValidating([]);
                    },
                  })}
                />
              )}
            />
          )}
        </FormControl>
      ))}
    </Box>
  );
};

export default DynamicForm;
