import { Badge, Box, Dropdown, IconButton, Table, Text } from "@suit-ui/react";
import { Movement } from "../types/Movement";
import * as Icons from "react-icons/md";
import { useState } from "react";
import { EditMovementModal } from "@/features/movements/components/EditMovementModal";
import { useGetMovements } from "@/features/movements/useCases/useGetMovements";
import { Case } from "../types/Case";
import { CreateAttachment } from "../../movements/types/Attachment";
import { UserAccess } from "@/features/session/components/UserAccess";
import { useTranslation } from "react-i18next";
import { CreateAttachmentModal } from "@/features/movements/components/CreateAttachmentModal";
import { useCreateAttachment } from "@/features/movements/useCases/useCreateAttachment";
import { MovementCommentsTable } from "@/features/movements/components/MovementCommentsTable";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { parseOfficialDate } from "@/features/movements/useCases/parseOfficialDate";
import { CreatorAvatar } from "@/features/movements/components/CreatorAvatar";
import { TableBody } from "@/ui/TableBody";
import { CollapseController } from "./CollapseController";
import { useGetCustomFields } from "@/features/customFields/useCases/useGetCustomFields";
import { getCustomFieldValueFormatted } from "@/features/customFields/useCases/getCustomFieldValueFormatted";
import { TableFooter } from "@/ui/TableFooter";
import { DeleteMovementDropdownItem } from "@/features/movements/components/DeleteMovementDropdownItem";
import { PlaceholderLoader } from "@/ui/PlaceholderLoader";
import AttachmentLink from "./AttachmentLink";

export interface CaseMovementsTableProps {
  caseId: Case["id"];
  isEditable?: boolean;
  availableUsers?: string[];
}

export const CaseMovementsTable = ({
  caseId,
  isEditable = true,
  availableUsers,
}: CaseMovementsTableProps) => {
  const { t } = useTranslation();
  const {
    movements,
    nextPage,
    prevPage,
    pagination,
    isLoading,
    onChangePageRows,
    isRefetching,
    queryKey,
    refetch,
  } = useGetMovements({ caseId });
  const { findById, isLoading: isUserLoading } = useGetUsers();
  const { customFields } = useGetCustomFields("Movement");

  const customFieldsInTable = [...customFields]
    .filter((field) => field.display_order_table > 0)
    .sort((a, b) => a.display_order_table - b.display_order_table);

  const customFieldsNotInTable = [...customFields].filter(
    (field) => field.display_order_table < 1,
  );

  const [isEditMovementModalOpen, setIsEditMovementModalOpen] =
    useState<boolean>(false);

  const [movementToEditId, setMovementToEditId] = useState<string>();

  const isEmpty = !isLoading && !movements?.length;

  const [isCreateAttachmentModalOpen, setIsCreateAttachmentModalOpen] =
    useState(false);
  const [currentMovement, setCurrentMovement] = useState<Movement | null>(null);
  const createAttachment = useCreateAttachment({ queryKey });

  const handleCreateAttachment = async (
    movement: Movement | null,
    data: CreateAttachment,
  ) => {
    await createAttachment.mutateAsync({
      movementId: movement!.id,
      attachment: data,
    });
  };

  return (
    <>
      <Table.Container
        className="rounded-md"
        isLoading={isLoading || isRefetching}
        page={pagination?.page}
        rowsPerPage={pagination?.pageSize}
        totalPages={pagination?.pageCount}
        onNextPage={nextPage}
        onPreviousPage={prevPage}
        onChangeRowsPerPage={onChangePageRows}
        totalRows={pagination?.total}
      >
        <Table>
          <Table.Head className="bg-primary-200 text-neutral-900 font-semibold">
            <Table.Row>
              <Table.Header />
              <Table.Header>{t("movements.title")}</Table.Header>
              <Table.Header>{t("movements.movement_date")}</Table.Header>
              <Table.Header>{t("movements.detail")}</Table.Header>
              {customFieldsInTable.map((field) => (
                <Table.Header key={field.id}>{field.name}</Table.Header>
              ))}
              <Table.Header>{t("movements.emitter")}</Table.Header>
              {isEditable && (
                <UserAccess
                  permission={["movements-update", "movements-destroy"]}
                >
                  <Table.Header>{t("movements.options")}</Table.Header>
                </UserAccess>
              )}
            </Table.Row>
          </Table.Head>
          {isEmpty && <TableBody isEmpty={isEmpty} title={t("ui.no_data")} />}
          {movements?.map((item) => {
            const user = findById(item.created_by_id);

            return (
              <CollapseController key={`collapsable_movement_${item.id}`}>
                {({ isOpen, onOpen, onToggle }) => (
                  <Table.Body className="even:bg-neutral-100 odd:bg-neutral-50">
                    <Table.Row>
                      <Table.Cell>
                        <IconButton
                          onClick={onToggle}
                          variant="text"
                          icon={
                            isOpen ? (
                              <Icons.MdOutlineExpandMore />
                            ) : (
                              <Icons.MdOutlineChevronRight />
                            )
                          }
                        />
                      </Table.Cell>
                      <Table.Cell className="w-[200px]">
                        {item.title}
                      </Table.Cell>
                      <Table.Cell>
                        {parseOfficialDate(
                          item.official_date,
                          t("date_formats.date"),
                        )}
                      </Table.Cell>
                      <Table.Cell className="flex flex-col">
                        <Box className="flex flex-col gap-1">
                          <Box>
                            {customFieldsNotInTable
                              .filter(
                                (customField) =>
                                  item.custom_data?.[customField.name],
                              )
                              .map((field, idx) => {
                                if (idx <= 2 || isOpen)
                                  return (
                                    <Box
                                      key={`collapsable_movement_field_${field.id}`}
                                      className="flex gap-2"
                                    >
                                      <Text className="font-semibold text-sm">
                                        {field.name}:
                                      </Text>
                                      <Text key={field.id} className="text-sm">
                                        {getCustomFieldValueFormatted(
                                          item.custom_data?.[field.name],
                                          field.type,
                                        )}
                                      </Text>
                                    </Box>
                                  );
                              })}
                          </Box>
                          <Box className="flex flex-col gap-2">
                            {item.attachments?.map((attachment) => (
                              <AttachmentLink
                                key={`attachment_${attachment._id}`}
                                attachment={attachment}
                                queryKey={queryKey}
                                movementId={item.id}
                              />
                            ))}
                          </Box>
                        </Box>
                      </Table.Cell>
                      {customFieldsInTable.map((field) => (
                        <Table.Cell
                          key={`movement_table_custom_field_${field.id}`}
                        >
                          {getCustomFieldValueFormatted(
                            item.custom_data?.[field.name],
                            field.type,
                          ) || "-"}
                        </Table.Cell>
                      ))}
                      <Table.Cell>
                        {isUserLoading && (
                          <PlaceholderLoader
                            width={24}
                            height={24}
                            className="rounded-full"
                          />
                        )}
                        {!isUserLoading && (
                          <CreatorAvatar className="w-10 h-10" user={user} />
                        )}
                      </Table.Cell>
                      {isEditable && (
                        <Table.Cell>
                          <UserAccess
                            permission={[
                              "movements-update",
                              "movements-destroy",
                            ]}
                          >
                            <Box className="mr-2 relative">
                              <IconButton
                                icon={<Icons.MdOutlineChatBubbleOutline />}
                                variant="text"
                                size="sm"
                                onClick={onToggle}
                              />
                              {!!item.comments?.length && (
                                <Badge
                                  color="danger"
                                  className="absolute -translate-x-1/2 -translate-y-1/2"
                                >
                                  {item.comments?.length}
                                </Badge>
                              )}
                            </Box>
                            <Dropdown placement="bottom-end">
                              <Dropdown.Button
                                as={IconButton}
                                icon={<Icons.MdMoreVert />}
                                variant="text"
                                color="neutral"
                                size="sm"
                              />

                              <Dropdown.List>
                                <Dropdown.Item onClick={onOpen}>
                                  {t("movements.add_comment")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setIsCreateAttachmentModalOpen(true);
                                    setCurrentMovement(item);
                                  }}
                                >
                                  {t("movements.attach_file")}
                                </Dropdown.Item>
                                <UserAccess permission={"movements-update"}>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setMovementToEditId(item.id);
                                      setIsEditMovementModalOpen(true);
                                    }}
                                  >
                                    {t("movements.edit")}
                                  </Dropdown.Item>
                                </UserAccess>

                                <UserAccess permission={"movements-destroy"}>
                                  <DeleteMovementDropdownItem movement={item} />
                                </UserAccess>
                              </Dropdown.List>
                            </Dropdown>
                          </UserAccess>
                        </Table.Cell>
                      )}
                    </Table.Row>

                    {isOpen && (
                      <MovementCommentsTable
                        movementId={item.id}
                        isEditable={isEditable}
                        availableUsers={availableUsers}
                      />
                    )}
                  </Table.Body>
                )}
              </CollapseController>
            );
          })}
        </Table>
        <TableFooter isEmpty={isEmpty} />
      </Table.Container>

      <EditMovementModal
        isOpen={isEditMovementModalOpen}
        onClose={() => {
          setIsEditMovementModalOpen(false);
          refetch();
        }}
        movementId={movementToEditId}
      />

      <CreateAttachmentModal
        isOpen={Boolean(isCreateAttachmentModalOpen)}
        onClose={() => setIsCreateAttachmentModalOpen(false)}
        movementId={currentMovement?.id}
        onSubmit={(data) => {
          handleCreateAttachment(currentMovement, data);
          setIsCreateAttachmentModalOpen(false);
        }}
      />
    </>
  );
};
