import { fetcher } from "@/utils/fetchers/fetcher";
import { Court } from "../types/Court";
import { useQuery } from "@tanstack/react-query";

const getCourt = async (courtId: string): Promise<Court> => {
  const response = await fetcher<Court>(`/courts/${courtId}`);

  return response.data
};

export const useGetCourt = (courtId: string) => {
  return useQuery({
    queryKey: ["get-court", courtId],
    queryFn: () => getCourt(courtId),
  });
}