import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";
import { SearchResult } from "../types/SearchResult";

export const GET_POLL_SEARCH_RESULT_KEY = "get_poll_search_result_key";

const getPollSearchResult = async (key?: string) => {
  const response = await fetcher.get<SearchResult>(
    "/scrapers/poll_search_result",
    {
      params: {
        key,
      },
    },
  );

  if (response.data === null) {
    throw new Error("polling in progress");
  }

  return response.data;
};

export function usePollSearchResult(key?: string) {
  const client = useQueryClient();
  const query = useQuery({
    queryKey: [GET_POLL_SEARCH_RESULT_KEY, key],
    queryFn: () => getPollSearchResult(key),
    retry: true,
    retryDelay: 1000,
    enabled: !!key,
  });

  return {
    ...query,
    invalidatePollSearchResult: () =>
      client.setQueryData([GET_POLL_SEARCH_RESULT_KEY, key], undefined),
  };
}
