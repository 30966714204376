import { useDateLocale } from "@/utils/i18n/useDateLocale";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  DatePicker,
  FormControl,
  Input,
  Modal,
  Select,
  Text,
  TextArea,
} from "@suit-ui/react";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { TaskStatus } from "../types/Task";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { CreateTaskData } from "../useCases/useCreateTask";
import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import { formatISO, setHours } from "date-fns";

const schema = z.object({
  title: z.string().min(1),
  state: z.string().min(1),
  priority: z.number(),
  asignees: z.array(z.string().min(1)).min(1),
  description: z.string(),
  start_date: z.date(),
  end_date: z.date(),
});

type TaskFormData = z.infer<typeof schema>;

interface EditableTaskModalProps {
  title: string;
  submitButtonText: string;
  isOpen: boolean;
  onClose: () => void;
  availableUsers?: string[];
  onSubmit: (data: CreateTaskData) => Promise<void>;
  isPending?: boolean;
  defaultValues?: Partial<TaskFormData>;
}

export function EditableTaskModal({
  isOpen = false,
  title,
  submitButtonText,
  onClose: closeHandler,
  onSubmit: submitHandler,
  availableUsers = [],
  isPending,
  defaultValues: initData,
}: EditableTaskModalProps) {
  const users = useGetUsers();
  const { data: currentUser } = useCurrentUser();
  const dateLocale = useDateLocale();
  const { t } = useTranslation();
  const defaultValues = useMemo(
    () =>
      initData || {
        asignees: [currentUser!.id],
        state: "todo",
        start_date: new Date(),
      },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initData],
  );
  const {
    register,
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors },
  } = useForm<TaskFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
    defaultValues,
  });

  const priorityOptions = useMemo(
    () => [
      {
        value: 0,
        label: t("tasks.low"),
      },
      {
        value: 1,
        label: t("tasks.medium"),
      },
      {
        value: 2,
        label: t("tasks.high"),
      },
    ],
    [t],
  );

  const statusOptions: {
    value: TaskStatus;
    label: string;
  }[] = useMemo(
    () => [
      {
        value: "todo",
        label: t("tasks.status_map.todo"),
      },
      {
        value: "in_progress",
        label: t("tasks.status_map.in_progress"),
      },
      {
        value: "done",
        label: t("tasks.status_map.done"),
      },
    ],
    [t],
  );

  const usersIds = Array.from(
    new Set([
      currentUser!.id,
      ...availableUsers,
      ...(initData?.asignees || []),
    ]),
  );

  const usersOptions = usersIds
    .map((userId) => users.findById(userId))
    ?.filter((user) => !!user?.active)
    .map((user) => ({
      label: user!.name,
      value: user!.id,
    }));

  const onClose = () => {
    if (isPending) return;

    closeHandler();
  };

  const onSubmit = handleSubmit(async (data) => {
    const noon = 12;

    await submitHandler({
      ...data,
      start_date: formatISO(setHours(data.start_date, noon)),
      end_date: formatISO(setHours(data.end_date, noon)),
      state: data.state as TaskStatus,
    });
    closeHandler();
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      onAnimationComplete={({ isOpen: isModalOpen }) =>
        !isModalOpen && reset(defaultValues)
      }
    >
      <Modal.Overlay />

      <Modal.Content as="form" noValidate onSubmit={onSubmit}>
        <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
          {title}
        </Modal.Header>
        <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />

        <Modal.Body className="flex flex-col gap-3">
          <FormControl required isInvalid={!!errors.title}>
            <FormControl.Label>{t("tasks.title")}</FormControl.Label>
            <Input {...register("title")} autoComplete="off" />

            <FormControl.ErrorMessage>
              {t("form_errors.field_required")}
            </FormControl.ErrorMessage>
          </FormControl>

          <Box className="flex gap-3">
            <FormControl required isInvalid={!!errors.priority}>
              <FormControl.Label>{t("tasks.priority")}</FormControl.Label>

              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={priorityOptions}
                    valueAsObject={false}
                    menuPosition="fixed"
                    placeholder={t("tasks.select_placeholder")}
                    className="w-[200px]"
                  />
                )}
              />

              <FormControl.ErrorMessage>
                {t("form_errors.field_required")}
              </FormControl.ErrorMessage>
            </FormControl>

            <FormControl required isInvalid={!!errors.state}>
              <FormControl.Label>{t("tasks.status")}</FormControl.Label>

              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={statusOptions}
                    valueAsObject={false}
                    menuPosition="fixed"
                    placeholder={t("tasks.select_placeholder")}
                    className="w-[200px]"
                  />
                )}
              />

              <FormControl.ErrorMessage>
                {t("form_errors.field_required")}
              </FormControl.ErrorMessage>
            </FormControl>
          </Box>

          <FormControl required isInvalid={!!errors.asignees}>
            <FormControl.Label>
              {t("tasks.assign_responsible")}
            </FormControl.Label>

            <Controller
              name="asignees"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={usersOptions}
                  isLoading={users.isLoading}
                  valueAsObject={false}
                  menuPosition="fixed"
                  isMulti
                  placeholder={t("tasks.select_placeholder")}
                />
              )}
            />

            <FormControl.ErrorMessage>
              {t("form_errors.field_required")}
            </FormControl.ErrorMessage>
          </FormControl>

          <FormControl>
            <FormControl.Label>{t("tasks.description")}</FormControl.Label>

            <TextArea {...register("description")} autoComplete="off" />
          </FormControl>

          <Box className="flex gap-3">
            <FormControl required isInvalid={!!errors.start_date}>
              <FormControl.Label>{t("tasks.start_date")}</FormControl.Label>

              <Controller
                name="start_date"
                control={control}
                render={({
                  field: { value, onChange, onBlur, name, disabled },
                }) => (
                  <DatePicker
                    name={name}
                    onBlur={onBlur}
                    disabled={disabled}
                    onChange={(date) => {
                      onChange(date);
                      trigger("start_date");
                    }}
                    selected={value}
                    dateFormat={t("date_formats.date")}
                    size="sm"
                    autoComplete="off"
                    isClearable
                    locale={dateLocale}
                  />
                )}
              />

              <FormControl.ErrorMessage>
                {t("form_errors.field_required")}
              </FormControl.ErrorMessage>
            </FormControl>

            <FormControl required isInvalid={!!errors.end_date}>
              <FormControl.Label>{t("tasks.end_date")}</FormControl.Label>

              <Controller
                name="end_date"
                control={control}
                render={({
                  field: { value, onChange, onBlur, name, disabled },
                }) => (
                  <DatePicker
                    name={name}
                    onBlur={onBlur}
                    disabled={disabled}
                    onChange={(date) => {
                      onChange(date);
                      trigger("end_date");
                    }}
                    selected={value}
                    dateFormat={t("date_formats.date")}
                    size="sm"
                    autoComplete="off"
                    isClearable
                    locale={dateLocale}
                  />
                )}
              />
              <FormControl.ErrorMessage>
                {t("form_errors.field_required")}
              </FormControl.ErrorMessage>
            </FormControl>
          </Box>
        </Modal.Body>

        <Modal.Footer className="flex items-center">
          <Text className="mr-auto text-sm">* {t("ui.required_fields")}</Text>
          <Button
            className="mr-2"
            variant="text"
            color="neutral"
            onClick={onClose}
          >
            {t("ui.cancel")}
          </Button>
          <Button type="submit" loading={isPending}>
            {submitButtonText}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
