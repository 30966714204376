import { ReactNode, useEffect, JSX } from "react";
import { Button, Dialog, useDisclosure } from "@suit-ui/react";
import { useTranslation } from "react-i18next";

interface ConfirmProps {
  isLoading?: boolean;
  onConfirm: () => void | Promise<unknown>;
  onConfirmText?: string;
  onCancel?: () => void;
  onOpen?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  body?: ReactNode;
  button?: JSX.Element | null;
  forceOpen?: boolean;
  buttonDisabled?: boolean;
}
const Confirm: React.FC<ConfirmProps> = ({
  isLoading = false,
  onConfirm,
  onConfirmText,
  onCancel,
  onOpen,
  title,
  body,
  button,
  forceOpen,
  buttonDisabled = false,
}) => {
  const { onClose, onOpen: _onOpen, isOpen } = useDisclosure();
  const { t } = useTranslation();

  const conditionalOnclose = () => {
    if (isLoading) return;

    onCancel?.();
    onClose();
  };

  useEffect(() => {
    if (forceOpen) {
      _onOpen();
    }
  }, [forceOpen, _onOpen]);

  return (
    <>
      {button && (
        <button.type
          {...button.props}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onOpen?.(e);
            _onOpen();
          }}
          disabled={buttonDisabled}
        />
      )}

      <Dialog
        isOpen={isOpen}
        onClose={conditionalOnclose}
        status="warning"
        size="sm"
      >
        <Dialog.Overlay />
        <Dialog.Content>
          <Dialog.Header>{title}</Dialog.Header>
          <Dialog.CloseButton />
          <Dialog.Body>{body}</Dialog.Body>
          <Dialog.Footer>
            <Button
              data-testid="cancel"
              onClick={conditionalOnclose}
              variant="text"
              color="neutral"
              className="mr-2"
            >
              {t("ui.cancel")}
            </Button>
            <Button
              onClick={async () => {
                await onConfirm();
                onClose();
              }}
              loading={isLoading}
            >
              {onConfirmText}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default Confirm;
