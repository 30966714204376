import { Box, MotionBox } from "@suit-ui/react";
import clsx from "clsx";

interface PlaceholderLoaderProps {
  width?: number;
  height?: number;
  className?: string;
}

export function PlaceholderLoader({
  width,
  height,
  className,
}: PlaceholderLoaderProps) {
  return (
    <Box
      className={clsx("bg-neutral-200 relative overflow-hidden", className)}
      style={{
        width,
        height,
      }}
    >
      <MotionBox
        className="absolute -left-40 w-40 h-full top-0 bg-gradient-to-r from-transparent via-neutral-300 to-transparent opacity-50"
        initial={{ left: -160 }}
        animate={{ left: "100%" }}
        transition={{
          repeat: Infinity,
          duration: 1,
        }}
        exit={{ left: 0 }}
      />
    </Box>
  );
}
