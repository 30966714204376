import React, { FormEvent, useEffect, useState } from "react";
import { CreateCourt } from "../types/Court";
import { useTranslation } from "react-i18next";
import { Button, FormControl, Input, Modal } from "@suit-ui/react";

export interface CreateCourtModalProps {
  onSubmit: (item: CreateCourt) => void;
  isOpen: boolean;
  onClose: () => void;
  defaultValue?: string;
}

export const CreateCourtModal: React.FC<CreateCourtModalProps> = ({
  onSubmit,
  isOpen,
  onClose,
  defaultValue,
}) => {
  const defaultState: CreateCourt = {
    name: defaultValue ?? "",
  };

  const [court, setCourt] = useState<CreateCourt>(defaultState);
  const { t } = useTranslation();

  useEffect(() => {
    setCourt(defaultState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Overlay />

        <Modal.Content
          as="form"
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onSubmit(court);
          }}
        >
          <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
            {t("cases_data.courts.create_court_header")}
          </Modal.Header>

          <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
          <Modal.Body className="flex flex-col gap-3">
            <FormControl required className="flex-1">
              <FormControl.Label>
                {t("cases_data.courts.name")}
              </FormControl.Label>

              <Input
                value={court.name}
                onChange={(e) => {
                  const value = e.target.value;
                  setCourt((prev) => ({ ...prev, name: value }));
                }}
              />
            </FormControl>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={onClose}
              variant="text"
              color="neutral"
              className="mr-2"
            >
              {t("ui.cancel")}
            </Button>
            <Button type="submit">{t("ui.create")}</Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};