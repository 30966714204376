import { useFormContext } from "react-hook-form";
import { Box, Button, Table, Text } from "@suit-ui/react";
import { CaseWithConnectionForm } from "../../types/CaseWithConnectionForm";
import { PageTitle } from "@/ui/texts/PageTitle";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "@/router";
import { SearchResultItem } from "./SearchResultItem";
import { useEffect, useState } from "react";
import { TableFooter } from "@/ui/TableFooter";
import { trackEvent } from "@/utils/analytics/trackEvent";
import { usePollSearchResult } from "../../useCases/usePollSearchResult";
import { useSearch } from "../../useCases/useSearch";

interface SearchResultStepProps {
  onStepComplete?: () => void;
}
const DEFAULT_ITEMS_PER_PAGE = 10;

const SearchResultStep = ({ onStepComplete }: SearchResultStepProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [caseIdxPolling, setCaseIdxPolling] = useState<number | null>(null);
  const [caseIdConnected, setCaseIdConnected] = useState<string | null>(null);
  const methods = useFormContext<CaseWithConnectionForm>();
  const { credential_id, searchKey, finder, search_params } = methods.watch();
  const [page, setPage] = useState(1);

  const search = useSearch();

  const { data: searchResultDataPolling } = usePollSearchResult(
    search.data?.key ?? searchKey,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnSearchResultFinish = (data: any, id: string[]) => {
    setCaseIdxPolling(null);
    setCaseIdConnected(id.join("-"));

    methods.setValue("case_data", { ...data, credential_id });
    onStepComplete?.();
  };

  const handleReset = () => {
    setCaseIdxPolling(null);
    setCaseIdConnected(null);
  };

  // This is to reset/re-enable connect case buttons when user backs to search step
  useEffect(() => {
    if (searchKey) handleReset();
  }, [searchKey]);

  const handleNextPage = async ({ nextPage }: { nextPage?: number | null }) => {
    if (!nextPage) return;

    const { paginable: isPaginable } = searchResultDataPolling || {};

    if (isPaginable)
      await search.mutateAsync({
        finder,
        search_params,
        credential_id,
        page: nextPage,
      });

    setPage(nextPage);
  };

  const handlePreviousPage = async () => {
    if (page <= 1) return;

    const { paginable: isPaginable } = searchResultDataPolling || {};

    if (isPaginable)
      await search.mutateAsync({
        finder,
        search_params,
        credential_id,
        page: page - 1,
      });

    setPage(page - 1);
  };

  if (!searchResultDataPolling) return null;

  const { paginable, rows, pagination, col_names, detail_cols } =
    searchResultDataPolling;

  const items = paginable
    ? rows
    : rows?.slice(
        (page - 1) * DEFAULT_ITEMS_PER_PAGE,
        page * DEFAULT_ITEMS_PER_PAGE,
      );
  const itemsPerPage = paginable
    ? pagination.page_size
    : DEFAULT_ITEMS_PER_PAGE;

  const totalPages = paginable
    ? pagination?.total_pages
    : Math.ceil(rows?.length / DEFAULT_ITEMS_PER_PAGE);

  const totalRows = paginable ? pagination?.total_entries : rows?.length;

  return (
    <Box className="relative">
      <PageTitle className="sticky top-0 bg-neutral-100">
        {t("cases.search_result_case")}
      </PageTitle>

      <Box className="flex justify-center w-full">
        <Box
          as="form"
          className="flex flex-col bg-neutral-50 p-8 rounded-sm shadow-xs mt-6 w-full max-w-screen-lg gap-6"
        >
          <Text>
            {t("cases.search_result_count", { count: totalRows || 0 })}
          </Text>

          <Table.Container
            className="rounded-md"
            page={page}
            rowsPerPage={itemsPerPage}
            totalPages={totalPages}
            totalRows={totalRows}
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
            variant="elevated"
          >
            <Table>
              <Table.Head className="bg-primary-800 text-neutral-50">
                <Table.Row className="text-neutral-50">
                  {col_names?.map(
                    (colName, idx) =>
                      detail_cols.indexOf(idx) < 0 && (
                        <Table.Header
                          key={`searchResultHeader-${colName}-${idx}`}
                          className="py-2"
                        >
                          {colName}
                        </Table.Header>
                      ),
                  )}
                  <Table.Header />
                </Table.Row>
              </Table.Head>

              {items?.map(({ values, scraper_params }, idx) => {
                const id = values.join("-");

                return (
                  <SearchResultItem
                    key={`SearchResultItem-${idx}-${id}`}
                    values={values}
                    scraper_params={scraper_params}
                    credential_id={credential_id}
                    col_names={col_names}
                    detail_cols={detail_cols}
                    isDisabled={
                      caseIdxPolling !== null && idx !== caseIdxPolling
                    }
                    isConnected={
                      caseIdConnected !== null && id === caseIdConnected
                    }
                    onConnect={(itemIdx) => {
                      setCaseIdxPolling(itemIdx);
                    }}
                    onSearchResultFinish={handleOnSearchResultFinish}
                    onReset={handleReset}
                    idx={idx}
                  />
                );
              })}
            </Table>
            <TableFooter isEmpty={!rows?.length} rowsPerPageSelect={false} />
          </Table.Container>

          <Box className="flex ml-auto">
            <Button
              variant="text"
              onClick={() => {
                trackEvent("Search result step canceled");
                navigate(routes.cases);
              }}
            >
              {t("ui.cancel")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { SearchResultStep };
