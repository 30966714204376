import { Box, Button, Placeholder } from "@suit-ui/react";
import { MainNavbar } from "../navbars/MainNavbar";
import { ErrorBoundary } from "@sentry/react";
import { ProtectedRoute } from "@/features/session/components/ProtectedRoute";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function MainContainer() {
  const { t } = useTranslation();

  return (
    <Box className="flex flex-col h-screen overflow-auto">
      <MainNavbar />
      <ErrorBoundary
        fallback={
          <Box className="flex justify-center items-center w-full h-screen">
            <Placeholder className="w-[600px]" variant="vertical">
              <Placeholder.Image>
                <Placeholder.EmptyErrorIcon />
              </Placeholder.Image>
              <Placeholder.Content>
                <Placeholder.Title>
                  {t("ui.error_loading_data")}
                </Placeholder.Title>
                <Placeholder.Description>
                  {t("ui.an_error_occurred_while_loading_the_data")}
                </Placeholder.Description>
              </Placeholder.Content>
              <Placeholder.BtnGroup className="flex gap-4">
                <Button onClick={() => window.location.reload()}>
                  {t("ui.reload")}
                </Button>
              </Placeholder.BtnGroup>
            </Placeholder>
          </Box>
        }
      >
        <ProtectedRoute>
          <Outlet />
        </ProtectedRoute>
      </ErrorBoundary>
    </Box>
  );
}
