import {
  Box,
  DatePicker,
  Dropdown,
  FilterButton,
  Icon,
  Input,
  SelectMultiCheckbox,
} from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { MdOutlineArrowForward, MdSearch } from "react-icons/md";
import { TaskFilters } from "../useCases/useGetTasks";
import { useDebounce } from "@/utils/debounce/useDebounce";
import { useMemo, useState } from "react";
import { TaskStatus } from "../types/Task";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { useDateLocale } from "@/utils/i18n/useDateLocale";
import { endOfDay, format, formatISO, parseISO, startOfDay } from "date-fns";

interface SearchFiltersProps {
  filters: TaskFilters;
  onChangeFilters: (filters: TaskFilters) => void;
}

export function SearchFilters({
  filters,
  onChangeFilters,
}: SearchFiltersProps) {
  const locale = useDateLocale();
  const { t } = useTranslation();
  const debouncedApplyFilters = useDebounce(onChangeFilters, 500);
  const { users } = useGetUsers();
  const [selectType, setSelectType] = useState("start");

  const handleInputSearch = (newFilter: TaskFilters) => {
    debouncedApplyFilters({ ...filters, ...newFilter });
  };

  const handleFilter = (newFilter: Partial<TaskFilters>) => {
    onChangeFilters({
      ...filters,
      ...newFilter,
    });
  };

  const userOptions = useMemo(
    () =>
      users?.map((user) => ({
        value: user.id,
        label: user.name,
      })),
    [users],
  );

  const statusOptions: {
    value: TaskStatus;
    label: string;
  }[] = useMemo(
    () => [
      {
        value: "todo",
        label: t("tasks.status_map.todo"),
      },
      {
        value: "in_progress",
        label: t("tasks.status_map.in_progress"),
      },
      {
        value: "done",
        label: t("tasks.status_map.done"),
      },
    ],
    [t],
  );

  const start = filters.start_date?.gte || filters.end_date?.gte;
  const end = filters.start_date?.lte || filters.end_date?.lte;

  const startDate = start ? parseISO(start) : undefined;
  const endDate = end ? parseISO(end) : undefined;

  const onChangeDate = (date: Date | null) => {
    if (selectType === "start") {
      setSelectType("end");

      handleFilter({
        start_date: {
          ...filters.start_date,
          gte:
            typeof date === "undefined" || date === null
              ? undefined
              : formatISO(startOfDay(date)),
        },
        end_date: {
          ...filters.end_date,
          gte:
            typeof date === "undefined" || date === null
              ? undefined
              : formatISO(startOfDay(date)),
        },
      });
    }

    if (selectType === "end") {
      handleFilter({
        start_date: {
          ...filters.start_date,
          lte:
            typeof date === "undefined" || date === null
              ? undefined
              : formatISO(endOfDay(date)),
        },
        end_date: {
          ...filters.end_date,
          lte:
            typeof date === "undefined" || date === null
              ? undefined
              : formatISO(endOfDay(date)),
        },
      });
    }
  };

  return (
    <>
      <Box className="flex mt-4 mb-2">
        <Box className="flex items-center grow shrink max-w-[855px] mr-6">
          <Input.Group size="lg" className="grow shrink">
            <Input
              placeholder={t("tasks.search_tasks_placeholder")}
              className="w-full rounded-full peer"
              defaultValue={filters.q}
              onChange={(e) => {
                handleInputSearch({ q: e.target.value || undefined });
              }}
            />
            <Input.LeftElement className="opacity-50 peer-focus-visible:opacity-100 flex justify-center items-center w-10 h-10">
              <Icon as={MdSearch} size="6" className="text-neutral-600" />
            </Input.LeftElement>
          </Input.Group>
        </Box>
      </Box>

      <Box className="flex my-4 gap-2 flex-wrap">
        <Dropdown>
          <Dropdown.Button
            isSelected={!!filters.state?.or?.length}
            as={FilterButton}
            className="whitespace-nowrap"
            onClear={() => {
              handleFilter({
                state: {
                  or: [],
                },
              });
            }}
          >
            {t("tasks.status")}{" "}
            {!!filters.state?.or?.length && `(${filters.state?.or?.length})`}
          </Dropdown.Button>
          <Dropdown.List className="py-0 w-[380px]">
            <SelectMultiCheckbox
              valueAsObject={false}
              isMulti
              removeShadows
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              menuIsOpen
              isClearable={false}
              options={statusOptions}
              placeholder={t("ui.search_placeholder")}
              value={filters.state?.or}
              onChange={(values: []) => {
                handleFilter({
                  state: {
                    or: [...values],
                  },
                });
              }}
            />
          </Dropdown.List>
        </Dropdown>

        <Dropdown>
          <Dropdown.Button
            isSelected={!!filters.asignees?.or?.length}
            as={FilterButton}
            className="whitespace-nowrap"
            onClear={() => {
              handleFilter({
                asignees: {
                  or: [],
                },
              });
            }}
          >
            {t("tasks.asignees")}{" "}
            {!!filters.asignees?.or?.length &&
              `(${filters.asignees?.or?.length})`}
          </Dropdown.Button>
          <Dropdown.List className="py-0 w-[380px]">
            <SelectMultiCheckbox
              valueAsObject={false}
              isMulti
              removeShadows
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              menuIsOpen
              isClearable={false}
              options={userOptions}
              placeholder={t("ui.search_placeholder")}
              value={filters.asignees?.or}
              onChange={(values: []) => {
                handleFilter({
                  asignees: {
                    or: [...values],
                  },
                });
              }}
            />
          </Dropdown.List>
        </Dropdown>

        <DatePicker
          customInput={
            <FilterButton
              isSelected={!!startDate || !!endDate}
              className="whitespace-nowrap"
              onClear={() => {
                setSelectType("start");

                handleFilter({
                  start_date: undefined,
                  end_date: undefined,
                });
              }}
            >
              {t("tasks.dates")}{" "}
              {[startDate, endDate].filter(Boolean).length
                ? `(${[startDate, endDate].filter(Boolean).length})`
                : ""}
            </FilterButton>
          }
          selected={selectType === "start" ? startDate : endDate}
          onChange={onChangeDate}
          selectsStart={selectType === "start"}
          selectsEnd={selectType === "end"}
          startDate={startDate}
          endDate={endDate}
          minDate={selectType === "end" ? startDate : undefined}
          onCalendarOpen={() => setSelectType("start")}
          openToDate={
            startDate && endDate && selectType === "start"
              ? startDate
              : endDate!
          }
          isHeaderClickeable={false}
          shouldCloseOnSelect={false}
          monthsShown={2}
          locale={locale}
          topContent={
            <Box className="flex m-5 mb-1 items-center space-x-5">
              <Input
                size="sm"
                value={
                  startDate ? format(startDate, t("date_formats.date")) : ""
                }
                onClick={() => setSelectType("start")}
                className={`${
                  selectType === "start" && "border-[1.5px] border-primary-800"
                }`}
              />

              <Icon as={MdOutlineArrowForward} />
              <Input
                size="sm"
                value={endDate ? format(endDate, t("date_formats.date")) : ""}
                onClick={() => setSelectType("end")}
                className={`${
                  selectType === "end" && "border-[1.5px] border-primary-800"
                }`}
              />
            </Box>
          }
        />
      </Box>
    </>
  );
}
