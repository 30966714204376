import { fetcher } from "@/utils/fetchers/fetcher";
import { PaginatedResource } from "@/utils/pagination/PaginatedResource";
import { usePagination } from "@/utils/pagination/usePagination";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Task } from "../types/Task";
import { getTaskApiUrl } from "../utils/getTaskApiUrl";
import { FilterOperators } from "@/utils/filter";
import qs from "qs";

export type TaskTypes = "all" | "cases";

export type TaskFilters = {
  state?: {
    [key in FilterOperators]?: string[];
  };
  start_date?: {
    [key in FilterOperators]?: string;
  };
  end_date?: {
    [key in FilterOperators]?: string;
  };
  asignees?: {
    [key in FilterOperators]?: string[];
  };
  sort?: string[];
  q?: string;
};

interface UseGetTaksProps {
  type?: TaskTypes;
  resourceId?: string;
  refetchOnMount?: boolean;
  filters?: TaskFilters;
  pagination?: {
    defaultValues?: {
      page?: number;
      pageSize?: number;
    };
  };
}

interface GetTasksProps {
  type: TaskTypes;
  page: number;
  pageSize: number;
  resourceId?: string;
  filters?: TaskFilters;
}

export const GET_TASKS_QUERY_KEY = "GET_TASKS_QUERY_KEY";

async function getTasks({
  type,
  page,
  pageSize,
  resourceId,
  filters,
}: GetTasksProps) {
  const response = await fetcher<PaginatedResource<Task[]>>(
    getTaskApiUrl(type, resourceId),
    {
      params: {
        filter: filters,
        pagination: {
          page,
          pageSize,
        },
      },
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: "brackets" }),
    },
  );

  return response.data;
}

export function useGetTasks({
  type = "all",
  resourceId,
  refetchOnMount = true,
  filters,
  pagination,
}: UseGetTaksProps = {}) {
  const {
    page,
    pageSize,
    nextPage,
    prevPage,
    onChangePageRows,
    goToFirstPage,
  } = usePagination(pagination?.defaultValues);

  const query = useQuery({
    queryKey: [GET_TASKS_QUERY_KEY, type, resourceId, page, pageSize, filters],
    queryFn: () => getTasks({ type, page, pageSize, resourceId, filters }),
    placeholderData: keepPreviousData,
    refetchOnMount,
  });

  return {
    ...query,
    tasks: query.data?.data || [],
    pagination: query.data?.meta?.pagination,
    nextPage,
    prevPage,
    onChangePageRows,
    goToFirstPage,
  };
}
