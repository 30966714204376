import { Dropdown, Icon, Box, useTw, Image } from "@suit-ui/react";
import {
  MdAccountCircle,
  MdArrowDropDown,
  MdOutlineDescription,
  MdOutlineHome,
  MdLogout,
  MdTimeline,
  MdOutlinePersonOutline,
  MdLockOutline,
  MdOutlineEvent,
  MdOutlineSettings,
  MdOutlineBadge,
  MdOutlineGroup,
  MdOutlineList,
  MdDisplaySettings,
} from "react-icons/md";
import { signOut } from "../../features/session/useCases/signOut";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routes } from "@/router";
import clsx from "clsx";
import { IconType } from "react-icons";
import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import {
  UserAccess,
  UserAccessProps,
} from "@/features/session/components/UserAccess";
import { UserPermission } from "@/features/users/types/UserPermission";
import { trackEvent } from "@/utils/analytics/trackEvent";

export const MainNavbar = () => {
  const { t } = useTranslation();
  const { data: currUser } = useCurrentUser();

  const isGuest = currUser?.role?.type === "guest";

  return (
    <Box className="bg-primary-900 flex min-h-[50px] px-8 items-center text-neutral-50 gap-4">
      <Link to={routes.home}>
        <Image
          alt="Lemontech logo"
          className="w-[160px] py-3"
          fallbackSrc="https://uploads-ssl.webflow.com/599de54773c6620001cd7ad0/5e97150ce4e48422dcb803cf_logotype_vector_lemontech.svg"
          src="/ct-lite-logo.png"
        />
      </Link>

      <Box className="flex h-full">
        {isGuest && (
          <NavButton to={routes.cases} icon={MdOutlineDescription}>
            {t("cases.cases")}
          </NavButton>
        )}

        {!isGuest && (
          <>
            <NavButton to={routes.home} icon={MdOutlineHome}>
              {t("main_navbar.home")}
            </NavButton>

            <NavDropdown
              icon={<Icon as={MdOutlineDescription} size="5" />}
              title={t("main_navbar.cases")}
              items={[
                {
                  to: routes.cases,
                  icon: <Icon as={MdOutlineDescription} size="5" />,
                  title: t("main_navbar.cases_list"),
                  permission: "cases-read",
                },
                {
                  to: routes.movements,
                  icon: <Icon as={MdTimeline} size="5" />,
                  title: t("main_navbar.movements"),
                  permission: "movements-read",
                },
                {
                  to: routes.events,
                  icon: <Icon as={MdOutlineEvent} size="5" />,
                  title: t("main_navbar.events"),
                  permission: "events-read",
                },
                {
                  to: routes.tasks,
                  icon: <Icon as={MdOutlineList} size="5" />,
                  title: t("main_navbar.tasks"),
                  permission: "tasks-read",
                },
              ]}
            />

            {!isGuest && (
              <NavButton to={routes.clients} icon={MdOutlineDescription}>
                {t("main_navbar.clients")}
              </NavButton>
            )}

            <NavDropdown
              icon={<Icon as={MdOutlineSettings} size="5" />}
              title={t("main_navbar.settings")}
              items={[
                {
                  to: routes.users,
                  icon: <Icon as={MdOutlineGroup} size="5" />,
                  title: t("main_navbar.users_and_permissions"),
                  permission: "users-read",
                },
                {
                  to: routes.credentials,
                  icon: <Icon as={MdOutlineBadge} size="5" />,
                  title: t("main_navbar.credentials"),
                },
                {
                  to: routes.casesData,
                  icon: <Icon as={MdDisplaySettings} size="5" />,
                  title: t("main_navbar.cases_data"),
                },
              ]}
            />
          </>
        )}
      </Box>

      <Dropdown placement="bottom-end">
        <Dropdown.Button className="flex items-center gap-2 ml-auto h-full">
          <Icon as={MdAccountCircle} />
          {currUser?.name}
          <Icon as={MdArrowDropDown} />
        </Dropdown.Button>

        <Dropdown.List>
          {!isGuest && (
            <>
              <Dropdown.Item
                as={Link}
                to={routes.profile}
                icon={<Icon as={MdOutlinePersonOutline} size="5" />}
              >
                {t("main_navbar.profile")}
              </Dropdown.Item>

              <Dropdown.Item
                as={Link}
                to={routes.changePassword}
                icon={<Icon as={MdLockOutline} size="5" />}
              >
                {t("main_navbar.change_password")}
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Item
            onClick={() => {
              trackEvent("User logged out");
              signOut();
            }}
            icon={<Icon as={MdLogout} size="5" />}
          >
            {t("main_navbar.log_out")}
          </Dropdown.Item>
        </Dropdown.List>
      </Dropdown>
    </Box>
  );
};

interface NavButtonProps {
  to: string;
  children: React.ReactNode;
  icon: IconType;
  permission?: UserAccessProps["permission"];
}

const NavButton = ({ children, to, icon, permission }: NavButtonProps) => {
  const tw = useTw();

  return (
    <UserAccess permission={permission}>
      <NavLink
        to={to}
        className={({ isActive }) =>
          tw(
            clsx(
              "text-neutral-50/50 items-center flex text-md px-3.5 hover:text-neutral-50",
              isActive && "text-neutral-50 bg-neutral-500/20",
            ),
          )
        }
      >
        <Icon as={icon} className="mr-2" size="5" />
        {children}
      </NavLink>
    </UserAccess>
  );
};

interface NavDropdownProps {
  title: string;
  icon: React.ReactNode;
  items: {
    to: string;
    icon: React.ReactNode;
    title: string;
    permission?: UserPermission;
  }[];
  permission?: UserPermission;
}

const NavDropdown = ({ title, items, icon, permission }: NavDropdownProps) => {
  const tw = useTw();
  const location = useLocation();

  const isActive = items.some((item) => location.pathname.startsWith(item.to));

  return (
    <UserAccess permission={permission}>
      <Box
        className={tw(
          clsx(
            "text-neutral-50/50 items-center flex text-md px-3.5 hover:text-neutral-50",
            isActive && "text-neutral-50 bg-neutral-500/20",
          ),
        )}
      >
        <Dropdown placement="bottom-end">
          <Dropdown.Button className="flex gap-1 items-center h-full">
            {icon}
            {title}
            <Icon as={MdArrowDropDown} />
          </Dropdown.Button>

          <Dropdown.List>
            {items?.map((item) => (
              <UserAccess key={`link-${item.to}`} permission={item.permission}>
                <Dropdown.Item as={Link} to={item.to} icon={item.icon}>
                  {item.title}
                </Dropdown.Item>
              </UserAccess>
            ))}
          </Dropdown.List>
        </Dropdown>
      </Box>
    </UserAccess>
  );
};
