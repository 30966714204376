import { EventQueryParams } from "@/features/events/types/Event";
import { useGetEvents } from "@/features/events/useCases/useGetEvents";
import { Box, Button, Icon, Placeholder } from "@suit-ui/react";
import { useMemo } from "react";
import { useGetCourts } from "../useCases/useGetCourts";
import { format } from "date-fns";
import { useDateLocale } from "@/utils/i18n/useDateLocale";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "@/router";
import { PlaceholderLoader } from "@/ui/PlaceholderLoader";
import { MdOutlineEvent } from "react-icons/md";
import { trackEvent } from "@/utils/analytics/trackEvent";

export function OverviewEventsTable() {
  const navigate = useNavigate();
  const nextEventsParams = useMemo((): EventQueryParams => {
    return {
      filter: {
        startDate: {
          gte: new Date().toISOString(),
        },
      },
      sort: "start_date:asc",
    };
  }, []);

  const { t } = useTranslation();
  const dateLocale = useDateLocale();
  const { events, isLoading } = useGetEvents("all", "", nextEventsParams);
  const { courtMap } = useGetCourts();

  if (isLoading) {
    return (
      <Box className="flex flex-col h-[420px] overflow-hidden">
        {Array.from({ length: 3 }).map((_, index) => (
          <Box
            key={`last_movements_loader_${index}`}
            className="flex flex-col p-6 gap-2"
          >
            <PlaceholderLoader className="w-full h-4 mb-2" />
            <PlaceholderLoader className="w-full h-2" />
            <PlaceholderLoader className="w-full h-2" />
            <PlaceholderLoader className="w-full h-2" />
            <PlaceholderLoader className="w-full h-2" />
          </Box>
        ))}
      </Box>
    );
  }

  if (!events.length) {
    return (
      <>
        <Box className="flex ml-10 mt-5">
          <Box className="flex align-left text-primary-900 font-bold text-lg">
            {t("events.events")}
          </Box>
          <Box className="flex align-right ml-auto mr-10">
            <Button
              variant="text"
              size="xs"
              className="text-xs align-right"
              onClick={() => {
                trackEvent("Home Page - See more events clicked");
                navigate(routes.events);
              }}
            >
              {t("overview.see_more")}
            </Button>
          </Box>
        </Box>
        <Box className="flex flex-col h-[420px] justify-center items-center px-8">
          <Placeholder>
            <Placeholder.Image>
              <Placeholder.EmptyIcon />
            </Placeholder.Image>
            <Placeholder.Content>
              <Placeholder.Title>{t("ui.empty_data")}</Placeholder.Title>
              <Placeholder.Description>
                {t("ui.empty_data_description")}
              </Placeholder.Description>
            </Placeholder.Content>
          </Placeholder>
        </Box>
      </>
    );
  }

  return (
    <Box className="max-h-[420px] overflow-y-auto px-10">
      <Box className="flex mt-5">
        <Box className="flex align-left text-primary-900 font-bold text-lg">
          {t("events.events")}
        </Box>
        <Box className="flex align-right ml-auto">
          <Button
            variant="text"
            size="xs"
            className="text-xs align-right"
            onClick={() => {
              trackEvent("Home Page - See more events clicked");
              navigate(routes.events);
            }}
          >
            {t("overview.see_more")}
          </Button>
        </Box>
      </Box>
      {events.map((event) => (
        <Box
          as={Link}
          to={`${routes.cases}/${event.case?.id}`}
          key={`next_events_${event.id}`}
          onClick={() => trackEvent("Home Page - Event clicked")}
          className="pt-4.5 pb-3 px-6 border-b border-neutral-300 last:border-b-0 flex text-neutral-600 hover:text-primary-600"
        >
          <Box className="flex">
            <Icon as={MdOutlineEvent} />
          </Box>
          <Box
            as="h2"
            className="flex flex-col px-10 font-bold mb-1 first-letter:capitalize"
          >
            {event?.start_date && (
              <>
                <span>
                  {format(
                    new Date(event.start_date),
                    `EEEE dd '${t("ui.of")}' MMMM`,
                    {
                      locale: dateLocale,
                    },
                  )}
                </span>
                <span>
                  {format(new Date(event.start_date), `HH:mm 'h'`, {
                    locale: dateLocale,
                  })}
                </span>
              </>
            )}
          </Box>
          <Box className="flex flex-col">
            <Box className="flex flex-col font-bold text-md">
              {event.summary}
            </Box>
            {event.case?.title && (
              <Box className="text-sm">{event.case?.title}</Box>
            )}
          </Box>
          {courtMap.get(event.case?.court_id as string)?.name && (
            <Box className="flex text-sm px-10">
              {courtMap.get(event.case?.court_id as string)?.name}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
