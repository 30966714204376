import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { MovementAttachment } from "../types/Movement";
import { useRef, useState } from "react";
import { GET_CASE_MOVEMENTS_FETCH_KEY } from "@/features/movements/useCases/useGetMovements";

const getMovementAttachment = async ({
  movementId,
  attachmentId,
  pollingCount,
}: {
  movementId: string;
  attachmentId: string;
  pollingCount: number;
}) => {
  const { data: attachment } = await fetcher<MovementAttachment>(
    `/movements/${movementId}/attachments/${attachmentId}`,
  );

  // Cut polling if it reaches the polling limit
  if (pollingCount + 1 >= 10) {
    return attachment;
  }

  if (attachment.summarize_status === "sent") {
    // This keeps the polling working
    throw new Error("polling in progress");
  }

  // Cut the polling
  return attachment;
};

interface UseGetMovementAttachParams {
  movementId: string;
  attachmentId: string;
}

export const useGetMovementAttachment = ({
  attachmentId,
  movementId,
}: UseGetMovementAttachParams) => {
  const client = useQueryClient();
  const [isPollingAttachment, setIsPollingAttachment] =
    useState<boolean>(false);
  const pollingCount = useRef(0);

  return {
    ...useQuery({
      queryKey: ["movement-attachment", attachmentId],
      queryFn: async () => {
        try {
          await getMovementAttachment({
            movementId,
            attachmentId,
            pollingCount: pollingCount.current,
          });
          pollingCount.current = 0;
          setIsPollingAttachment(false);
          client.invalidateQueries({
            queryKey: [GET_CASE_MOVEMENTS_FETCH_KEY],
          });
        } catch (error) {
          pollingCount.current += 1;
          throw new Error("polling in progress");
        }
      },
      enabled: isPollingAttachment,
      retryDelay: 10000,
      retry: true,
    }),
    isPollingAttachment,
    setIsPollingAttachment,
  };
};
