import { PageContainer } from "@/ui/containers/PageContainer";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import { PageTitle } from "@/ui/texts/PageTitle";
import { Box } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { SearchFilters } from "../components/SearchFilters";
import { TaskTable } from "../components/TaskTable";
import { TaskFilters } from "../useCases/useGetTasks";
import { useFilters } from "@/utils/filter/useFilters";
import { useUserAccess } from "@/features/session/useCases/useUserAccess";

export function TasksPage() {
  const { t } = useTranslation();
  const [filters, setFilters] = useFilters<TaskFilters>({
    sort: ["end_date:desc"],
  });

  const hasPermission = useUserAccess("tasks-read");

  if (!hasPermission)
    return (
      <PageContainer className="flex flex-col">
        <Box className="flex justify-between mb-4.5">
          <BreadcrumbNav />
        </Box>

        <PageTitle>{t("tasks.tasks")}</PageTitle>

        <Box className="flex justify-center items-center">
          <PageContainer.Forbidden />
        </Box>
      </PageContainer>
    );

  return (
    <PageContainer className="flex flex-col">
      <Box className="flex justify-between mb-4.5">
        <BreadcrumbNav />
      </Box>

      <PageTitle>{t("tasks.tasks")}</PageTitle>

      <SearchFilters filters={filters} onChangeFilters={setFilters} />

      <Box className="flex mt-2 flex-col gap-2">
        <TaskTable filters={filters} strongHeader includeCase showCounter />
      </Box>
    </PageContainer>
  );
}
