import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { Case } from "../types/Case";
import { useTranslation } from "react-i18next";
import { GET_CASES_FETCH_KEY } from "./useGetCases";

interface CreateCaseParams extends Pick<Case, "title" | "court_id" | "code"> {
  scraper_params?: { [key: string]: string };
  key?: string;
}

const createCase = async (ccase: CreateCaseParams) => {
  const reponse = await fetcher.post<Case>("/cases", { ...ccase });

  return reponse.data;
};

export const useCreateCase = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCase,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_CASES_FETCH_KEY] });

      toast.add({
        content: t("cases.create_case_success"),
        status: "success",
      });
    },

    onError: (args) => {
      console.error(args);
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });
};
