import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { Credential } from "../types/Credential";

export const GET_CREDENTIALS_KEY = "get-credentials";

const getCredentials = async () => {
  const response = await fetcher<Credential[]>("/credentials");
  return response.data;
};

export const useGetCredentials = () => {
  const query = useQuery({
    queryKey: [GET_CREDENTIALS_KEY],
    queryFn: getCredentials,
  });
  return {
    ...query,
    credentials: query.data,
  };
};
