import { PageContainer } from "@/ui/containers/PageContainer";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import { PageTitle } from "@/ui/texts/PageTitle";
import { Box, Button, Table, useDisclosure } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { MdOutlineAdd } from "react-icons/md";
import { useGetCredentials } from "../useCases/useGetCredentials";
import { CredentalItem } from "../components/CredentialItem";
import { CreateCredentialModal } from "../components/CreateCredentialModal";
import { TableBody } from "@/ui/TableBody";
import { useGetCredentialsTypes } from "../useCases/useGetCredentialsTypes";

export function CredentialsPage() {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { credentials = [], isLoading: isCredentialsLoading } =
    useGetCredentials();

  const { credentialsTypes = {} } = useGetCredentialsTypes();

  return (
    <PageContainer className="flex flex-col ">
      <Box className="flex items-center mb-4.5">
        <BreadcrumbNav />
      </Box>

      <PageTitle>{t("credentials.title")}</PageTitle>

      <Box className="mt-4">
        <Box className="flex mb-6 items-center gap-6">
          <Box className="max-w-screen-md mr-auto text-neutral-600">
            {t("credentials.subtitle")}
          </Box>

          <Button size="lg" leftIcon={<MdOutlineAdd />} onClick={onOpen}>
            {t("credentials.create_credential_btn")}
          </Button>
        </Box>

        <Box className="flex flex-col gap-2">
          <Table.Container
            className="rounded-md"
            variant="elevated"
            isLoading={isCredentialsLoading}
          >
            <Table>
              <Table.Head className="bg-primary-800 text-neutral-50">
                <Table.Row className="text-neutral-50">
                  <Table.Header>{t("credentials.type")}</Table.Header>
                  <Table.Header>{t("credentials.name")}</Table.Header>
                  <Table.Header>{t("credentials.username")}</Table.Header>
                  <Table.Header>{t("credentials.creator")}</Table.Header>
                  <Table.Header>{t("credentials.is_shared")}</Table.Header>
                  <Table.Header>{t("credentials.cases_count")}</Table.Header>
                  <Table.Header>{t("credentials.state")}</Table.Header>
                  <Table.Header className="flex justify-center">
                    {t("ui.options")}
                  </Table.Header>
                </Table.Row>
              </Table.Head>
              <TableBody
                isEmpty={!isCredentialsLoading && credentials.length === 0}
                title={t("credentials.add_new_credential_title")}
                description={t("credentials.add_new_credential_subtitle")}
              >
                {credentials?.map((cred) => (
                  <CredentalItem
                    credential={cred}
                    key={`cred-id-${cred.id}`}
                    types={credentialsTypes}
                  />
                ))}
              </TableBody>
            </Table>
          </Table.Container>
        </Box>
      </Box>

      <CreateCredentialModal isOpen={isOpen} onClose={onClose} />
    </PageContainer>
  );
}
