import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { CustomFieldSelectValue } from "../types/CustomField";

export const GET_CUSTOM_FIELD_VALUE_QUERY_KEY = "get-custom-field-value";

const getCustomFieldValue = async (id: string) => {
  const response = await fetcher.get<CustomFieldSelectValue[]>(
    `/custom_fields/${id}/custom_field_values`,
  );

  return response.data;
};
// TODO merge with useGetCustomFieldValues
export const useGetCustomFieldsValuesById = (id: string) => {
  return useQuery({
    queryKey: [GET_CUSTOM_FIELD_VALUE_QUERY_KEY, id],
    queryFn: () => getCustomFieldValue(id),
    refetchOnMount: false,
  });
};
