import { TableBody } from "@/ui/TableBody";
import { TableFooter } from "@/ui/TableFooter";
import { Box, Dropdown, IconButton, Table, Text } from "@suit-ui/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { MdMoreVert } from "react-icons/md";
import { ViewTask } from "./ViewTask";
import { TaskFilters, TaskTypes, useGetTasks } from "../useCases/useGetTasks";
import { PriorityDots } from "./PriorityDots";
import { AvatarUsersGroup } from "./AvatarUsersGroup";
import { formatDate } from "../utils/formatDate";
import { useNavigate } from "react-router-dom";
import { routes } from "@/router";
import { DeleteTask } from "./DeleteTask";
import { EditTask } from "./EditTask";
import { EditableTaskStatus } from "./EditableTaskStatus";
import { isPast, parseISO } from "date-fns";
import { UserAccess } from "@/features/session/components/UserAccess";
import { useGetCourts } from "@/features/cases/useCases/useGetCourts";

interface TaskTableProps {
  strongHeader?: boolean;
  showCounter?: boolean;
  includeCase?: boolean;
  type?: TaskTypes;
  resourceId?: string;
  filters?: TaskFilters;
  isEditableInline?: boolean;
}

export function TaskTable({
  strongHeader = false,
  includeCase = false,
  showCounter = false,
  type,
  resourceId,
  filters,
  isEditableInline = false,
}: TaskTableProps) {
  const { t } = useTranslation();
  const {
    tasks,
    pagination,
    isLoading,
    isRefetching,
    nextPage,
    prevPage,
    onChangePageRows,
  } = useGetTasks({
    type,
    resourceId,
    filters,
  });
  const { courtMap } = useGetCourts();
  const navigate = useNavigate();

  const isEmpty = !isLoading && tasks.length <= 0;

  const goToCase = (id: string) => {
    if (!includeCase) {
      return;
    }

    const selection = window.getSelection();

    if (selection?.type != "Range") {
      navigate(`${routes.cases}/${id}?tab=tasks`);
    }
  };

  return (
    <>
      {showCounter && (
        <Box className="flex justify-between items-center">
          <Text className="text-primary-900 mb-2.5 font-semibold">
            {t("ui.showing_of_results", {
              showing: tasks?.length,
              total: pagination?.total,
            })}
          </Text>
        </Box>
      )}

      <Table.Container
        isLoading={isLoading || isRefetching}
        variant="elevated"
        className="rounded-md"
        page={pagination?.page}
        rowsPerPage={pagination?.pageSize}
        totalPages={pagination?.pageCount}
        totalRows={pagination?.total}
        onNextPage={nextPage}
        onPreviousPage={prevPage}
        onChangeRowsPerPage={(page) => onChangePageRows(Number(page))}
      >
        <Table>
          <Table.Head
            className={clsx(
              "font-semibold",
              strongHeader
                ? "bg-primary-800 text-neutral-50"
                : "bg-primary-200 text-neutral-900",
            )}
          >
            <Table.Row>
              {includeCase && <Table.Header>{t("tasks.case")}</Table.Header>}
              <Table.Header className="w-40">
                {t("tasks.task_title")}
              </Table.Header>
              <Table.Header>{t("tasks.priority")}</Table.Header>
              <Table.Header>{t("tasks.status")}</Table.Header>
              <Table.Header>{t("tasks.asignees")}</Table.Header>
              <Table.Header className="whitespace-nowrap">
                {t("tasks.start_date")}
              </Table.Header>
              <Table.Header className="whitespace-nowrap">
                {t("tasks.end_date")}
              </Table.Header>
              <Table.Header className="w-24">{t("tasks.options")}</Table.Header>
            </Table.Row>
          </Table.Head>
          <TableBody
            isEmpty={isEmpty}
            title={t("ui.empty_data")}
            className="bg-neutral-50"
          >
            {tasks.map((task) => (
              <Table.Row
                key={`task_row_${task._id}`}
                className={clsx(
                  "even:bg-neutral-100 odd:bg-neutral-50",
                  includeCase && "cursor-pointer",
                )}
                onClick={
                  includeCase
                    ? () => {
                        goToCase(task.case?.id as string);
                      }
                    : undefined
                }
              >
                {includeCase && (
                  <Table.Cell className="w-[320px]">
                    <Box>
                      <Text className="text-sm">
                        {`${task.case?.code} - ${
                          courtMap.get(task.case?.court_id as string)?.name ||
                          ""
                        }`}
                      </Text>
                      <Text className="text-sm">{task.case?.title}</Text>
                    </Box>
                  </Table.Cell>
                )}
                <Table.Cell>
                  <Text className="text-sm">{task?.title}</Text>
                </Table.Cell>
                <Table.Cell>
                  <PriorityDots priority={task?.priority} />
                </Table.Cell>
                <Table.Cell>
                  <EditableTaskStatus
                    isEnabled={isEditableInline}
                    task={task}
                  />
                </Table.Cell>
                <Table.Cell>
                  <AvatarUsersGroup ids={task?.asignees} className="my-2" />
                </Table.Cell>

                <Table.Cell>
                  <Text className="text-sm">
                    {formatDate(task.start_date, t("date_formats.date"))}
                  </Text>
                </Table.Cell>
                <Table.Cell>
                  <Text
                    className={clsx(
                      "text-sm",
                      isPast(parseISO(task.end_date)) &&
                        task?.state !== "done" &&
                        "text-danger-500",
                    )}
                  >
                    {formatDate(task.end_date, t("date_formats.date"))}
                  </Text>
                </Table.Cell>
                <Table.Cell>
                  <Dropdown placement="bottom-end">
                    <Dropdown.Button
                      as={IconButton}
                      icon={<MdMoreVert />}
                      variant="text"
                      color="neutral"
                      size={strongHeader ? "md" : "sm"}
                    />

                    <Dropdown.List>
                      <ViewTask
                        task={task}
                        button={
                          <Dropdown.Item>
                            {t("tasks.view_detail")}
                          </Dropdown.Item>
                        }
                      />
                      <UserAccess permission="tasks-update">
                        <EditTask
                          task={task}
                          button={<Dropdown.Item>{t("ui.edit")}</Dropdown.Item>}
                        />
                      </UserAccess>
                      {includeCase && (
                        <Dropdown.Item
                          onClick={() => {
                            goToCase(task.case?.id as string);
                          }}
                        >
                          {t("tasks.view_in_case")}
                        </Dropdown.Item>
                      )}
                      <UserAccess permission="tasks-destroy">
                        <DeleteTask
                          task={task}
                          button={
                            <Dropdown.Item className="text-danger-600 hover:bg-danger-100 active:(bg-danger-200 text-danger-700)">
                              {t("ui.delete")}
                            </Dropdown.Item>
                          }
                        />
                      </UserAccess>
                    </Dropdown.List>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            ))}
          </TableBody>
        </Table>
        <TableFooter isEmpty={isEmpty} />
      </Table.Container>
    </>
  );
}
