import { Navigate, useLocation } from "react-router-dom";
import { useCurrentUser } from "../useCases/useCurrentUser";
import { routes } from "@/router";

export interface ProtectedRouteProps {
  children?: React.ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { pathname } = useLocation();
  const { data: currUser } = useCurrentUser();

  const onCasesPage =
    pathname === routes.cases || pathname.startsWith(`${routes.cases}/`);

  if (currUser?.role?.type === "guest" && !onCasesPage) {
    return <Navigate to={routes.cases} replace />;
  }

  return children;
};

