import { Box, Button, Icon } from "@suit-ui/react";
import { PageContainer } from "@/ui/containers/PageContainer";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import Wizard from "@/ui/Wizard";
import { SearchCaseStep } from "../components/CaseWithConnectionSteps/SearchCaseStep";
import { SearchResultStep } from "../components/CaseWithConnectionSteps/SearchResultsStep";
import { FormProvider, useForm } from "react-hook-form";
import { CaseWithConnectionForm } from "../types/CaseWithConnectionForm";
import { CaseDataStep } from "../components/CaseWithConnectionSteps/CaseDataStep";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineReportProblem } from "react-icons/md";

export function CaseWithConnectionPage() {
  const { t } = useTranslation();
  const [caseError, setCaseError] = useState<string | null>(null);
  const methods = useForm<CaseWithConnectionForm>();

  return (
    <FormProvider {...methods}>
      {caseError && (
        <Box className="flex items-center bg-danger-200 p-4 w-full">
          <Icon as={MdOutlineReportProblem} />

          <Box className="font-regular ml-2">{caseError}</Box>

          <Button
            variant="text"
            className="ml-auto font-semibold"
            onClick={() => setCaseError(null)}
          >
            {t("ui.close")}
          </Button>
        </Box>
      )}

      <PageContainer className="flex flex-col items-start">
        <Box className="flex justify-between mb-6">
          <BreadcrumbNav />
        </Box>

        <Wizard
          steps={[
            <SearchCaseStep />,
            <SearchResultStep />,
            <CaseDataStep onSaveFailed={(error) => setCaseError(error)} />,
          ]}
        />
      </PageContainer>
    </FormProvider>
  );
}
