import {
  Box,
  Button,
  FormControl,
  Input,
  Select,
  TextArea,
} from "@suit-ui/react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";

const defaultParty = {
  name: "",
  identifier: "",
  type: "",
  comment: null,
};

const schema = z
  .object({
    name: z.string().min(1),
    identifier: z.string().min(1),
    party_type: z.string().min(1),
    comments: z.string({ description: "field_required" }).nullish(),
  })
  .required({
    name: true,
    identifier: true,
    party_type: true,
  });

export type CasePartyFormData = z.infer<typeof schema>;

export const CasePartyForm = ({
  defaultValues,
  onSubmit = () => {},
  onCancel = () => {},
  isLoading,
}: {
  defaultValues?: CasePartyFormData | null;
  onSubmit?: (data: CasePartyFormData) => void;
  onCancel?: () => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<CasePartyFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
    defaultValues: defaultValues ?? defaultParty,
  });

  return (
    <Box
      as="form"
      noValidate
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
      }}
      className="flex flex-col gap-3"
    >
      <FormControl required isInvalid={!!errors.name}>
        <FormControl.Label>Nombre</FormControl.Label>
        <Input {...register("name")} autoComplete="off" />
        {!!errors.name && (
          <FormControl.ErrorMessage>
            {t("form_errors.field_required")}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
      <FormControl required isInvalid={!!errors.identifier}>
        <FormControl.Label>Identificador</FormControl.Label>
        <Input {...register("identifier")} autoComplete="off" />
        {!!errors.identifier && (
          <FormControl.ErrorMessage>
            {t("form_errors.field_required")}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
      <FormControl required isInvalid={!!errors.party_type}>
        <FormControl.Label>Tipo</FormControl.Label>

        <Controller
          control={control}
          name="party_type"
          render={({ field }) => (
            <Select
              {...field}
              menuPosition="fixed"
              value={
                field.value && {
                  label: field.value,
                  value: field.value,
                }
              }
              options={[
                {
                  label: "Demandante",
                  value: "Demandante",
                },
                {
                  label: "Demandado",
                  value: "Demandado",
                },
                {
                  label: "Abogado del demandante",
                  value: "Abogado del demandante",
                },
                {
                  label: "Abogado del demandado",
                  value: "Abogado del demandado",
                },
              ]}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onInputChange={(value, actionMeta) => {
                if (
                  actionMeta.action !== "input-blur" &&
                  actionMeta.action !== "menu-close" &&
                  actionMeta.action !== "set-value"
                ) {
                  field.onChange(value);
                }
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(value, actionMeta) => {
                if (actionMeta.action === "clear") {
                  field.onChange("");
                }

                if (actionMeta.action === "select-option") {
                  field.onChange(value?.label ?? "");
                }
              }}
              isClearable
            />
          )}
        />

        {!!errors.party_type && (
          <FormControl.ErrorMessage>
            {t("form_errors.field_required")}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.comments}>
        <FormControl.Label>Comentario</FormControl.Label>
        <TextArea {...register("comments")} autoComplete="off" />
        {!!errors.comments && (
          <FormControl.ErrorMessage>
            {t(errors.comments.message!)}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
      <Box className="flex justify-end gap-2">
        <Button
          variant="text"
          disabled={isLoading}
          color="neutral"
          className="mt-4"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button type="submit" loading={isLoading} className="mt-4">
          Guardar
        </Button>
      </Box>
    </Box>
  );
};
