import { Modal } from "@suit-ui/react";
import { Case } from "../types/Case";
import { CasePartyForm, CasePartyFormData } from "./CasePartyForm";
import { useUpdateCaseParty } from "../useCases/useUpdateCaseParty";
import { CaseParty } from "../types/CaseParty";
import { trackEvent } from "@/utils/analytics/trackEvent";

export interface EditCasePartyModalProps {
  caseId: Case["id"];
  isOpen: boolean;
  onClose: () => void;
  defaultValues: CaseParty | null;
}

export const EditCasePartyModal = ({
  isOpen,
  onClose,
  caseId,
  defaultValues,
}: EditCasePartyModalProps) => {
  const { updateCase, isPending } = useUpdateCaseParty();

  const onSubmit = async (data: CasePartyFormData) => {
    // TODO consultar por api directamente alomejor el case party para no exigirlo como defaultValues
    if (!defaultValues) return;

    await updateCase({
      caseId,
      data: {
        ...defaultValues,
        ...data,
      },
    });

    trackEvent("Case party updated");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
          Editar parte involucrada
        </Modal.Header>

        <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
        <Modal.Body>
          <CasePartyForm
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            onCancel={onClose}
            isLoading={isPending}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
