import { Input, Checkbox, DatePicker, Select } from "@suit-ui/react";
import {
  CustomFieldModel,
  useGetCustomFields,
} from "@/features/customFields/useCases/useGetCustomFields";
import { format, parseISO, setHours, startOfHour } from "date-fns";
import { ForwardedRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { SelectOptionsWrapper } from "./SelectOptionsWrapper";
import { CustomFieldValueType } from "../types/CustomField";
import { useDateLocale } from "@/utils/i18n/useDateLocale";

export interface CustomFieldInputProps {
  /** Name of the custom field */
  name: string;
  model: CustomFieldModel;
  onChange?: (v: CustomFieldValueType) => void;
  value?: string;
  disabled?: boolean;
  id: string;
}

export const CustomFieldInput = forwardRef<HTMLElement, CustomFieldInputProps>(
  ({ name, value, model, id, onChange = () => {}, ...rest }, ref) => {
    const { customFields } = useGetCustomFields(model);

    const type = customFields.find((field) => field.name === name)?.type;
    const { t } = useTranslation();
    const locale = useDateLocale();

    if (type === "FLOAT" || type === "INTEGER" || type === "CURRENCY") {
      return (
        <Input
          ref={ref as ForwardedRef<HTMLInputElement>}
          onChange={(e) => {
            onChange(e.target.valueAsNumber);
          }}
          type="number"
          value={value}
          name={name}
          {...rest}
        />
      );
    }

    if (type === "BOOL") {
      return (
        <Checkbox
          onChange={(e) => onChange(e.target.checked)}
          checked={!!value}
          name={name}
          {...rest}
        />
      );
    }

    if (type === "DATE" || type === "DATETIME") {
      let parsedValue = null;

      if (value)
        parsedValue =
          type === "DATE" ? parseISO(value.split("T")[0]) : parseISO(value);

      return (
        <DatePicker
          locale={locale}
          selected={parsedValue}
          showTimeSelect={type === "DATETIME"}
          dateFormat={
            type === "DATETIME"
              ? t("date_formats.datetime")
              : t("date_formats.date")
          }
          onChange={(date) => {
            if (!date) return onChange(null);
            if (type === "DATE") return onChange(format(date, "yyyy-MM-dd"));

            if (!value) {
              const currentHour = startOfHour(new Date()).getHours();
              return onChange(setHours(date, currentHour).toISOString());
            }

            onChange(date.toISOString());
          }}
          name={name}
          {...rest}
        />
      );
    }

    if (type === "FIXED_SELECT") {
      return (
        <SelectOptionsWrapper fieldId={id}>
          <Select
            menuPosition="fixed"
            valueAsObject={false}
            onChange={onChange}
            value={value}
            {...rest}
          />
        </SelectOptionsWrapper>
      );
    }

    if (type === "VARIABLE_SELECT") {
      return (
        <SelectOptionsWrapper fieldId={id}>
          <Select
            menuPosition="fixed"
            value={
              value && {
                label: value,
                value: value,
              }
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onInputChange={(val, actionMeta) => {
              if (
                actionMeta.action !== "input-blur" &&
                actionMeta.action !== "menu-close" &&
                actionMeta.action !== "set-value"
              ) {
                onChange(val);
              }
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={(val, actionMeta) => {
              if (actionMeta.action === "clear") {
                onChange("");
              }

              if (actionMeta.action === "select-option") {
                onChange(val?.label ?? "");
              }
            }}
            isClearable
            {...rest}
          />
        </SelectOptionsWrapper>
      );
    }

    return (
      <Input
        ref={ref as ForwardedRef<HTMLInputElement>}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
    );
  },
);
