import mixpanel from "mixpanel-browser";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Appcues: any;
  }
}

export const identifyTrackedUser = ({
  id,
  role,
  tenant,
  email,
}: {
  id: string;
  role: string;
  tenant: string;
  email: string;
}) => {
  mixpanel.identify(id);
  mixpanel.people.set({ role, email, tenant });

  mixpanel.register({ source: "web-app" });

  window.Appcues.identify(id, { email, role, tenant });
};
