import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { GET_TASKS_QUERY_KEY, TaskTypes } from "./useGetTasks";
import { getTaskApiUrl } from "../utils/getTaskApiUrl";

interface DeleteTaskProps {
  type: TaskTypes;
  taskId: string;
  resourceId: string;
}

const deleteTask = async ({ resourceId, type, taskId }: DeleteTaskProps) => {
  const url = getTaskApiUrl(type, resourceId);
  const response = await fetcher.delete(`${url}/${taskId}`);

  return response.data;
};

interface UseDeleteTaskProps {
  type: TaskTypes;
  resourceId: string;
  taskId: string;
}

export function useDeleteTask({
  type,
  taskId,
  resourceId,
}: UseDeleteTaskProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteTask({ type, resourceId, taskId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_TASKS_QUERY_KEY] });

      toast.add({
        content: t("tasks.delete_success"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("tasks.errors.delete_failed"),
        status: "danger",
      });
    },
  });
}
