import { fetcher } from "@/utils/fetchers/fetcher";
import { EventForm, EventTypes, GetEventUrl } from "../types/Event";
import { useToast } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET_EVENTS_FETCH_KEY } from "./useGetEvents";

const editEvent = async (url: string, id: string, data: EventForm) => {
  const response = await fetcher.put<Event>(`${url}/${id}`, data);

  return response.data;
};

export const useEditEvent = (
  type: EventTypes,
  resourceId: string,
  id: string,
) => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: EventForm) =>
      editEvent(GetEventUrl(type, resourceId), id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_EVENTS_FETCH_KEY] });

      toast.add({
        content: t("events.edit_success"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("events.errors.edit_failed"),
        status: "danger",
      });
    },
  });
};
