import { Badge, Box, Icon } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { MdOutlineList } from "react-icons/md";
import { useGetTasks } from "../useCases/useGetTasks";
import { endOfDay, formatISO } from "date-fns";

interface TaskTabButtonProps {
  caseId: string;
}

export function TaskTabButton({ caseId }: TaskTabButtonProps) {
  const { t } = useTranslation();
  const maxTasks = 10;
  const { tasks } = useGetTasks({
    type: "cases",
    resourceId: caseId,
    pagination: {
      defaultValues: {
        pageSize: maxTasks + 1,
      },
    },
    filters: {
      sort: ["end_date:desc"],
      end_date: { lte: formatISO(endOfDay(new Date())) },
      state: {
        or: ["todo", "in_progress"],
      },
    },
  });

  return (
    <Box className="flex items-center gap-1 px-2">
      <Icon as={MdOutlineList} />
      {t("tasks.tasks")}
      {!!tasks?.length && (
        <Badge color="danger">
          {tasks.length > maxTasks ? `${maxTasks}+` : tasks.length}
        </Badge>
      )}
    </Box>
  );
}
