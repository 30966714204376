import { Text } from "@suit-ui/react";
import clsx from "clsx";

interface PageTitleProps {
  children?: React.ReactNode;
  className?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({
  children,
  className,
}) => (
  <Text
    as="h1"
    className={clsx("text-3xl font-bold text-primary-900", className)}
  >
    {children}
  </Text>
);
