import { Box, Placeholder, Text } from "@suit-ui/react";
import { useGetCases } from "../useCases/useGetCases";
import { useGetCourts } from "../useCases/useGetCourts";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "@/router";
import { PlaceholderLoader } from "@/ui/PlaceholderLoader";
import { differenceInDays, parseISO } from "date-fns";
import { trackEvent } from "@/utils/analytics/trackEvent";

export const OverviewCasesTable = () => {
  const { cases, isLoading: areCasesLoading } = useGetCases({
    filters: {
      active: { eq: true },
      sort: ["last_movement_date:asc"],
      last_movement_date: {
        neq: "null",
      },
    },
    pagination: {
      defaultValues: {
        pageSize: 4,
      },
    },
  });
  const { courtMap, isLoading: areCourtsLoading } = useGetCourts();

  const { t } = useTranslation();

  if (areCasesLoading || areCourtsLoading) {
    return (
      <Box className="flex flex-col h-[195px] overflow-hidden pt-4.5 pb-3 px-6">
        {Array.from({ length: 3 }).map((_, index) => (
          <Box
            key={`last_movements_loader_${index}`}
            className="flex flex-col gap-1 my-3"
          >
            <PlaceholderLoader className="w-full h-2" />
            <PlaceholderLoader className="w-full h-2" />
            <PlaceholderLoader className="w-full h-2" />
          </Box>
        ))}
      </Box>
    );
  }

  if (!cases.length) {
    return (
      <Box className="flex flex-col h-[195px] overflow-hidden pt-4.5 pb-3 px-6 justify-center items-center">
        <Placeholder variant="horizontal">
          <Placeholder.Image>
            <Placeholder.EmptyIcon />
          </Placeholder.Image>
          <Placeholder.Content>
            <Placeholder.Title>{t("ui.empty_data")}</Placeholder.Title>
            <Placeholder.Description>
              {t("ui.empty_data_description")}
            </Placeholder.Description>
          </Placeholder.Content>
        </Placeholder>
      </Box>
    );
  }

  return (
    <Box className="pt-4.5 pb-3 px-6">
      {cases?.map((kase) => (
        <Box
          as={Link}
          to={`${routes.cases}/${kase.id}`}
          onClick={() => trackEvent("Home Page - Case clicked")}
          key={`case_without_movement_${kase.id}`}
          className="block leading-5 my-2 text-neutral-900 hover:text-primary-600"
        >
          <Text as="span" className="text-sm">
            {kase.title} ROL {kase.code} {courtMap.get(kase.court_id!)?.name}{" "}
          </Text>

          <Text as="span" className="font-semibold text-sm">
            {differenceInDays(
              new Date(),
              parseISO(kase.last_movement_date as unknown as string),
            )}{" "}
            {t("cases.days_without_movement")}
          </Text>
        </Box>
      ))}
    </Box>
  );
};
