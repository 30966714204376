import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomFieldValue } from "../types/CustomFieldValue";
import { GET_CUSTOM_FIELD_VALUES_FETCH_KEY } from "./useGetCustomFieldValues";

const updateCustomFieldValue = async (
  customFieldId: string,
  customFieldValueId: string,
  data: Partial<Omit<CustomFieldValue, "id">>,
) => {
  const response = await fetcher.put<Event>(
    `/custom_fields/${customFieldId}/custom_field_values/${customFieldValueId}`,
    data,
  );
  return response.data;
};

export const useUpdateCustomFieldValue = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (opts: {
      customFieldId: string;
      customFieldValueId: string;
      data: Partial<Omit<CustomFieldValue, "id">>;
    }) => {
      await updateCustomFieldValue(
        opts.customFieldId,
        opts.customFieldValueId,
        opts.data,
      );
      return opts;
    },
    onSuccess: async ({ customFieldId }) => {
      await queryClient.invalidateQueries({
        queryKey: [GET_CUSTOM_FIELD_VALUES_FETCH_KEY, customFieldId],
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return { updateCustomFieldValue: mutateAsync, isPending };
};
