import { Setting } from "./types/Setting";

export const STORAGE_SESSION_NAME = "token";

export const NOTIFICATIONS_OPTS: {
  code: keyof Setting["merged"];
  alertCode?: keyof Setting["merged"];
  name: string;
  description: string;
  timeSelectable?: boolean;
  timeRestricted?: boolean;
}[] = [
  {
    code: "notify_daily_movements",
    alertCode: "times_daily_movements",
    name: "Aviso de nuevos movimientos",
    description:
      "Recibirás la actualización de movimientos de tus causas traídos desde el poder judicial o ingresadas manualmente.",
    timeSelectable: true,
    timeRestricted: true,
  },
  {
    code: "notify_daily_events",
    alertCode: "times_daily_events",
    name: "Aviso de nuevo eventos",
    description:
      "Recibirás la actualización de eventos de tus causas traídos desde el poder judicial.",
    timeSelectable: true,
    timeRestricted: true,
  },
  {
    code: "notify_alert_tasks",
    alertCode: "times_alert_tasks",
    name: "Recordatorio de tareas por vencer",
    description:
      "Recibirás una lista de las tareas que vencen el día siguiente y el mismo día del recordatorio. ",
    timeSelectable: true,
  },
  {
    code: "notify_alert_events",
    alertCode: "times_alert_events",
    name: "Recordatorio de eventos",
    description:
      "Cuando un evento agendado en tus causas esté cerca de ocurrir, recibirás un aviso el día antes y el mismo día.",
    timeSelectable: true,
  },
  {
    code: "notify_added_to_case",
    name: "Aviso de asignación a causa",
    description:
      "Notificación de que alguien te ha asignado a una causa. Quien crea la causa puede definir que recibas o no el aviso.",
  },
  {
    code: "notify_added_to_task",
    name: "Aviso de asignación de tarea",
    description:
      "Si estás asociado a una causa, podrán asignarte tareas de la misma y te avisaremos de que has sido asignado.",
  },
  {
    code: "notify_added_to_event",
    name: "Aviso de asignación a un evento",
    description: "Notificación de que alguien te ha asignado a una tarea.",
  },
  {
    code: "notify_movement_mention",
    name: "Aviso de mencion en comentario de un movimiento",
    description:
      "Notificación de que alguien te ha mencionado en un comentario perteneciente a un movimiento.",
  },
  {
    code: "notify_task_mention",
    name: "Aviso de mencion en comentario de una tarea",
    description:
      "Notificación de que alguien te ha mencionado en un comentario perteneciente a una tarea.",
  },
];
