import React, { useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Spinner,
  Table,
  Text,
  useTw,
} from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { useGetComments } from "../useCases/useGetcomments";
import { useCreateComment } from "../useCases/useCreateComment";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { format } from "date-fns";
import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import { MovementComment } from "../types/MovementComment";
import { MdOutlineClose } from "react-icons/md";
import Confirm from "@/ui/Confirm";
import { useDeleteComment } from "../useCases/useDeleteComment";
import { Mention, MentionsInput } from "react-mentions";
import { User } from "@/features/users/types/User";
import clsx from "clsx";
import { trackEvent } from "@/utils/analytics/trackEvent";

export interface MovementCommentsTableProps {
  movementId: string;
  isEditable?: boolean;
  availableUsers?: string[];
}

interface CommentProps {
  comment: MovementComment;
  deleteButton?: React.ReactNode;
  className?: string;
}

const Comment: React.FC<CommentProps> = ({
  comment,
  className,
  deleteButton,
}) => {
  const { t } = useTranslation();
  const users = useGetUsers();
  const userName = users.findById(comment.created_by_id!)?.name;

  return (
    <Box
      className={clsx(
        "flex p-3 items-start rounded-sm hover:bg-primary-100 relative group",
        className,
      )}
    >
      <Box className="flex p-3 gap-3 grow-0 shrink-0 items-center">
        <Box className="flex items-center">
          <Avatar
            size="xs"
            variant="dark"
            className="grow-0 shrink-0"
            name={userName}
          />
        </Box>
      </Box>

      <Box className="grow shrink pt-2 pb-3 pr-3">
        <Box className="flex gap-1 items-center">
          <Box className="text-md font-semibold leading-6">{userName}</Box>
          <Box as="span" className="leading-6">
            -
          </Box>
          <Text
            as="span"
            size="xs"
            className="font-semibold leading-6 pt-[1px]"
          >
            {format(comment.created_at!, t("date_formats.date"))}
          </Text>
        </Box>

        <Box
          as={MentionsInput}
          value={comment?.content}
          className="[&_textarea]:(outline-transparent outline-none)"
        >
          <Box
            as={Mention}
            markup="@[__display__](user:__id__)"
            trigger="@"
            data={users.users}
            className={"text-primary-600 relative z-[1]"}
            style={{
              textShadow:
                "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
            }}
          />
        </Box>
      </Box>

      {deleteButton}
    </Box>
  );
};

export const MovementCommentsTable: React.FC<MovementCommentsTableProps> = ({
  movementId,
  isEditable = true,
  availableUsers = [],
}) => {
  const { t } = useTranslation();
  const { data: comments, isLoading } = useGetComments(movementId);
  const currentUser = useCurrentUser();
  const { users, findById } = useGetUsers();
  const [newComment, setNewComment] = useState<string>("");
  const createComment = useCreateComment(movementId);
  const deleteComment = useDeleteComment(movementId);
  const usersSuggestions = useMemo(() => {
    return (
      availableUsers.map((userId) => findById(userId)).filter(Boolean) as User[]
    ).map(({ id, name }) => ({ id, name, display: `@${name}` }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, availableUsers]);
  const tw = useTw();

  const addComment = () => {
    if (newComment.length === 0) {
      return;
    }

    createComment.mutate({
      movementId,
      comment: { content: newComment },
    });

    trackEvent("Movement comment created");
    setNewComment("");
  };

  return (
    <Table.Row>
      <Table.Cell colSpan={9999}>
        <Box className="w-full flex flex-col gap-3 pt-3 pb-12">
          {!isLoading && !comments?.length && !isEditable && (
            <Box>
              <Text className="text-neutral-500 text-sm italic">
                {t("movements.no_comments")}...
              </Text>
            </Box>
          )}

          {comments?.map((comment) => (
            <Comment
              className="pl-[270px]"
              key={comment.id}
              comment={comment}
              deleteButton={
                <Confirm
                  title={t("movements.delete_comment_title")}
                  onConfirm={() =>
                    deleteComment.mutateAsync({
                      commentId: comment.id!,
                      movementId,
                    })
                  }
                  onConfirmText={t("ui.delete")}
                  button={
                    <IconButton
                      icon={<MdOutlineClose />}
                      color="neutral"
                      variant="text"
                      size="xs"
                      className="absolute top-3 right-3 group-hover:inline-flex hidden"
                    />
                  }
                  onOpen={(e) => e.stopPropagation()}
                  body={t("movements.delete_warning")}
                  isLoading={deleteComment.isPending}
                />
              }
            />
          ))}
          {createComment.isPending && (
            <Comment
              className="pl-[270px]"
              comment={{
                content: createComment.variables?.comment?.content,
                created_at: new Date(),
                created_by_id: currentUser?.data?.id,
              }}
            />
          )}
          {isLoading && (
            <Box className="flex justify-center w-full py-8">
              <Spinner />
            </Box>
          )}
          {isEditable && (
            <Box className="pl-[280px] mt-1 flex items-center gap-3 mr-10">
              <Avatar
                size="xs"
                variant="dark"
                className="grow-0 shrink-0"
                name={currentUser?.data?.name}
              />

              <MentionsInput
                onChange={(_, value) => setNewComment(value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    addComment();
                  }
                }}
                value={newComment}
                placeholder={t("comments.placeholder")}
                style={{
                  suggestions: {
                    backgroundColor: "transparent",
                  },
                }}
                className={tw(
                  clsx(
                    "h-10 text-md border bg-neutral-50 border-neutral-600 font-regular outline-none text-neutral-600 rounded-sm w-full",
                    "placeholder:text-neutral-400",
                    "hover:shadow-sm",
                    "focus-visible:(border-[1.5px] border-primary-800)",
                    "disabled:(text-neutral-400 border-neutral-300 bg-neutral-100 shadow-none ring-0)",
                    "[&_input]:px-4 [&_input]:py-2 [&_input]:focus:(outline-0)",
                    "[&>div>div]:(mt-2 pl-2) [&>div]:(pl-[7px])",
                    "[&>*:nth-child(2)]:(pl-0 pt-0) [&>*:nth-child(2)>div]:(pl-0)",
                  ),
                )}
                customSuggestionsContainer={(children) => {
                  return (
                    <Box
                      className={clsx(
                        "bg-neutral-50 shadow-md mt-0 overflow-hidden text-neutral-900",
                        "border border-solid border-neutral-100 rounded-md",
                        "overflow-y-auto max-h-[300px]",
                        "[&_li[aria-selected='true']]:(:bg-primary-400 text-neutral-50)",
                      )}
                    >
                      {children}
                    </Box>
                  );
                }}
                allowSuggestionsAboveCursor
                singleLine
                suggestionsPortalHost={document.querySelector("#root")!}
              >
                <Mention
                  markup="@[__display__](user:__id__)"
                  trigger="@"
                  data={usersSuggestions}
                  renderSuggestion={(suggestion) => {
                    const { name } = suggestion as User;

                    return (
                      <Box className="flex items-center px-2 py-2">
                        <Avatar
                          size="xs"
                          variant="dark"
                          className="grow-0 shrink-0"
                          name={name}
                        />

                        <Text className="ml-2">{name}</Text>
                      </Box>
                    );
                  }}
                  className={tw("text-primary-600 relative z-[1]")}
                  style={{
                    textShadow:
                      "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
                  }}
                  appendSpaceOnAdd
                />
              </MentionsInput>

              <Button variant="outline" onClick={addComment}>
                {t("comments.send")}
              </Button>
            </Box>
          )}
        </Box>
      </Table.Cell>
    </Table.Row>
  );
};
