import { Box, Button, Icon, Text } from "@suit-ui/react";
import { PageContainer } from "../../../ui/containers/PageContainer";
import { Link as Rrlink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OverviewMovementsTable } from "../components/OverviewMovementsTable";
import { OverviewCasesTable } from "../components/OverviewCasesTable";
import { OverviewCard } from "../components/OverviewCard";
import {
  MdOutlineBadge,
  MdOutlineDescription,
  MdOutlineEvent,
  MdOutlineGroup,
  MdTimeline,
} from "react-icons/md";
import { routes } from "@/router";
import { OverviewEventsTable } from "../components/OverviewEventsTable";
import { OverviewTenantInfo } from "../components/OverviewTenantInfo";
import { OverviewTasksTable } from "../components/OverviewTasksTable";
import { trackEvent } from "@/utils/analytics/trackEvent";

export function OverviewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageContainer className="flex flex-col">
      <Text size="2xl" className="font-semibold mb-8">
        {t("overview.title")}
      </Text>

      <Box className="grid grid-cols-2 gap-10">
        <Box className="flex flex-col gap-10">
          <OverviewCard
            title={t("overview.last_movements")}
            headerIcon={MdTimeline}
            headerActions={
              <Button
                variant="text"
                size="xs"
                className="text-xs"
                onClick={() => {
                  trackEvent("Home Page - See more movements clicked");
                  navigate(routes.movements);
                }}
              >
                {t("overview.see_more")}
              </Button>
            }
          >
            <OverviewMovementsTable />
          </OverviewCard>

          <OverviewCard
            title={t("overview.cases_without_recent_movements")}
            headerIcon={MdOutlineDescription}
            headerActions={
              <Button
                variant="text"
                size="xs"
                className="text-xs"
                onClick={() => {
                  trackEvent(
                    "Home Page - See more cases without movement clicked",
                  );

                  navigate(
                    `${routes.cases}?active[eq]=true&last_movement_date[neq]=null&sort[]=last_movement_date:asc`,
                  );
                }}
              >
                {t("overview.see_more")}
              </Button>
            }
          >
            <OverviewCasesTable />
          </OverviewCard>
        </Box>

        <Box className="flex flex-col gap-10">
          <OverviewTenantInfo />

          <OverviewCard
            title={t("overview.next_dates")}
            headerIcon={MdOutlineEvent}
            headerColor="rose-300"
          >
            <OverviewEventsTable />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <hr style={{ width: "95%", borderColor: "lightgray" }} />
            </div>
            <OverviewTasksTable />
          </OverviewCard>

          <Box>
            <Text className="font-bold mb-3 text-info-900">
              {t("overview.quick_actions")}
            </Text>
            <Box className="flex gap-8">
              <Box
                as={Rrlink}
                to={routes.cases}
                onClick={() => trackEvent("Home Page - Cases shortcut clicked")}
                className="flex flex-col bg-neutral-50 p-6 rounded-md shadow-md group"
              >
                <Icon
                  as={MdOutlineDescription}
                  className="text-neutral-600 group-hover:text-primary-600"
                  size="4"
                />
                <Box
                  as="span"
                  className="text-xs text-neutral-900 group-hover:text-primary-600 font-semibold"
                >
                  {t("overview.go_to_cases")}
                </Box>
              </Box>
              <Box
                as={Rrlink}
                to={routes.credentials}
                onClick={() =>
                  trackEvent("Home Page - Credentials shortcut clicked")
                }
                className="flex flex-col bg-neutral-50 p-6 rounded-md shadow-md group"
              >
                <Icon
                  as={MdOutlineBadge}
                  className="text-neutral-600 group-hover:text-primary-600"
                  size="4"
                />
                <Box
                  as="span"
                  className="text-xs text-neutral-900 group-hover:text-primary-600 font-semibold"
                >
                  {t("overview.go_to_credentials")}
                </Box>
              </Box>
              <Box
                as={Rrlink}
                to={routes.users}
                onClick={() =>
                  trackEvent("Home Page - User configs shortcut clicked")
                }
                className="flex flex-col bg-neutral-50 p-6 rounded-md shadow-md group"
              >
                <Icon
                  as={MdOutlineGroup}
                  className="text-neutral-600 group-hover:text-primary-600"
                  size="4"
                />
                <Box
                  as="span"
                  className="text-xs text-neutral-900 group-hover:text-primary-600 font-semibold"
                >
                  {t("overview.go_to_users")}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
}
