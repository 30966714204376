import { fetcher } from "@/utils/fetchers/fetcher";
import { MovementComment } from "../types/MovementComment";
import { useQuery } from "@tanstack/react-query";

export const GET_MOVEMENT_COMMENTS_FETCH_KEY = "get-movement-comments";

const getComments = async (movementId: string) => {
  const response = await fetcher.get<MovementComment[]>(`/movements/${movementId}/comments`);
  return response.data;
};

export const useGetComments = (movementId: string) => {
  return useQuery({
    queryKey: [GET_MOVEMENT_COMMENTS_FETCH_KEY, movementId],
    queryFn: () => getComments(movementId),
  });
};