import { fetcher } from "@/utils/fetchers/fetcher";
import { User } from "../types/User";
import { useQuery } from "@tanstack/react-query";

export const GET_USERS_FETCH_KEY = "get-users";

const getUsers = async () => {
  const response = await fetcher.get<User[]>("/users");

  return response.data;
};

export const useGetUsers = () => {
  const query = useQuery({
    queryKey: [GET_USERS_FETCH_KEY],
    queryFn: () => getUsers(),
    select: (users) => {
      return users.filter(({ active }) => active);
    },
    refetchOnMount: false,
  });

  const findById = (id: string) => query.data?.find((user) => user.id === id);

  return {
    users: query.data ?? [],
    isLoading: query.isLoading,
    isError: query.isError,
    findById,
    error: query.error,
    refetch: query.refetch,
  };
};
