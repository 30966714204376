import { useDisclosure } from "@suit-ui/react";
import React from "react";

interface CollapseControllerProps {
  children?: (disclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
  }) => React.ReactNode;
}

export const CollapseController: React.FC<CollapseControllerProps> = ({
  children,
}) => {
  const disclosure = useDisclosure();

  return children?.(disclosure);
};
