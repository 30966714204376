import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetcher, fetcherCookie } from "../../../utils/fetchers/fetcher.ts";
import { useToast } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { MovementFilter } from "./useGetMovements.ts";
import { STORAGE_SESSION_NAME } from "@/features/session/conts.ts";
import { useRef } from "react";

interface DownloadAttachmentProps {
  id: string;
  isUploaded: boolean;
  filename: string;
}

const downloadAttachment = async ({
  id,
  isUploaded,
}: DownloadAttachmentProps) => {
  if (!isUploaded) await downloadFromSpody(id);
  return downloadFromS3(id, isUploaded);
};

const downloadFromS3 = async (attachmentId: string, isUploaded: boolean) => {
  const token = localStorage.getItem(STORAGE_SESSION_NAME);

  await fetcherCookie.get("/login", {
    params: {
      token,
    },
    withCredentials: true,
  });

  const url = `${fetcherCookie.getUri()}/attachments/${attachmentId}/download`;

  window.open(url, "__blank", "");

  return { isUploaded };
};

const downloadFromSpody = async (attachmentId: string) => {
  const response = await fetcher.get(`/scrapers/download_attachment`, {
    params: {
      attachment_id: attachmentId,
    },
  });
  const key = response.data.key;
  await pollSpodyDownload(attachmentId, key);

  return key;
};

const getPollSpodyDownload = async (attachmentId: string, key: string) => {
  const response = await fetcher.get(`/scrapers/poll_attachment_result`, {
    params: {
      attachment_id: attachmentId,
      key,
    },
  });

  return response.data;
};

const pollSpodyDownload = (attachmentId: string, key: string) => {
  return new Promise(function (resolve) {
    (async function waitForFoo() {
      const result = await getPollSpodyDownload(attachmentId, key);
      if (result) return resolve(result);
      setTimeout(waitForFoo, 1000);
    })();
  });
};

interface UseDownloadAttachmentProps {
  queryKey?: (string | number | MovementFilter | undefined)[];
}

export function useDownloadAttachment({
  queryKey,
}: UseDownloadAttachmentProps = {}) {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toastRef = useRef<string>();

  return useMutation({
    mutationFn: downloadAttachment,
    onMutate: ({ filename }) => {
      toastRef.current = toast.add({
        content: `${t("ui.downloading")} ${filename}`,
        position: "bottom-right",
        duration: 1000 * 60,
      });
    },
    onSuccess: ({ isUploaded }) => {
      if (!isUploaded) queryClient.invalidateQueries({ queryKey });

      toast.close(toastRef.current as string);
    },

    onError: () => {
      toast.close(toastRef.current as string);

      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });
}
