import { Box, Button, Icon, IconButton, Input, Table } from "@suit-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetClients } from "../useCases/useGetClients";
import { MdOutlineDelete, MdOutlineEdit, MdSearch } from "react-icons/md";
import { useDeleteClient } from "../useCases/useDeleteClient";
import { ClientModal } from "./ClientModal";
import { Client } from "../types/Client";
import { useUpdateClient } from "../useCases/useUpdateClient";
import { useCreateClient } from "../useCases/useCreateClient";
import { stringIncludes } from "@/utils/texts/stringIncludes";
import { TableBody } from "@/ui/TableBody";
import Confirm from "@/ui/Confirm";
import { trackEvent } from "@/utils/analytics/trackEvent";

export const ClientsTable = () => {
  const { t } = useTranslation();

  const [createClientModal, setCreateClientModal] = useState(false);
  const [updateClientModal, setUpdateClientModal] = useState<Client | null>(
    null,
  );
  const [search, setSearch] = useState("");

  const { clients, isLoading } = useGetClients();
  const { deleteClient, isPending: isDeleting } = useDeleteClient();
  const { udpateClient, isPending: isUpdating } = useUpdateClient();
  const { createClient, isPending: isCreating } = useCreateClient();

  const handleUpdateClient = async (data: Partial<Client>) => {
    if (!updateClientModal || !updateClientModal.code) return;

    await udpateClient({
      id: updateClientModal.id,
      data: {
        ...updateClientModal,
        ...data,
      },
    });
    trackEvent("Client updated");
    setUpdateClientModal(null);
  };

  const handleCreateClient = async (data: Partial<Client>) => {
    await createClient(data);
    trackEvent("Client created");
    setCreateClientModal(false);
  };

  const filteredClients = clients.filter(
    (client) =>
      stringIncludes(client.name, search) ||
      stringIncludes(client.code ?? "", search),
  );

  const isEmpty = !isLoading && filteredClients.length <= 0;

  return (
    <Box className="flex flex-col gap-4 mt-4">
      <Box className="flex justify-between items-center gap-4">
        <Input.Group size="lg" className="grow shrink max-w-4xl">
          <Input
            placeholder={t("clients.search_clients_placeholder")}
            className="w-full rounded-full peer"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Input.LeftElement className="opacity-50 peer-focus-visible:opacity-100 flex justify-center items-center w-10 h-10">
            <Icon as={MdSearch} size="6" className="text-neutral-600" />
          </Input.LeftElement>
        </Input.Group>

        <Button className="ml-auto" onClick={() => setCreateClientModal(true)}>
          {t("clients.create")}
        </Button>
      </Box>

      <Table.Container className="rounded-md" variant="elevated">
        <Table>
          <Table.Head className="bg-primary-800 text-neutral-50">
            <Table.Row className="text-neutral-50">
              <Table.Header>{t("clients.code")}</Table.Header>
              <Table.Header>{t("clients.name")}</Table.Header>
              <Table.Header>{t("ui.options")}</Table.Header>
            </Table.Row>
          </Table.Head>
          <TableBody
            isEmpty={isEmpty}
            title={t("ui.empty_data")}
            className="bg-neutral-50"
          >
            {filteredClients.map((item) => {
              return (
                <Table.Row
                  key={item.id}
                  className={"even:bg-neutral-100 odd:bg-neutral-50"}
                >
                  <Table.Cell>{item.code}</Table.Cell>
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell className="flex justify-center">
                    <IconButton
                      icon={<MdOutlineEdit />}
                      variant="text"
                      color="neutral"
                      className="text-neutral-600"
                      onClick={() => setUpdateClientModal(item)}
                    />

                    <Confirm
                      title={`${t("ui.delete")} ${item.code} - ${item.name}`}
                      onConfirm={async () => {
                        await deleteClient(item.id);
                        trackEvent("Client deleted");
                      }}
                      onConfirmText={t("ui.delete")}
                      button={
                        <IconButton
                          icon={<MdOutlineDelete />}
                          variant="text"
                          color="neutral"
                          className="text-neutral-600"
                          onClick={() => deleteClient(item.id)}
                        />
                      }
                      body={t("clients.confirm_delete_description")}
                      isLoading={isDeleting}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </TableBody>
        </Table>
      </Table.Container>

      <ClientModal
        isOpen={createClientModal}
        onClose={() => setCreateClientModal(false)}
        isLoading={isCreating}
        mode="create"
        onSubmit={handleCreateClient}
      />

      <ClientModal
        isOpen={!!updateClientModal}
        onClose={() => setUpdateClientModal(null)}
        isLoading={isUpdating}
        mode="update"
        defaultValues={updateClientModal}
        onSubmit={handleUpdateClient}
      />
    </Box>
  );
};
