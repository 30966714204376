import { fetcher } from "@/utils/fetchers/fetcher";
import { User } from "../types/User";
import { useMutation } from "@tanstack/react-query";

interface GetUserProps {
  id: string;
}

const getUser = async ({ id }: GetUserProps) => {
  const response = await fetcher.get<User>(`/users/${id}`);
  return response.data;
};

export const useGetUser = () => {
  return useMutation({
    mutationFn: getUser,
  });
};
