import { z } from "zod";

export const credentialFormSchema = z
  .object({
    credential_type: z.string({
      required_error: "credentials.errors.credential_type_required",
    }),
    username: z.string({
      required_error: "credentials.errors.username_required",
    }),
    password: z.string({
      required_error: "credentials.errors.password_required",
    }),
    name: z.string({
      required_error: "credentials.errors.name_required",
    }),
    user_ids: z.string().array().optional(),
    finders: z
      .string()
      .array()
      .nonempty({ message: "credentials.errors.finders_required" }),
    global: z.boolean(),
    search_params: z.record(z.string(), z.string()),
  })
  .required({
    credential_type: true,
    username: true,
    password: true,
    name: true,
    finders: true,
  });

export type CredentialFormData = z.infer<typeof credentialFormSchema>;
