import {
  Box,
  Dropdown,
  FilterButton,
  SelectMultiCheckbox,
} from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { TaskTable } from "./TaskTable";
import { AddTask } from "./AddTask";
import { TaskStatus } from "../types/Task";
import { useMemo, useState } from "react";
import { TaskFilters } from "../useCases/useGetTasks";
import { UserAccess } from "@/features/session/components/UserAccess";

interface TaskTabTablesProps {
  caseId: string;
  isCaseEditable?: boolean;
  availableUsers?: string[];
}

export function TaskTabTables({
  caseId,
  isCaseEditable,
  availableUsers,
}: TaskTabTablesProps) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<TaskFilters>({
    sort: ["end_date:desc"],
  });
  const statusOptions: {
    value: TaskStatus;
    label: string;
  }[] = useMemo(
    () => [
      {
        value: "todo",
        label: t("tasks.status_map.todo"),
      },
      {
        value: "in_progress",
        label: t("tasks.status_map.in_progress"),
      },
      {
        value: "done",
        label: t("tasks.status_map.done"),
      },
    ],
    [t],
  );

  return (
    <Box className="flex flex-col gap-3">
      <Box className="flex justify-between items-center gap-4">
        <Dropdown>
          <Dropdown.Button
            isSelected={!!filters.state?.or?.length}
            as={FilterButton}
            size="sm"
            className="whitespace-nowrap"
            onClear={() => {
              setFilters({
                state: {
                  or: [],
                },
              });
            }}
          >
            {t("tasks.status")}{" "}
            {!!filters.state?.or?.length && `(${filters.state?.or?.length})`}
          </Dropdown.Button>
          <Dropdown.List className="py-0 w-[380px]">
            <SelectMultiCheckbox
              valueAsObject={false}
              isMulti
              removeShadows
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              menuIsOpen
              isClearable={false}
              options={statusOptions}
              placeholder={t("ui.search_placeholder")}
              value={filters.state?.or}
              onChange={(values: []) => {
                setFilters({
                  state: {
                    or: [...values],
                  },
                });
              }}
            />
          </Dropdown.List>
        </Dropdown>

        {isCaseEditable && (
          <UserAccess permission="tasks-create">
            <AddTask
              availableUsers={availableUsers}
              type="cases"
              resourceId={caseId}
            />
          </UserAccess>
        )}
      </Box>

      <Box className="my-6">
        <TaskTable
          filters={filters}
          type="cases"
          resourceId={caseId}
          isEditableInline
        />
      </Box>
    </Box>
  );
}
