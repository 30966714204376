import {
  Button,
  Dialog,
  IconButton,
  IconButtonProps,
  useDisclosure,
} from "@suit-ui/react";
import { useDeleteCourt } from "../useCases/useDeleteCourt";
import { MdDeleteOutline } from "react-icons/md";
import { useTranslation } from "react-i18next";

interface DeleteCourtIconButtonProps {
  courtId?: string;
  size?: IconButtonProps["size"];
  onClose?: () => Promise<void>;
}

export const DeleteCourtIconButton: React.FC<DeleteCourtIconButtonProps> = ({
  courtId,
  size = "lg",
  onClose: _onClose,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync, isPending } = useDeleteCourt(courtId!);

  const handleOnClose = async () => {
    if (isPending) return;

    onClose();
  };

  const handleDelete = async () => {
    await mutateAsync();
    await _onClose?.();
    onClose();
  };

  const handleOnOpen = (event: React.MouseEvent) => {
    event.stopPropagation();

    onOpen();
  };

  return (
    <>
      <IconButton
        onClick={handleOnOpen}
        variant="text"
        icon={<MdDeleteOutline />}
        color="neutral"
        size={size}
      />

      <Dialog
        isOpen={isOpen}
        onClose={handleOnClose}
        status="warning"
        size="sm"
      >
        <Dialog.Overlay />

        <Dialog.Content>
          <Dialog.Header>{t("cases_data.courts.delete_court")}</Dialog.Header>
          <Dialog.CloseButton />

          <Dialog.Body>{t("cases_data.courts.delete_warning")}</Dialog.Body>

          <Dialog.Body>{t("cases_data.courts.confirm_dialog")}</Dialog.Body>
          <Dialog.Footer>
            <Button
              onClick={handleOnClose}
              variant="text"
              color="neutral"
              className="mr-2"
              size="sm"
            >
              {t("ui.cancel")}
            </Button>
            <Button size="sm" onClick={handleDelete} loading={isPending}>
              {t("ui.delete")}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
