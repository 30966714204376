import { useTranslation } from "react-i18next";
import { Box, Tabs } from "@suit-ui/react";
import { useGetEvents } from "../useCases/useGetEvents";
import { EventTable } from "../components/EventTable";
import { PageContainer } from "@/ui/containers/PageContainer";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import { PageTitle } from "@/ui/texts/PageTitle";
import { useMemo } from "react";
import { EventQueryParams } from "../types/Event";

export const EventsPage = () => {
  const { t } = useTranslation();

  const nextEventsParams = useMemo((): EventQueryParams => {
    return {
      filter: {
        startDate: {
          gte: new Date().toISOString(),
        },
      },
      sort: "start_date:asc",
    };
  }, []);

  const {
    events: nextEvents,
    isLoading: isLoadingNextEvents,
    isFetching: isFetchingNextEvents,
    pagination: nextEventsPagination,
    nextPage: nextEventsNextPage,
    prevPage: nextEventsPrevPage,
    onChangePageRows: onChangeNextEventsPageRows,
  } = useGetEvents("all", "", nextEventsParams);

  const pastEventsParams = useMemo((): EventQueryParams => {
    return {
      filter: {
        startDate: {
          lt: new Date().toISOString(),
        },
      },
      sort: "start_date:desc",
    };
  }, []);

  const {
    events: pastEvents,
    isLoading: isLoadingPastEvents,
    isFetching: isFetchingPastEvents,
    pagination: pastEventsPagination,
    nextPage: pastEventsNextPage,
    prevPage: pastEventsPrevPage,
    onChangePageRows: onChangePastEventsPageRows,
  } = useGetEvents("all", "", pastEventsParams);

  return (
    <PageContainer>
      <Box className="flex justify-between mb-4.5">
        <BreadcrumbNav />
      </Box>

      <PageTitle>{t("events.events")}</PageTitle>

      <Tabs className="mt-4">
        <Tabs.List>
          <Tabs.Tab>{t("events.short_next")}</Tabs.Tab>
          <Tabs.Tab>{t("events.short_past")}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panels className="bg-neutral-50">
          <Tabs.Panel>
            <EventTable
              events={nextEvents}
              isLoading={isLoadingNextEvents || isFetchingNextEvents}
              pagination={nextEventsPagination}
              nextPage={nextEventsNextPage}
              prevPage={nextEventsPrevPage}
              onChangePageRows={onChangeNextEventsPageRows}
              includeCase
              strongHeader
              hideActions
            />
          </Tabs.Panel>
          <Tabs.Panel>
            <EventTable
              events={pastEvents}
              isLoading={isLoadingPastEvents || isFetchingPastEvents}
              pagination={pastEventsPagination}
              nextPage={pastEventsNextPage}
              prevPage={pastEventsPrevPage}
              onChangePageRows={onChangePastEventsPageRows}
              includeCase
              strongHeader
              isEditable={false}
            />
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </PageContainer>
  );
};
