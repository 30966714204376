import { Box, Tooltip } from "@suit-ui/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface PriorityDotsProps {
  priority: number;
}

export function PriorityDots({ priority = 0 }: PriorityDotsProps) {
  const { t } = useTranslation();

  const priorityLabels = [
    t("tasks.low_priority"),
    t("tasks.medium_priority"),
    t("tasks.high_priority"),
  ];
  return (
    <Tooltip label={priorityLabels[priority]}>
      <Box className="flex gap-1">
        {Array.from({ length: 3 }).map((_, index) => (
          <Box
            key={`priority_dots_${index}`}
            className={clsx(
              "rounded-full w-3.5 h-3.5",
              priority >= index ? "bg-[#FDA14B]" : "bg-[#E7EAEB]",
            )}
          />
        ))}
      </Box>
    </Tooltip>
  );
}
