import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { CustomField } from "../types/CustomField";
import { CustomFieldValue } from "../types/CustomFieldValue";

export const GET_CUSTOM_FIELD_VALUES_FETCH_KEY = "get-custom-field-values";

const getCustomFieldValues = async (
  id: CustomField["id"],
): Promise<CustomFieldValue[]> => {
  const response = await fetcher(`/custom_fields/${id}/custom_field_values`);
  return response.data;
};

export const useGetCustomFieldValues = (customFieldId: string) => {
  const query = useQuery({
    queryKey: [GET_CUSTOM_FIELD_VALUES_FETCH_KEY, customFieldId],
    queryFn: () => getCustomFieldValues(customFieldId),
  });

  return {
    customFieldValues: query.data || [],
    isPending: query.isPending,
    isLoading: query.isLoading,
  };
};
