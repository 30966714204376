import { useTranslation } from "react-i18next";
import { useGetCourts } from "../useCases/useGetCourts";
import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  Input,
  Table,
} from "@suit-ui/react";
import { MdAdd, MdOutlineCreate, MdSearch } from "react-icons/md";
import { CreatorAvatar } from "@/features/movements/components/CreatorAvatar";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { useState } from "react";
import { EditCourtModal } from "./EditCourtModal";
import { Court, CreateCourt } from "../types/Court";
import { useCreateCourt } from "../useCases/useCreateCourt";
import { CreateCourtModal } from "./CreateCourtModal";
import { DeleteCourtIconButton } from "./DeleteCourtButton";
import { CourtsFilters } from "../types/CourtsFilters";
import { stringIncludes } from "@/utils/texts/stringIncludes";
import { TableBody } from "@/ui/TableBody";

export const CaseDataCourts = () => {
  const { t } = useTranslation();
  const { findById } = useGetUsers();
  const { data: courts, isLoading, refetch } = useGetCourts();

  const createCourt = useCreateCourt();
  const [courtFilters, setCourtFilters] = useState<CourtsFilters>({
    onlyManuallyCreated: true,
    name: "",
  });
  const [isCreateCourtModalOpen, setIsCreateCourtModalOpen] =
    useState<boolean>(false);
  const [isEditCourtModalOpen, setIsEditCourtModalOpen] =
    useState<boolean>(false);
  const [courtToEditId, setCourtToEditId] = useState<string>();

  const handleCreateCourt = async (data: CreateCourt) => {
    await createCourt.mutateAsync({
      court: data,
    });
  };

  const filteredCourts = courts?.filter((value) => {
    if (courtFilters.onlyManuallyCreated && value.spody_id) return false;

    if (!stringIncludes(value?.name ?? "", courtFilters.name ?? ""))
      return false;

    return true;
  });

  const optionsButton = (court: Court) => {
    if (court.spody_id === null) {
      return (
        <>
          <IconButton
            icon={<MdOutlineCreate />}
            variant="text"
            color="neutral"
            onClick={() => {
              setCourtToEditId(court.id);
              setIsEditCourtModalOpen(true);
            }}
          />

          <DeleteCourtIconButton
            courtId={court.id}
            size="md"
            onClose={async () => {
              await refetch();
            }}
          />
        </>
      );
    }
  };

  const isEmpty = !isLoading && !filteredCourts?.length;

  return (
    <>
      <Box className="flex mt-6 mb-2 pb-3">
        <Box className="flex justify-between ">
          <Input.Group className="w-[400px]">
            <Input
              placeholder={t("cases_data.courts.search_courts_placeholder")}
              className="rounded-full"
              onChange={(e) => {
                const value = e.target.value;
                setCourtFilters({ ...courtFilters, name: value });
              }}
            />
            <Input.LeftElement className="opacity-50 peer-focus-visible:opacity-100 flex justify-center items-center w-10 h-10">
              <Icon as={MdSearch} size="6" className="text-neutral-600" />
            </Input.LeftElement>
          </Input.Group>

          <Checkbox
            className="ml-6"
            checked={courtFilters.onlyManuallyCreated}
            onChange={(e) => {
              const newValue = e.target.checked;
              setCourtFilters({
                ...courtFilters,
                onlyManuallyCreated: newValue,
              });
            }}
          >
            {t("cases_data.courts.manually_created_only")}
          </Checkbox>
        </Box>
        <Box className="ml-auto">
          <Button
            onClick={() => setIsCreateCourtModalOpen(true)}
            leftIcon={<MdAdd />}
          >
            {t("cases_data.courts.add_court")}
          </Button>
        </Box>
      </Box>
      <Table.Container variant="elevated" className="rounded-md">
        <Table>
          <Table.Head className="bg-primary-800 text-neutral-50">
            <Table.Row className="text-neutral-50">
              <Table.Header>{t("cases_data.courts.name")}</Table.Header>
              <Table.Header>{t("cases_data.courts.created_by")}</Table.Header>
              <Table.Header>{t("ui.options")}</Table.Header>
            </Table.Row>
          </Table.Head>

          <TableBody isEmpty={isEmpty} title={t("ui.no_data")}>
            {!isLoading &&
              filteredCourts
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((court) => {
                  return (
                    <Table.Row key={`court_row_${court.id}`}>
                      <Table.Cell>{court.name}</Table.Cell>
                      <Table.Cell>
                        {court.created_by_id && (
                          <CreatorAvatar user={findById(court.created_by_id)} />
                        )}
                      </Table.Cell>
                      <Table.Cell>{optionsButton(court)}</Table.Cell>
                    </Table.Row>
                  );
                })}
          </TableBody>
        </Table>
      </Table.Container>

      <CreateCourtModal
        isOpen={isCreateCourtModalOpen}
        onClose={() => setIsCreateCourtModalOpen(false)}
        defaultValue={courtFilters.name}
        onSubmit={async (data) => {
          await handleCreateCourt(data);
          setIsCreateCourtModalOpen(false);
          await refetch();
        }}
      />

      <EditCourtModal
        isOpen={isEditCourtModalOpen}
        onClose={async () => {
          setIsEditCourtModalOpen(false);
          await refetch();
        }}
        courtId={courtToEditId}
      />
    </>
  );
};
