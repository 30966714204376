import {
  Dropdown,
  IconButton,
  Table,
  Icon,
  Tooltip,
  Avatar,
} from "@suit-ui/react";
import { Credential } from "../types/Credential";
import { MdMoreVert, MdCheck, MdOutlineClear } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useState, useMemo } from "react";
import EditCredentialModal from "./EditCredentialModal";
import { CredentialFormData } from "../types/CredentialFormData";
import Confirm from "@/ui/Confirm";
import { useDeleteCredentials } from "../useCases/useDeleteCredentials";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { CredentialType } from "../types/CredentialType";
import { Link } from "react-router-dom";
import { routes } from "@/router";

interface CredentalItemProps {
  credential: Credential;
  types: CredentialType;
}

const CredentalItem: React.FC<CredentalItemProps> = ({ credential, types }) => {
  const { t } = useTranslation();
  const {
    credential_type,
    name,
    username,
    password,
    id,
    global,
    user_ids,
    created_by_id,
    cases_count,
    state,
    finders,
    search_params,
  } = credential;
  const { mutateAsync, isPending } = useDeleteCredentials();
  const users = useGetUsers();
  const creatorUser = useMemo(() => {
    return users.users.find((user) => user.id === created_by_id);
  }, [created_by_id, users]);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  return (
    <Table.Row className="even:bg-neutral-100 odd:bg-neutral-50">
      <Table.Cell>
        {types[credential_type]?.full_name || credential_type}
      </Table.Cell>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{username}</Table.Cell>
      <Table.Cell>{creatorUser?.name}</Table.Cell>
      <Table.Cell>
        {global && t("ui.all")}

        {!global && !users.isLoading && (
          <Avatar.Group className="my-2" size="sm" max={3} variant="dark">
            {user_ids.map((userId) => {
              const user = users.users.find((user) => user.id === userId);

              return (
                <Tooltip key={`user_ids-id-${user?.id}`} label={user?.name}>
                  <Avatar name={user?.name} />
                </Tooltip>
              );
            })}
          </Avatar.Group>
        )}
      </Table.Cell>
      <Table.Cell>
        <Link to={`${routes.cases}?active[eq]=true&credential_id[eq]=${id}`}>
          {cases_count}
        </Link>
      </Table.Cell>
      <Table.Cell>
        {state === "ok" && <Icon className="text-success-700" as={MdCheck} />}
        {state === "blocked" && (
          <Icon className="text-danger-700" as={MdOutlineClear} />
        )}
      </Table.Cell>
      <Table.Cell>
        <Dropdown placement="bottom-end" withPortal>
          <Dropdown.Button
            as={IconButton}
            color="neutral"
            icon={<MdMoreVert />}
            variant="text"
            className="mx-auto"
          />

          <Dropdown.List>
            <Dropdown.Item onClick={() => setIsEditModalOpen(true)}>
              {t("ui.edit")}
            </Dropdown.Item>

            <Confirm
              title={t("credentials.delete_credential_title")}
              onConfirm={() => mutateAsync(id)}
              onConfirmText={t("ui.delete")}
              button={
                <Dropdown.Item className="text-danger-600 hover:bg-danger-100 active:(bg-danger-200 text-danger-700)">
                  {t("ui.delete")}
                </Dropdown.Item>
              }
              onOpen={(e) => e.stopPropagation()}
              body={t("credentials.delete_credential_confirmation")}
              isLoading={isPending}
            />
          </Dropdown.List>
        </Dropdown>
      </Table.Cell>

      <EditCredentialModal
        credential={
          {
            credential_type,
            name,
            username,
            password,
            global,
            user_ids,
            finders,
            search_params,
          } as CredentialFormData
        }
        credentialId={id}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
      />
    </Table.Row>
  );
};

export { CredentalItem };
