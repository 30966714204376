import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { EventForm, EventTypes, GetEventUrl } from "../types/Event";
import { GET_EVENTS_FETCH_KEY } from "./useGetEvents";

const createEvent = async (type: EventTypes, id: string, data: EventForm) => {
  const url = GetEventUrl(type, id);
  const params = {
    ...data,
    resource_id: id,
    resource_type: type,
  };

  const response = await fetcher.post<Event>(url, params);

  return response.data;
};

export const useCreateEvent = (type: EventTypes, id: string) => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: EventForm) => createEvent(type, id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_EVENTS_FETCH_KEY] });

      toast.add({
        content: t("events.create_success"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("events.errors.create_failed"),
        status: "danger",
      });
    },
  });
};
