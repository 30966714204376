import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Client } from "../types/Client";
import { GET_CLIENTS_FETCH_KEY } from "./useGetClients";

const updateClient = async (
  id: Client["id"],
  data: Partial<Omit<Client, "id">>,
) => {
  const response = await fetcher.put<Client>(`/clients/${id}`, {
    client: data,
  });
  return response.data;
};

export const useUpdateClient = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (opts: {
      id: Client["id"];
      data: Partial<Omit<Client, "id">>;
    }) => updateClient(opts.id, opts.data),
    onSuccess: async () => {
      // FIXME - for some reason when data._id is used, the query GET_CLIENTS_FETCH_KEY (useGetClients) is not invalidated
      /*       await queryClient.invalidateQueries({
        queryKey: [GET_CLIENTS_FETCH_KEY, data._id],
      }); */
      await queryClient.invalidateQueries({
        queryKey: [GET_CLIENTS_FETCH_KEY],
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return { udpateClient: mutateAsync, isPending };
};

