import { fetcher } from "@/utils/fetchers/fetcher";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "@suit-ui/react";
import { GET_CLIENTS_FETCH_KEY } from "./useGetClients";
import { Client } from "../types/Client";

const createClient = async (data: Partial<Client>) => {
  const response = await fetcher.post<Client>("/clients", { client: data });
  return response.data;
};

export const useCreateClient = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toast = useToast();

  const { mutateAsync, isPending, isError } = useMutation({
    mutationFn: async (data: Partial<Client>) => createClient(data),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [GET_CLIENTS_FETCH_KEY],
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return { createClient: mutateAsync, isPending, isError };
};

