import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function useFilters<T>(initState: T) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initFilters = useMemo(() => {
    if (searchParams.size === 0) return initState;

    const parsedParms = qs.parse(searchParams.toString(), {
      decoder: (str, defaultDecoder, charset) => {
        const value = defaultDecoder(str, defaultDecoder, charset);

        if (value === "true") return true;
        if (value === "false") return false;

        return value;
      },
    }) as T;

    return { ...initState, ...parsedParms };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filters, setFilters] = useState<T>(initFilters);

  useEffect(() => {
    setSearchParams(qs.stringify(filters), { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return [filters, setFilters] as const;
}
