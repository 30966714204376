import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FormControl,
  Modal,
  Select,
  useDisclosure,
} from "@suit-ui/react";
import { useMemo, JSX } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import Confirm from "@/ui/Confirm";

const schema = z.object({
  asignees: z.array(z.string().min(1)).min(1),
});

type CaseUserAssignModalFormData = z.infer<typeof schema>;

interface CaseUserAssignModalProps {
  title: string;
  submitButtonText: string;
  button?: JSX.Element | null;
  onClose: () => void;
  onSubmit: (users: string[]) => Promise<void>;
  defaultValues?: Partial<CaseUserAssignModalFormData>;
  isAssign?: boolean;
  total?: number;
}

export function CaseUserAssignModal({
  title,
  submitButtonText,
  button,
  onClose: closeHandler,
  onSubmit: submitHandler,
  defaultValues: initData,
  isAssign = true,
  total,
}: CaseUserAssignModalProps) {
  const users = useGetUsers();
  const { t } = useTranslation();

  const defaultValues = useMemo(
    () =>
      initData || {
        asignees: [],
      },
    [initData],
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CaseUserAssignModalFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
    defaultValues,
  });

  const usersOptions = users.users.map((user) => ({
    label: user.name,
    value: user.id,
  }));

  const {
    onOpen: _onOpen,
    isOpen: _isOpen,
    onClose: _onClose,
  } = useDisclosure();

  const onClose = () => {
    _onClose();
    closeHandler();
  };

  const onSubmit = handleSubmit(async (data) => {
    onClose();
    submitHandler(data.asignees || []);
  });

  return (
    <>
      {button && (
        <button.type
          {...button.props}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            _onOpen();
          }}
        />
      )}

      <Modal isOpen={_isOpen} onClose={onClose} scrollBehavior="outside">
        <Modal.Overlay />

        <Modal.Content as="form" noValidate onSubmit={onSubmit}>
          <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
            {title}
          </Modal.Header>
          <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />

          <Modal.Body className="flex flex-col gap-3">
            <FormControl isInvalid={!!errors.asignees}>
              <FormControl.Label className="mb-3">
                {isAssign
                  ? t("cases.association_user_warning_title_text")
                  : t("cases.unassociation_user_warning_title_text")}
              </FormControl.Label>

              <Controller
                name="asignees"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={usersOptions}
                    isLoading={users.isLoading}
                    valueAsObject={false}
                    menuPosition="fixed"
                    isMulti
                    placeholder={t("cases.attendees_placeholder")}
                  />
                )}
              />

              <FormControl.ErrorMessage>
                {t("form_errors.field_required")}
              </FormControl.ErrorMessage>
            </FormControl>
          </Modal.Body>

          <Modal.Footer className="flex items-center">
            <Button
              className="mr-2"
              variant="text"
              color="neutral"
              onClick={onClose}
            >
              {t("ui.cancel")}
            </Button>
            <Confirm
              title={
                isAssign
                  ? t("cases.association_user_warning_title_text")
                  : t("cases.unassociation_user_warning_title_text")
              }
              onConfirm={onSubmit}
              onConfirmText={
                isAssign
                  ? t("cases.yes_associate_text")
                  : t("cases.yes_unassociate_text")
              }
              button={<Button type="button">{submitButtonText}</Button>}
              body={
                isAssign
                  ? t("cases.association_causes_warning_text", {
                      total,
                    })
                  : t("cases.unassociation_causes_warning_text")
              }
            />
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}
