import { useDisclosure } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { EditableTaskModal } from "./EditableTaskModal";
import { TaskTypes } from "../useCases/useGetTasks";
import { EditTaskData, useEditTask } from "../useCases/useEditTask";
import { Task } from "../types/Task";
import { parseISO } from "date-fns";
import { trackEvent } from "@/utils/analytics/trackEvent";
import { JSX } from "react";

interface EditTaskProps {
  task: Task;
  button: JSX.Element;
}

export function EditTask({ task, button }: EditTaskProps) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync, isPending } = useEditTask({
    type: task.resource_type as TaskTypes,
    resourceId: task.resource_id,
    id: task._id,
  });

  const onSubmit = async (data: EditTaskData) => {
    await mutateAsync(data);
    trackEvent("Task updated");
  };

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOpen();
  };

  return (
    <>
      <button.type {...button.props} onClick={handleOpen} />

      <EditableTaskModal
        availableUsers={task?.case?.user_ids}
        defaultValues={{
          ...task,
          start_date: task.start_date ? parseISO(task.start_date) : undefined,
          end_date: task.end_date ? parseISO(task.end_date) : undefined,
        }}
        title={t("tasks.edit_task")}
        isOpen={isOpen}
        onClose={onClose}
        submitButtonText={t("ui.edit")}
        onSubmit={onSubmit}
        isPending={isPending}
      />
    </>
  );
}
