import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useTranslation } from "react-i18next";

interface RegenerateSummaryParams {
  movementId: string;
  attachmentId: string;
}

const regenerateSummary = async ({
  movementId,
  attachmentId,
}: RegenerateSummaryParams) => {
  const reponse = await fetcher.post(
    `/movements/${movementId}/attachments/${attachmentId}/summarize`,
  );

  return reponse.data;
};

export const useRegenerateAttachmentSummary = () => {
  const toast = useToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: regenerateSummary,
    onSuccess: async () => {
      toast.add({
        content: t("attachments.toast_generating_ai_summary"),
        status: "success",
      });
    },

    onError: (args) => {
      console.error(args);
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });
};
