import { Movement } from "@/features/cases/types/Movement";
import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET_CASE_MOVEMENTS_FETCH_KEY } from "./useGetMovements";

const updateMovement = async (data: Movement) => {
  const response = await fetcher.put<Movement>(
    `/movements/${data.id}`, { movement: data },
  );

  return response.data;
};

export const useUpdateMovement = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: updateMovement,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: [GET_CASE_MOVEMENTS_FETCH_KEY, data.id]
      });

      toast.add({
        content: "Movimiento actualizado exitosamente",
        status: "success",
      })
    },

    onError: () => {
      toast.add({
        content: "Error inesperado",
        status: "danger",
      })
    }
  })

  return {
    updateMovement: mutateAsync,
    isPending,
  };
};