import {
  Box,
  Button,
  Icon,
  Link,
  Modal,
  Tooltip,
  useDisclosure,
  Text,
  IconButton,
  Spinner,
} from "@suit-ui/react";
import { MovementAttachment } from "../types/Movement";
import {
  MdOutlineAttachFile,
  MdOutlineCached,
  MdOutlineDownload,
  MdOutlineStarBorder,
} from "react-icons/md";
import { useDownloadAttachment } from "@/features/movements/useCases/useDownloadAttachment";
import { MovementFilter } from "@/features/movements/useCases/useGetMovements";
import { trackEvent } from "@/utils/analytics/trackEvent";
import { Eyeglasses } from "./EyeglassesIcon";
import { FeedbackSummaryAI } from "./FeedbackSummaryAI";
import { useTranslation } from "react-i18next";
import { useRegenerateAttachmentSummary } from "../useCases/useRegenerateAttachmentSummary";
import { useGetMovementAttachment } from "../useCases/useGetMovementAttachment";
import { useMemo } from "react";
import { useGetSettings } from "@/features/session/useCases/useGetSettings";
import { UserAccess } from "@/features/session/components/UserAccess";

export interface AttachmentLinkProps {
  attachment: MovementAttachment;
  queryKey?: (string | number | MovementFilter | undefined)[];
  movementId: string;
}

interface SummaryProps {
  headerButtons?: JSX.Element;
  custom_data: Record<string, string> | undefined;
}

const Summary = ({ headerButtons, custom_data }: SummaryProps) => {
  const { t } = useTranslation();
  const { Resumen = "", ...importantData } = custom_data || {};
  const importantDataKeys = Object.keys(importantData);

  return (
    <Box className="border border-primary-500 bg-primary-100 rounded-sm p-4 mt-4">
      <Box className="flex justify-between mb-3">
        <Icon as={Eyeglasses} size="7" className="text-primary-700" />

        {headerButtons}
      </Box>

      {Resumen.length === 0 && (
        <Text className="text-neutral-500">
          {t("attachments.ai_summary_no_available")}
        </Text>
      )}

      {Resumen.length !== 0 && (
        <>
          <Box className="font-semibold text-primary-800">
            {t("attachments.summary")}
          </Box>
          <Box className="w-full">
            <Text className="mb-1">{Resumen}</Text>
          </Box>
        </>
      )}

      {importantDataKeys.length !== 0 && (
        <>
          <Box className="h-px mt-4 mb-2 mx-auto w-[120px] border border-neutral-300" />

          <Box className="text-sm flex flex-col gap-1 w-full">
            {importantDataKeys.map((dataKey) => (
              <Box key={`importantData-${dataKey}`}>
                <Box
                  as="strong"
                  className="font-semibold text-primary-800 capitalize mr-2"
                >
                  {dataKey}:
                </Box>

                {importantData[dataKey]}
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

const AttachmentLink: React.FC<AttachmentLinkProps> = ({
  attachment,
  queryKey,
  movementId,
}) => {
  const { summarize_status, custom_data, key, filename, content_type } =
    attachment;
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { settings } = useGetSettings();
  const downloadAttachment = useDownloadAttachment({ queryKey });
  const { mutateAsync: regenerateSummary, isPending: isRegenerating } =
    useRegenerateAttachmentSummary();
  const { isPollingAttachment, setIsPollingAttachment } =
    useGetMovementAttachment({
      movementId,
      attachmentId: attachment._id,
    });

  const isUploaded = !!key;
  const isAbsoluteUrl = attachment.url?.match(/^https?:/) && !isUploaded;
  const href = () => {
    if (isAbsoluteUrl) {
      return attachment.url;
    }

    return isUploaded ? "#" : "##";
  };

  const showAISummary = useMemo<boolean>(() => {
    if (!summarize_status || !settings || !key) {
      return false;
    }

    if (content_type !== "application/pdf") {
      return false;
    }

    const summarizeAttachmentsVals = Object.values(
      settings.merged.features_summarize_attachments || {},
    );

    if (summarizeAttachmentsVals.some((v) => v.manual)) {
      return true;
    }

    if (summarize_status !== "none") {
      return true;
    }

    return false;
  }, [summarize_status, settings, key, content_type]);

  const summarizeTooltipMsg = () => {
    if (isPollingAttachment) {
      return t("attachments.generating_ai_summary");
    }

    if (summarize_status === "success") {
      return t("attachments.ai_summary_available");
    }

    if (summarize_status === "fail") {
      return t("attachments.ai_summary_failed");
    }

    return t("attachments.ai_summary_available");
  };

  const downloadAttachmentLink = () => {
    if (isAbsoluteUrl) {
      return trackEvent("Attachment opened");
    }

    if (downloadAttachment.isPending) return;

    downloadAttachment.mutate({
      id: attachment._id,
      isUploaded: isUploaded,
      filename,
    });

    trackEvent("Attachment downloaded");
  };

  if (!showAISummary) {
    return (
      <Link
        key={attachment._id}
        href={href()}
        target={isAbsoluteUrl ? "_blank" : undefined}
        disableVisitedStyle
        className="flex items-center"
        onClick={(e) => {
          if (!isAbsoluteUrl) {
            e.preventDefault();
          }
          downloadAttachmentLink();
        }}
      >
        <Icon as={MdOutlineAttachFile} size="4" className="mr-1" />
        {filename}

        <IconButton
          icon={<MdOutlineDownload />}
          variant="outline"
          size="xs"
          className="ml-1 border-none"
        />
      </Link>
    );
  }

  return (
    <Box className="flex items-center" onClick={onOpen}>
      <Link disableVisitedStyle className="flex items-center my-1">
        <Icon as={MdOutlineAttachFile} size="4" className="mr-1" />
        {filename}
      </Link>

      <Box className="flex my-auto items-center">
        {isPollingAttachment && <Spinner size="5" />}
        {!isPollingAttachment && (
          <Tooltip label={summarizeTooltipMsg()} shouldWrapChildren>
            <IconButton
              icon={<Eyeglasses />}
              variant="outline"
              size="xs"
              color={summarize_status === "fail" ? "danger" : "success"}
              className="ml-1 border-none"
            />
          </Tooltip>
        )}

        <Tooltip label={t("attachments.download")} shouldWrapChildren>
          <IconButton
            icon={<MdOutlineDownload />}
            variant="outline"
            size="xs"
            className="ml-1 border-none"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              downloadAttachmentLink();
            }}
          />
        </Tooltip>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside">
        <Modal.Overlay />

        <Modal.Content>
          <Modal.Header className="bg-primary-800 flex gap-1 items-center text-neutral-50 rounded-t-md">
            <Icon as={MdOutlineAttachFile} size="5" className="mr-1" />{" "}
            {filename}
          </Modal.Header>

          <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
          <Modal.Body>
            <Box className="text-sm flex">
              <Icon as={MdOutlineStarBorder} size="4" className="mt-1 mr-1" />

              {t("attachments.ai_summary_title")}
            </Box>

            <Summary
              custom_data={custom_data}
              headerButtons={
                <Box className="flex gap-2 justify-end">
                  <UserAccess permission="feedbacks-create">
                    <FeedbackSummaryAI
                      attachmentId={attachment._id}
                      filename={attachment.filename}
                      summary={<Summary custom_data={custom_data} />}
                    />
                  </UserAccess>

                  <UserAccess permission="attachments-summarize">
                    <Tooltip
                      label={
                        isPollingAttachment
                          ? t("attachments.generating_ai_summary")
                          : t("attachments.regenerate_summary")
                      }
                      placement="bottom"
                    >
                      <Box className="flex my-auto">
                        {isPollingAttachment && <Spinner size="5" />}
                        {!isPollingAttachment && (
                          <IconButton
                            icon={<MdOutlineCached />}
                            variant="text"
                            size="sm"
                            disabled={isRegenerating || isPollingAttachment}
                            onClick={async () => {
                              await regenerateSummary({
                                movementId,
                                attachmentId: attachment._id,
                              });
                              setIsPollingAttachment(true);
                            }}
                          />
                        )}
                      </Box>
                    </Tooltip>
                  </UserAccess>
                </Box>
              }
            />
          </Modal.Body>
          <Modal.Footer className="gap-2">
            <Button
              onClick={(e) => {
                e.preventDefault();
                downloadAttachmentLink();
              }}
              size="sm"
              className="flex gap-1"
              rightIcon={<MdOutlineAttachFile />}
            >
              {t("attachments.download")}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Box>
  );
};

export default AttachmentLink;
