import { Button, useDisclosure } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { EventForm, EventTypes } from "../types/Event";
import { useCreateEvent } from "../useCases/useCreateEvent";
import { MdOutlineAdd } from "react-icons/md";
import { EventModal } from "./EventModal";
import { trackEvent } from "@/utils/analytics/trackEvent";

const defaultValues: EventForm = {
  summary: "",
  description: "",
  start_date: null,
  end_date: null,
  all_day: false,
  attendees: [""],
};

interface CreateEventButtonProps {
  resourceId: string;
  resourceType: EventTypes;
  availableEventUsers?: string[];
}

export const CreateEventButton: React.FC<CreateEventButtonProps> = ({
  resourceId,
  resourceType,
  availableEventUsers = [],
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync, isPending } = useCreateEvent(resourceType, resourceId);

  const onSubmit = async (data: EventForm) => {
    await mutateAsync(data);
    trackEvent("Event created");
  };

  return (
    <>
      <Button size="sm" onClick={onOpen} leftIcon={<MdOutlineAdd />}>
        {t("events.create_event")}
      </Button>

      <EventModal
        title={t("events.create_event")}
        submitButtonText={t("events.create")}
        defaultValues={defaultValues}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        isPending={isPending}
        availableEventUsers={availableEventUsers}
      />
    </>
  );
};
