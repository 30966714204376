import { Box, Button, MotionBox, Text } from "@suit-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos } from "react-icons/md";

interface WizardProps {
  steps: React.ReactElement[];
  onComplete?: () => void;
}

const Wizard: React.FC<WizardProps> = ({ steps, onComplete }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);

  return (
    <Box className="flex flex-col h-full w-full">
      <Button
        variant="text"
        className="mr-auto mb-6"
        size="sm"
        leftIcon={<MdArrowBackIos />}
        onClick={() => setCurrentStep(currentStep - 1)}
        disabled={currentStep === 0}
      >
        {t("ui.back")}
      </Button>

      <Text className="text-lg font-semibold text-neutral-600 mb-2">
        {t("ui.wizard_step_counting", {
          currentStep: currentStep + 1,
          totalSteps: steps.length,
        })}
      </Text>

      <Box className="flex w-full">
        {steps.map((step, idx) => (
          <MotionBox
            className="flex flex-col w-full"
            key={`wizard-step-${idx}`}
            initial={idx === 0}
            animate={
              currentStep === idx
                ? {
                    opacity: 1,
                    display: "flex",
                  }
                : {
                    opacity: 0,
                    display: "none",
                  }
            }
          >
            {
              <step.type
                {...step.props}
                onStepComplete={() => {
                  if (currentStep === steps.length - 1) {
                    onComplete?.();
                    return;
                  }
                  setCurrentStep(currentStep + 1);
                }}
              />
            }
          </MotionBox>
        ))}
      </Box>
    </Box>
  );
};

export default Wizard;
