import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";

import { CaseFinder } from "../types/CaseFinder";

export const GET_CASE_FINDERS_KEY = "get_case_finders";

const getCaseFinders = async () => {
  const response = await fetcher.get<CaseFinder[]>("/scrapers/case_finders");
  return response.data;
};

export function useGetCaseFinders() {
  return useQuery({
    queryKey: [GET_CASE_FINDERS_KEY],
    queryFn: getCaseFinders,
    refetchOnMount: false,
  });
}
