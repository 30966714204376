import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";

export const GET_POLL_CONNECT_CASE_RESULT_KEY =
  "get_poll_connect_case_result_key";

const getPollConnectCaseResult = async (key?: string) => {
  const pollingInProgressMessage = "polling in progress";
  try {
    const response = await fetcher.get(
      "/scrapers/poll_connection_scrape_result",
      {
        params: {
          key,
        },
      },
    );

    if (response.data === null) {
      throw new Error("polling in progress");
    }

    return response.data;
  } catch (error) {
    if ((error as Error)?.message === pollingInProgressMessage) {
      throw new Error(pollingInProgressMessage);
    }

    return {
      error: "polling error",
    };
  }
};

export function usePollConnectCaseResult(key?: string) {
  return useQuery({
    queryKey: [GET_POLL_CONNECT_CASE_RESULT_KEY, key],
    queryFn: () => getPollConnectCaseResult(key),
    retry: true,
    retryDelay: 1000,
    enabled: !!key,
  });
}
