import { fetcher } from "@/utils/fetchers/fetcher";

import { useToast } from "@suit-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const updateUserPassword = async ({
  userId,
  password,
}: {
  userId: string;
  password: string;
}) => {
  const res = await fetcher.patch(`/users/${userId}/password`, { password });

  return res.data;
};

export const useUpdateUserPassword = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const { mutate, isPending, mutateAsync } = useMutation({
    mutationFn: updateUserPassword,
    onSuccess: async () => {
      toast.add({
        content: t("session.update_password_success"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("session.update_password_failed"),
        status: "danger",
      });
    },
  });

  return {
    updateUserPassword: mutate,
    updateUserPasswordAsync: mutateAsync,
    isPending: isPending,
  };
};

