import { useTenantData } from "@/features/session/useCases/useTenantData";
import { PlaceholderLoader } from "@/ui/PlaceholderLoader";
import { Box } from "@suit-ui/react";
import { useTranslation } from "react-i18next";

export function OverviewTenantInfo() {
  const { t } = useTranslation();
  const { data, isLoading } = useTenantData({ refetchOnMount: true });

  if (isLoading) {
    return (
      <Box className="bg-neutral-50 rounded-md shadow-md p-2.5 flex justify-center">
        <Box className="px-10">
          <Box className="text-xs text-neutral-900">
            {t("overview.total_cases")}
          </Box>

          <PlaceholderLoader className="w-20 h-7.5" />
        </Box>
        <Box className="px-10">
          <Box className="text-xs text-neutral-900">
            {t("overview.connected_cases")}
          </Box>
          <PlaceholderLoader className="w-24 h-7.5" />
        </Box>
        <Box className="px-10">
          <Box className="text-xs text-neutral-900">
            {t("overview.active_users")}
          </Box>
          <PlaceholderLoader className="w-24 h-7.5" />
        </Box>
      </Box>
    );
  }

  return (
    <Box className="bg-neutral-50 rounded-md shadow-md p-2.5 flex justify-center">
      <Box className="px-10">
        <Box className="text-xs text-neutral-900">
          {t("overview.total_cases")}
        </Box>
        <Box className="text-xl font-semibold text-primary-800">
          {data?.metrics?.cases_created}/{data?.plan?.cases_created}
        </Box>
      </Box>
      <Box className="px-10">
        <Box className="text-xs text-neutral-900">
          {t("overview.connected_cases")}
        </Box>
        <Box className="text-xl font-semibold text-primary-800">
          {data?.metrics?.cases_connected}
        </Box>
      </Box>
      <Box className="px-10">
        <Box className="text-xs text-neutral-900">
          {t("overview.active_users")}
        </Box>
        <Box className="text-xl font-semibold text-primary-800">
          {data?.metrics?.users_active}
        </Box>
      </Box>
    </Box>
  );
}
