import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { UserRole } from "../types/UserRole";

export const GET_ROLES_FETCH_KEY = "get-roles";

const getRoles = async () => {
  const response = await fetcher.get<UserRole[]>("/roles");

  return response.data;
};

export const useGetRoles = () => {
  const query = useQuery({
    queryKey: [GET_ROLES_FETCH_KEY],
    queryFn: () => getRoles(),
  });

  return {
    roles: query.data ?? [],
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    refetch: query.refetch,
  };
};

