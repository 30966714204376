import * as Icons from "react-icons/md";
import { IconType } from "react-icons";
import { User } from "@/features/users/types/User";
import { Avatar, Box, Icon, Tooltip } from "@suit-ui/react";

interface CreatorAvatarProps {
  className?: string;
  user: User | undefined;
}

export const CreatorAvatar: React.FC<CreatorAvatarProps> = ({
  className = "",
  user,
}) => {
  const { name, avatar } = user || {};

  return (
    <Tooltip label={name}>
      {avatar && (
        <Box className="flex items-center border border-neutral-900 rounded-full w-10 h-10">
          <Icon
            as={Icons[avatar as keyof IconType]}
            className="m-auto"
            size="6"
          />
        </Box>
      )}

      {!avatar && (
        <Avatar
          name={name}
          className={`my-2 ${className}`}
          size="sm"
          variant="dark"
        />
      )}
    </Tooltip>
  );
};
