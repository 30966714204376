import Confirm from "@/ui/Confirm";
import { useTranslation } from "react-i18next";
import { User } from "../types/User";
import { Box, Dropdown } from "@suit-ui/react";
import { useState } from "react";
import { useGetUser } from "../useCases/useGetUser";
import { useDeleteUser } from "../useCases/useDeleteUser";
import { trackEvent } from "@/utils/analytics/trackEvent";

interface DeleteUserConfirmModalProps {
  user: User;
  disabled?: boolean;
}

export function DeleteUserConfirmModal({
  user,
  disabled = false,
}: DeleteUserConfirmModalProps) {
  const { t } = useTranslation();
  const [casesCount, setCasesCount] = useState<number | null>(null);
  const { mutateAsync: deleteUser, isPending: isDeleting } = useDeleteUser();
  const { mutateAsync: getUser, isPending } = useGetUser();
  const { name, active, id, role_id } = user;

  return (
    <Confirm
      isLoading={isPending || isDeleting}
      title={t("users.delete_modal_title", {
        name: name ?? "",
      })}
      onConfirm={() => {
        deleteUser({ userId: id, role: role_id, name });
        trackEvent("User deleted");
      }}
      onConfirmText={t("ui.delete")}
      button={
        <Dropdown.Item
          className={`${disabled ? "opacity-50 cursor-not-allowed" : ""} ${
            active
              ? "text-danger-600 hover:bg-danger-100 active:(bg-danger-200 text-danger-700)"
              : "text-success-600 hover:bg-success-100 active:(bg-success-200 text-success-700)"
          }`}
        >
          {active ? t("ui.delete") : t("ui.activate")}
        </Dropdown.Item>
      }
      body={
        <Box>
          {isPending && t("users.fetching_user_data")}
          {!isPending &&
            t("users.delete_modal_description", {
              count: casesCount || 0,
              casesCount,
            })}
        </Box>
      }
      onOpen={async () => {
        const { cases_count } = await getUser({ id });
        setCasesCount(cases_count || 0);
      }}
      buttonDisabled={disabled}
    />
  );
}
