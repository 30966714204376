import { useMemo, JSX } from "react";
import { useGetCustomFieldsValuesById } from "../useCases/useGetCustomFieldsValuesById";

interface SelectOptionsWrapperProps {
  children: JSX.Element;
  fieldId: string;
}

export function SelectOptionsWrapper({
  children: Select,
  fieldId,
}: SelectOptionsWrapperProps) {
  const { isLoading, data } = useGetCustomFieldsValuesById(fieldId);

  const options = useMemo(() => {
    return (
      data?.map((option) => ({
        label: option.name,
        value: option.name,
      })) || []
    );
  }, [data]);

  return (
    <Select.type {...Select.props} loading={isLoading} options={options} />
  );
}
