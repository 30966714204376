import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateAttachment,
  Attachment,
} from "@/features/movements/types/Attachment";
import { fetcher } from "@/utils/fetchers/fetcher.ts";
import { useToast } from "@suit-ui/react";
import { MovementFilter } from "./useGetMovements";
import { useTranslation } from "react-i18next";

interface CreateAttachmentProps {
  movementId: string;
  attachment: CreateAttachment;
}

const createAttachment = async (params: CreateAttachmentProps) => {
  const response = await fetcher.post<Attachment>(
    `/movements/${params.movementId}/attachments`,
    {
      file: params.attachment.file,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response.data;
};

interface UseCreateAttachmentProps {
  queryKey?: (string | number | MovementFilter | undefined)[];
}

export const useCreateAttachment = ({
  queryKey,
}: UseCreateAttachmentProps = {}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: createAttachment,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
      toast.add({
        content: t("attachments.attachment_done"),
        status: "success",
      });
    },

    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });
};
