import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { GET_TASK_ATTACHMENTS_FETCH_KEY } from "./useGetTaskAttachments";

const deleteAttachment = async (attachmentId: string) => {
  const response = await fetcher.delete(`/attachments/${attachmentId}`);

  return response.data;
};

export function useDeleteTaskAttachment(taskId: string) {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ attachmentId }: { attachmentId: string }) =>
      deleteAttachment(attachmentId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_TASK_ATTACHMENTS_FETCH_KEY, taskId],
      });
    },
    onError: () => {
      toast.add({
        content: t("tasks.errors.delete_attachment_failed"),
        status: "danger",
      });
    },
  });
}
