import { SSO_URL } from "../../../configs";

export const navigateToSSO = (queryParams: {
  redirect?: string;
  tenant: string;
  isLogout?: boolean;
}) => {
  const url = new URL(SSO_URL.replace("{tenant}", queryParams.tenant));

  if (queryParams?.redirect)
    url.searchParams.append("redirect", queryParams.redirect);

  if (queryParams?.isLogout) url.searchParams.append("logout", "true");

  window.location.href = url.toString();
};
