import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET_USERS_FETCH_KEY } from "./useGetUsers";
import { GET_CURRENT_USER_FETCH_KEY } from "@/features/session/useCases/useCurrentUser";
import { useTranslation } from "react-i18next";
import { User } from "../types/User";
import { fetcher } from "@/utils/fetchers/fetcher";

export const deleteUser = async ({
  userId,
  role,
  name,
}: {
  userId: User["id"];
  role: User["role_id"];
  name: User["name"];
}) => {
  const res = await fetcher.put(`/users/${userId}`, {
    user: { role, active: false, name },
  });

  return res.data;
};

export const useDeleteUser = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUser,

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_USERS_FETCH_KEY],
      });

      // TODO evitar que en cada actualización de usuario se haga una petición para obtener el usuario actual
      await queryClient.invalidateQueries({
        queryKey: [GET_CURRENT_USER_FETCH_KEY],
      });

      toast.add({
        content: t("users.user_deleted_successfully"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("users.user_deleted_failed"),
        status: "danger",
      });
    },
  });
};
