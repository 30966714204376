import { routes } from "@/router";
import { Box, Icon, Text, useTw } from "@suit-ui/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
  MdLockOutline,
  MdLogout,
  MdOutlineNotifications,
  MdOutlinePersonOutline,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import { signOut } from "../useCases/signOut";

export function Sidebar() {
  const tw = useTw();
  const { t } = useTranslation();
  const navLinkClassName = (isActive: boolean) =>
    tw(
      clsx(
        "flex items-center text-sm py-2 font-semibold hover:text-primary-600",
        isActive ? "text-primary-700" : "text-primary-900",
      ),
    );

  return (
    <Box className="w-[240px] bg-neutral-50 shadow-md p-8">
      <Text className="text-primary-900 font-semibold text-xl mb-12">
        {t("profile.title")}
      </Text>

      <Box className="flex flex-col gap-3">
        <NavLink
          to={routes.profile}
          className={({ isActive }) => navLinkClassName(isActive)}
        >
          <Icon as={MdOutlinePersonOutline} className="mr-2" size="5" />
          {t("profile.my_account")}
        </NavLink>
        <NavLink
          to={routes.changePassword}
          className={({ isActive }) => navLinkClassName(isActive)}
        >
          <Icon as={MdLockOutline} className="mr-2" size="5" />
          {t("profile.change_password")}
        </NavLink>

        <NavLink
          to={routes.notifications}
          className={({ isActive }) => navLinkClassName(isActive)}
        >
          <Icon as={MdOutlineNotifications} className="mr-2" size="5" />
          {t("profile.notifications")}
        </NavLink>

        <Box
          as="button"
          onClick={signOut}
          className="text-primary-900 flex items-center text-sm py-2 font-semibold hover:text-primary-600"
        >
          <Icon as={MdLogout} className="mr-2" size="5" />
          {t("profile.logout")}
        </Box>
      </Box>
    </Box>
  );
}
