import { PageContainer } from "@/ui/containers/PageContainer";
import { Box, Tabs } from "@suit-ui/react";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import { PageTitle } from "@/ui/texts/PageTitle";
import { UsersTable } from "../components/UsersTable";
import { UserAccess } from "@/features/session/components/UserAccess";
import { useTranslation } from "react-i18next";
import { PermissionsTable } from "../components/PermissionsTable";

export const UsersPage = () => {
  const { t } = useTranslation();

  return (
    <UserAccess permission={"users-read"}>
      <PageContainer className="flex flex-col">
        <Box className="flex justify-between mb-4.5">
          <BreadcrumbNav />
        </Box>

        <PageTitle>{t("users.users_and_permissions")}</PageTitle>

        <Tabs className="mt-4">
          <Tabs.List>
            <Tabs.Tab>{t("users.users")}</Tabs.Tab>
            <Tabs.Tab>
              <Box className="px-4">{t("users.user_profiles")}</Box>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panels className="bg-neutral-50">
            <Tabs.Panel>
              <UsersTable />
            </Tabs.Panel>
            <Tabs.Panel>
              <PermissionsTable />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </PageContainer>
    </UserAccess>
  );
};
