import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";

interface ConnectCaseParams {
  scraper_params: { [key: string]: Record<string, unknown> | string };
  credential_id: string | undefined;
}

const connectCase = async ({ scraper_params }: ConnectCaseParams) => {
  const response = await fetcher.get<{ key: string }>("/scrapers/scrape", {
    params: { ...scraper_params },
  });
  return response.data;
};

export function useConnectCase(
  scrapperParams: ConnectCaseParams["scraper_params"],
) {
  return useMutation({
    mutationFn: connectCase,
    mutationKey: ["connectCase", scrapperParams],
  });
}
