import { Movement } from "@/features/cases/types/Movement";
import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";

const getMovement = async (movementId: string): Promise<Movement> => {
  const response = await fetcher<Movement>(`/movements/${movementId}`);

  return response.data
}

export const useGetMovement = (movementId: string) => {
  return useQuery({
    queryKey: ["get-movement", movementId],
    queryFn: () => getMovement(movementId),
  });
}