import { Modal, FormControl, Input, Button, Checkbox } from "@suit-ui/react";
import { Case } from "../types/Case";
import { useGetCase } from "../useCases/useGetCase";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import CourtSelect from "./CourtSelect";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useUpdateCase } from "../useCases/useUpdateCase";
import { CustomFieldInput } from "@/features/customFields/components/CustomFieldInput";
import { useGetCustomFields } from "@/features/customFields/useCases/useGetCustomFields";
import ClientSelect from "./ClientSelect";
import { trackEvent } from "@/utils/analytics/trackEvent";

const schema = z
  .object({
    title: z.string({ description: "field_required" }),
    code: z.string({ description: "field_required" }),
    court_id: z.string({ description: "field_required" }),
    custom_data: z.any(),
    active: z.boolean(),
    client_id: z.any().optional(),
  })
  .required({ title: true, code: true, court_id: true });

type CaseFormData = z.infer<typeof schema>;

export interface EditCaseModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  caseId?: Case["id"] | null;
}

export const EditCaseModal: React.FC<EditCaseModalProps> = ({
  isOpen,
  onClose,
  caseId,
}) => {
  const { data } = useGetCase(caseId ?? "");
  const { updateCase, isPending: isUpdating } = useUpdateCase();
  const { customFields } = useGetCustomFields("Case");

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CaseFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  });

  const { t } = useTranslation();

  const readOnlyFields = data?.readonly_fields || [];

  const onSubmit = handleSubmit(async (form: CaseFormData) => {
    if (!data) return;

    await updateCase({
      ...data,
      ...form,
      ...{
        client_id: form.client_id || null,
      },
    });

    trackEvent("Case updated");
    onClose?.();
  });

  useEffect(() => {
    if (data) reset(data);

    // eslint-disable-next-line
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <Modal.Overlay />

      <Modal.Content>
        <form onSubmit={onSubmit}>
          <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
            {t("cases.edit_case")}
          </Modal.Header>

          <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
          <Modal.Body className="flex flex-col gap-3">
            <FormControl required className="flex-1">
              <FormControl.Label>{t("cases.title")}</FormControl.Label>

              <Input {...register("title")} disabled={!data?.active} />
            </FormControl>

            <FormControl required className="flex-1">
              <FormControl.Label>{t("cases.code")}</FormControl.Label>

              <Input
                {...register("code")}
                disabled={readOnlyFields.includes("code") || !data?.active}
              />
            </FormControl>

            <FormControl required>
              <FormControl.Label>{t("cases.court")}</FormControl.Label>

              <Controller
                control={control}
                name="court_id"
                render={({ field: { onChange, onBlur, value } }) => (
                  <CourtSelect
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={
                      readOnlyFields.includes("court_id") || !data?.active
                    }
                  />
                )}
              />

              {!!errors.court_id && (
                <FormControl.ErrorMessage>
                  {t(errors.court_id.message!)}
                </FormControl.ErrorMessage>
              )}
            </FormControl>

            <FormControl>
              <FormControl.Label>{t("cases.client")}</FormControl.Label>

              <Controller
                control={control}
                name="client_id"
                render={({ field: { onChange, onBlur, value } }) => (
                  <ClientSelect
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={
                      readOnlyFields.includes("client_id") || !data?.active
                    }
                  />
                )}
              />
            </FormControl>

            {customFields
              .filter((customField) => customField.display_order_form > 0)
              .map((customField) => (
                <FormControl
                  key={`case_custom_field_${customField.name}`}
                  disabled={
                    readOnlyFields.includes(
                      `custom_data.${customField.name}`,
                    ) || !data?.active
                  }
                >
                  <FormControl.Label>{customField.name}</FormControl.Label>

                  <Controller
                    control={control}
                    name={`custom_data.${customField.name}`}
                    render={({ field }) => (
                      <CustomFieldInput
                        {...field}
                        id={customField.id}
                        name={customField.name}
                        model="Case"
                      />
                    )}
                  />
                </FormControl>
              ))}

            <FormControl required>
              <Checkbox {...register("active")}>{t("cases.active")}</Checkbox>
            </FormControl>
          </Modal.Body>

          <Modal.Footer className="flex gap-3">
            <Button onClick={onClose} variant="text" disabled={isUpdating}>
              {t("ui.cancel")}
            </Button>
            <Button type="submit" loading={isUpdating}>
              {t("ui.save")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Content>
    </Modal>
  );
};
