import { Select } from "@suit-ui/react";
import { forwardRef, useMemo } from "react";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { User } from "@/features/users/types/User";
import { useGetRoles } from "@/features/users/useCases/useGetRoles";

interface UsersSelectProps {
  onChange?: (value: string[]) => void;
  value?: string[];
  disabled?: boolean;
  required?: boolean;
  users?: User[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UsersSelect = forwardRef<any, UsersSelectProps>(
  ({ onChange, value, disabled, required, users }, ref) => {
    const { users: defaultUsers = [], isLoading } = useGetUsers();
    const { roles } = useGetRoles();

    const userOptions = useMemo(() => {
      const systemRoleId = roles.find((role) => role.type === "system")?.id;

      if (users) {
        return users
          .filter((user) => user.active && user.role_id !== systemRoleId)
          .map(({ name, id }) => ({
            label: name,
            value: id,
          }));
      }

      return defaultUsers
        .filter((user) => user.active && user.role_id !== systemRoleId)
        .map(({ name, id }) => ({
          label: name,
          value: id,
        }));
    }, [users, defaultUsers, roles]);

    return (
      <Select
        ref={ref}
        menuPosition="fixed"
        valueAsObject={false}
        value={value}
        options={userOptions}
        onChange={onChange}
        isLoading={isLoading}
        disabled={disabled}
        required={required}
        isMulti
      />
    );
  },
);

export default UsersSelect;
