import { useState } from "react";

interface UsePaginationProps {
  page?: number;
  pageSize?: number;
}

export const usePagination = (props?: UsePaginationProps) => {
  const [page, setPage] = useState(props?.page ?? 1);
  const [pageSize, setPageSize] = useState(props?.pageSize ?? 20);

  return {
    page,
    pageSize,
    goToFirstPage: () => setPage(1),
    nextPage: () => setPage((prev) => prev + 1),
    prevPage: () => setPage((prev) => Math.max(prev - 1, 0)),
    onChangePageRows: (newPageSize: number) => {
      setPageSize(newPageSize);
      setPage(1);
    },
  };
};
