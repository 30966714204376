import { Box, Icon, Table, Text } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { MdCheckCircleOutline } from "react-icons/md";

const permissions = [
  {
    name: "Administrar usuarios",
    permissions: [
      {
        description: "Ver, crear, editar y eliminar usuarios",
        admin: true,
        lawyer: false,
        assistant: false,
        guest: false,
      },
    ],
  },
  {
    name: "Causas",
    permissions: [
      {
        description: "Ver todas las causas",
        admin: true,
        lawyer: false,
        assistant: false,
        guest: false,
      },
      {
        description: "Ver las causas en las que está involucrado",
        admin: true,
        lawyer: true,
        assistant: true,
        guest: true,
      },
      {
        description: "Crear causas",
        admin: true,
        lawyer: true,
        assistant: true,
        guest: false,
      },
      {
        description: "Editar causas",
        admin: true,
        lawyer: true,
        assistant: false,
        guest: false,
      },
      {
        description: "Eliminar causas",
        admin: true,
        lawyer: true,
        assistant: false,
        guest: false,
      },
      {
        description: "Ver, crear, editar y eliminar movimientos",
        admin: true,
        lawyer: true,
        assistant: true,
        guest: false,
      },
      {
        description: "Ver, crear, editar y eliminar comentarios",
        admin: true,
        lawyer: true,
        assistant: true,
        guest: false,
      },
      {
        description: "Ver, crear, editar y eliminar archivos adjuntos",
        admin: true,
        lawyer: true,
        assistant: true,
        guest: false,
      },
    ],
  },
  {
    name: "Credenciales",
    permissions: [
      {
        description: "Ver, crear, editar y eliminar credenciales",
        admin: true,
        lawyer: true,
        assistant: false,
        guest: false,
      },
    ],
  },
  {
    name: "Eventos",
    permissions: [
      {
        description: "Ver, crear, editar y eliminar eventos",
        admin: true,
        lawyer: true,
        assistant: true,
        guest: false,
      },
    ],
  },
];

export function PermissionsTable() {
  const { t } = useTranslation();

  return (
    <Table.Container className="rounded-md" variant="elevated">
      <Table>
        <Table.Head className="bg-primary-800 text-neutral-50">
          <Table.Row className="text-neutral-50">
            <Table.Header>
              <Box className="pl-4">{t("users.permissions.header")}</Box>
            </Table.Header>
            <Table.Header className="[&>span]:justify-center">
              {t("users.permissions.admin")}
            </Table.Header>
            <Table.Header className="[&>span]:justify-center">
              {t("users.permissions.lawyer")}
            </Table.Header>
            <Table.Header className="[&>span]:justify-center">
              {t("users.permissions.assistant")}
            </Table.Header>
            <Table.Header className="pr-4 [&>span]:justify-center">
              {t("users.permissions.guest")}
            </Table.Header>
          </Table.Row>
        </Table.Head>

        {permissions.map(({ name, permissions }) => (
          <Table.Body
            className="border-b border-neutral-200 last:border-b-0"
            key={`PermissionBody-${name}`}
          >
            <Table.Row className="border-b-0">
              <Table.Cell className="pb-0 pt-4 [&>span]:min-h-0">
                <Box className="flex flex-col pl-4">
                  <Text className="text-neutral-600 font-semibold">{name}</Text>
                </Box>
              </Table.Cell>
              <Table.Cell className="pb-0 pt-4 [&>span]:min-h-0" />
              <Table.Cell className="pb-0 pt-4 [&>span]:min-h-0" />
              <Table.Cell className="pb-0 pt-4 [&>span]:min-h-0" />
              <Table.Cell className="pb-0 pt-4 [&>span]:min-h-0" />
            </Table.Row>

            {permissions.map(
              ({ description, admin, lawyer, assistant, guest }) => (
                <Table.Row key={`PermissionRow-${description}`}>
                  <Table.Cell className="[&>span]:min-h-10">
                    <Box className="flex flex-col pl-4">
                      <Text className="text-neutral-600 text-sm">
                        {description}
                      </Text>
                    </Box>
                  </Table.Cell>
                  <Table.Cell className="[&>span]:(justify-center min-h-10)">
                    {admin && (
                      <Icon
                        className="text-neutral-600"
                        as={MdCheckCircleOutline}
                        color={"green"}
                        size="5"
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell className="[&>span]:(justify-center min-h-10)">
                    {lawyer && (
                      <Icon
                        className="text-neutral-600"
                        as={MdCheckCircleOutline}
                        color={"green"}
                        size="5"
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell className="[&>span]:(justify-center min-h-10)">
                    {assistant && (
                      <Icon
                        className="text-neutral-600"
                        as={MdCheckCircleOutline}
                        color={"green"}
                        size="5"
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell className="pr-4 [&>span]:(justify-center min-h-10)">
                    {guest && (
                      <Icon
                        className="text-neutral-600"
                        as={MdCheckCircleOutline}
                        color={"green"}
                        size="5"
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              ),
            )}
          </Table.Body>
        ))}
      </Table>
    </Table.Container>
  );
}
