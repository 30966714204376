import { fetcher } from "@/utils/fetchers/fetcher";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  Event,
  EventQueryParams,
  EventTypes,
  GetEventQueryParams,
  GetEventUrl,
} from "../types/Event";
import { PaginatedResource } from "@/utils/pagination/PaginatedResource";
import { usePagination } from "@/utils/pagination/usePagination";

export const GET_EVENTS_FETCH_KEY = "get_events";

const getEvents = async (
  type: EventTypes,
  page: number,
  pageSize: number,
  id?: string,
  eventQueryParams?: EventQueryParams,
) => {
  const response = await fetcher<PaginatedResource<Event[]>>(
    GetEventUrl(type, id),
    {
      params: {
        ...(eventQueryParams && GetEventQueryParams(eventQueryParams)),
        "pagination[page]": page,
        "pagination[pageSize]": pageSize,
      },
    },
  );

  return response.data;
};

export const useGetEvents = (
  type: EventTypes,
  id?: string,
  eventQueryParams?: EventQueryParams,
) => {
  const { page, pageSize, nextPage, prevPage, onChangePageRows } =
    usePagination();

  const query = useQuery({
    queryKey: [
      GET_EVENTS_FETCH_KEY,
      type,
      id,
      page,
      pageSize,
      eventQueryParams,
    ],
    queryFn: () => getEvents(type, page, pageSize, id, eventQueryParams),
    placeholderData: keepPreviousData,
  });

  return {
    ...query,
    events: query.data?.data || [],
    pagination: query.data?.meta.pagination,
    nextPage,
    prevPage,
    onChangePageRows,
  };
};
