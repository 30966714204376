import { Button, useDisclosure } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { MdOutlineAdd } from "react-icons/md";
import { EditableTaskModal } from "./EditableTaskModal";
import { TaskTypes } from "../useCases/useGetTasks";
import { CreateTaskData, useCreateTask } from "../useCases/useCreateTask";
import { trackEvent } from "@/utils/analytics/trackEvent";

interface AddTaskProps {
  type: TaskTypes;
  resourceId: string;
  availableUsers?: string[];
}

export function AddTask({ type, resourceId, availableUsers }: AddTaskProps) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync, isPending } = useCreateTask({ type, resourceId });

  const onSubmit = async (data: CreateTaskData) => {
    await mutateAsync(data);
    trackEvent("Task created");
  };

  return (
    <>
      <Button size="sm" leftIcon={<MdOutlineAdd />} onClick={onOpen}>
        {t("tasks.add_task")}
      </Button>

      <EditableTaskModal
        title={t("tasks.add_task")}
        isOpen={isOpen}
        onClose={onClose}
        availableUsers={availableUsers}
        submitButtonText={t("tasks.add")}
        onSubmit={onSubmit}
        isPending={isPending}
      />
    </>
  );
}
