import { useQuery } from "@tanstack/react-query";
import { Court } from "../types/Court";
import { fetcher } from "@/utils/fetchers/fetcher";
import { useMemo } from "react";

const GET_COURTS_FETCH_KEY = "get-courts";

const getCourts = async () => {
  const response = await fetcher<Court[]>("/courts");
  return response.data;
};

export const useGetCourts = () => {
  const query = useQuery({
    queryKey: [GET_COURTS_FETCH_KEY],
    queryFn: getCourts,
    refetchOnMount: false,
  });

  const courtMap = useMemo(() => {
    const courts = query.data || [];

    return new Map<string, Court>(courts.map((court) => [court.id, court]));
  }, [query.data]);

  return {
    ...query,
    courtMap,
  };
};
