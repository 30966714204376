import { MIXPANEL_TOKEN, NODE_ENV } from "@/configs";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";

mixpanel.init(MIXPANEL_TOKEN || "...", {
  debug: NODE_ENV === "development" ? true : false,
  track_pageview: "full-url",
  persistence: "localStorage",
});

Sentry.init({
  dsn: "https://e83835e118b031ef688452ffb202f2ad@o212660.ingest.us.sentry.io/4507306464968704",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: NODE_ENV,
});
