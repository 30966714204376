import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { Case } from "../types/Case";
import { useTranslation } from "react-i18next";
import { GET_CASES_FETCH_KEY } from "./useGetCases";
import { CaseParty } from "../types/CaseParty";

const createCaseParty = async ({
  caseId,
  data,
}: {
  caseId: Case["id"];
  data: Pick<CaseParty, "name" | "identifier" | "party_type" | "comments">;
}) => {
  const reponse = await fetcher.post<Case>(`/cases/${caseId}/case_parties`, {
    case_party: data,
  });
  return reponse.data;
};

export const useCreateCaseParty = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: createCaseParty,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_CASES_FETCH_KEY],
      });

      toast.add({
        content: t("cases.case_updated"),
        status: "success",
      });
    },

    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return { createCaseParty: mutateAsync, isPending };
};
