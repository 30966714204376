import { Button, FormControl, Input, Modal } from "@suit-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useRef } from "react";

const schema = z.object({
  name: z.string().min(1),
});

type ClientFormData = z.infer<typeof schema>;

export interface ClientModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: (data: ClientFormData) => void;
  defaultValues?: ClientFormData | null;
  mode?: "create" | "update";
  isLoading?: boolean;
}

export const ClientModal = ({
  isOpen,
  onClose,
  onSubmit = () => {},
  defaultValues,
  mode,
  isLoading,
}: ClientModalProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      ...defaultValues,
    },
  });
  const prevDefaultValues = useRef<ClientFormData | null>(null);

  if (defaultValues && prevDefaultValues.current !== defaultValues) {
    prevDefaultValues.current = defaultValues;
    reset(defaultValues);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      closeOnOverlayClick={false}
      onAnimationComplete={() => reset()}
    >
      <Modal.Overlay />

      <Modal.Content as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
          {mode === "update" ? t("ui.edit") : t("ui.create")}{" "}
          {t("clients.client")?.toLocaleLowerCase()}
        </Modal.Header>

        <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
        <Modal.Body className="flex flex-col gap-2">
          <FormControl required isInvalid={!!errors.name}>
            <FormControl.Label>{t("clients.name")}</FormControl.Label>
            <Input
              placeholder={t("clients.name_placeholder")}
              {...register("name")}
            />
            <FormControl.ErrorMessage>
              {t("form_errors.field_required")}
            </FormControl.ErrorMessage>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="mr-2"
            variant="text"
            color="neutral"
            onClick={onClose}
            disabled={isLoading}
          >
            {t("events.cancel")}
          </Button>
          <Button type="submit" loading={isLoading}>
            {mode === "update" ? t("ui.save") : t("ui.add")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
