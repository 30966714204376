import { Card, Text, Banner } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { useGetSettings } from "../useCases/useGetSettings";
import { ProfileLayout } from "../components/ProfileLayout";
import { format, parse } from "date-fns";

import { NotificationsTable } from "../components/NotificationsTable";

export const NotificationsPage = () => {
  const { t } = useTranslation();
  const { settings } = useGetSettings();

  // add "am" to scrape time, to not confuse users
  let scrapeTime = "";
  if (settings?.merged.times_case_update) {
    scrapeTime = settings.merged.times_case_update;
    const amPm = format(parse(settings.merged.times_case_update, "HH:mm", new Date()), "a");
    if (amPm === "AM") {
      scrapeTime += " am";
    }
  }

  return (
    <ProfileLayout>
      <Card className="flex flex-1 m-6 p-4">
        <Card.Body>
          <Text className="text-primary-900 font-semibold text-2xl mb-8 ml-4">
            {t("notifications.title")}
          </Text>

          {settings?.merged.times_case_update && (
            <Banner size="sm" status="info">
              {t("notifications.banner_scrape_time", { scrapeTime })}
            </Banner>
          )}

          <NotificationsTable settings={settings} />
        </Card.Body>
      </Card>
    </ProfileLayout>
  );
};
