import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET_CASES_FETCH_KEY } from "./useGetCases";
import { Case } from "../types/Case";

export const updateCase = async (data: { id: Case["id"] } & Partial<Case>) => {
  const res = await fetcher.put<Case>(`/cases/${data.id}`, { case: data });
  return res.data;
};

export const useUpdateCase = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: updateCase,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: [GET_CASES_FETCH_KEY, data.id],
      });

      toast.add({
        content: "Caso actualizado correctamente",
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: "Error al actualizar los datos del caso",
        status: "danger",
      });
    },
  });

  return {
    updateCase: mutateAsync,
    isPending,
  };
};

