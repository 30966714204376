import { CreateAttachment } from "@/features/movements/types/Attachment";
import { byteConverter } from "@/utils/files/byConverter";
import {
  Modal,
  FormControl,
  Button,
  Upload,
  UploadProgress,
} from "@suit-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface CreateAttachmentModalProps {
  onSubmit?: (item: CreateAttachment, movementId: string) => void;
  isOpen?: boolean;
  onClose?: () => void;
  movementId?: string;
}

const defaultState: CreateAttachment = {
  file: null,
};

export const CreateAttachmentModal: React.FC<CreateAttachmentModalProps> = ({
  onSubmit,
  isOpen,
  onClose,
  movementId,
}) => {
  const [attachment, setAttachment] = useState<CreateAttachment>(defaultState);
  const { t } = useTranslation();

  const file = useMemo(() => {
    if (!attachment?.file) return null;

    return {
      size: byteConverter(attachment?.file?.size, 2),
      name: attachment?.file?.name,
    };
  }, [attachment?.file]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onAnimationComplete={({ isOpen: isModalOpen }) =>
        !isModalOpen && setAttachment(defaultState)
      }
    >
      <Modal.Overlay />

      <Modal.Content
        as="form"
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          movementId && onSubmit?.(attachment, movementId);
        }}
      >
        <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
          {t("attachments.create_modal_header")}
        </Modal.Header>
        <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
        <Modal.Body className="flex flex-col gap-3">
          <FormControl required>
            <FormControl.Label className="mb-4">
              {t("attachments.file_header")}
            </FormControl.Label>

            {file ? (
              <UploadProgress state="done">
                <UploadProgress.Image />
                <UploadProgress.Label>{file.name}</UploadProgress.Label>
                <UploadProgress.Value />
                <UploadProgress.DoneMsg>{file.size}</UploadProgress.DoneMsg>
                <UploadProgress.CancelBtn
                  onClick={() => setAttachment(defaultState)}
                />
              </UploadProgress>
            ) : (
              <Upload
                variant="vertical"
                onChange={(files) => {
                  setAttachment({
                    ...attachment,
                    file: files[0],
                  });
                }}
              >
                <Upload.Image />
                <Upload.Description>
                  <Upload.Label>
                    Arrasta aquí o <Upload.Highlight>busca</Upload.Highlight>{" "}
                    tus archivos para cargarlos
                  </Upload.Label>
                </Upload.Description>
              </Upload>
            )}
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={onClose}
            variant="text"
            className="mr-2"
            color="neutral"
          >
            {t("ui.cancel")}
          </Button>
          <Button type="submit">{t("attachments.attach")}</Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
