import { UploadProgress } from "@suit-ui/react";
import { JSX } from "react";

export const getAttachmentIcon = (fileName: string) => {
  const extension = fileName.split(".").pop();
  const icon: { [key: string]: JSX.Element } = {
    pdf: <UploadProgress.PdfIcon />,
    doc: <UploadProgress.DocIcon />,
    dot: <UploadProgress.DocIcon />,
    docx: <UploadProgress.DocIcon />,
    dotx: <UploadProgress.DocIcon />,
    docm: <UploadProgress.DocIcon />,
    dotm: <UploadProgress.DocIcon />,
    xls: <UploadProgress.XlsIcon />,
    xlt: <UploadProgress.XlsIcon />,
    xla: <UploadProgress.XlsIcon />,
    xlsx: <UploadProgress.XlsIcon />,
    xltx: <UploadProgress.XlsIcon />,
    xlsm: <UploadProgress.XlsIcon />,
    xltm: <UploadProgress.XlsIcon />,
    xlam: <UploadProgress.XlsIcon />,
    xlsb: <UploadProgress.XlsIcon />,
    jpg: <UploadProgress.JpgIcon />,
    jpeg: <UploadProgress.JpgIcon />,
  };

  return icon[extension as string] || <UploadProgress.FileIcon />;
};
