import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/utils/fetchers/fetcher";
import { SearchParams } from "../types/SearchParams";

const getSearch = async ({
  finder,
  search_params,
  credential_id,
  page,
}: SearchParams) => {
  const response = await fetcher.get<{ key: string }>("/scrapers/search", {
    params: {
      ...search_params,
      finder,
      credential_id,
      page,
    },
  });
  return response.data;
};

export function useSearch() {
  return useMutation({
    mutationFn: getSearch,
  });
}
