import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { Avatar, Box, Text } from "@suit-ui/react";
import clsx from "clsx";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Mention, MentionsInput } from "react-mentions";
import { TaskComment } from "../types/TaskComment";

interface CommentProps {
  comment: TaskComment;
  deleteButton?: React.ReactNode;
  className?: string;
}

export const Comment: React.FC<CommentProps> = ({
  comment,
  className,
  deleteButton,
}) => {
  const { t } = useTranslation();
  const users = useGetUsers();
  const userName = users.findById(comment.created_by_id!)?.name;

  return (
    <Box
      className={clsx(
        "flex p-3 gap-3 items-start relative bg-neutral-100 group",
        className,
      )}
    >
      <Avatar
        size="xs"
        variant="dark"
        className="grow-0 shrink-0 mt-1"
        name={userName}
      />

      <Box className="grow shrink">
        <Box className="flex gap-1 items-center">
          <Box className="text-md font-semibold leading-6">{userName}</Box>
          <Box as="span" className="leading-6">
            -
          </Box>
          <Text
            as="span"
            size="xs"
            className="font-semibold leading-6 pt-[1px]"
          >
            {format(comment.created_at!, t("date_formats.date"))}
          </Text>
        </Box>

        <Box
          as={MentionsInput}
          value={comment?.content}
          className="[&_textarea]:(outline-transparent outline-none)"
        >
          <Box
            as={Mention}
            markup="@[__display__](user:__id__)"
            trigger="@"
            data={users.users}
            className={"text-primary-600 relative z-[1]"}
            style={{
              textShadow:
                "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
            }}
          />
        </Box>
      </Box>

      {deleteButton}
    </Box>
  );
};
