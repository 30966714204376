import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { getTenantName } from "./getTenantName";

type FeatureFlags =
  | "enable_enhanced_custom_data"
  | "enable_inactive_user_management"
  | "show_inactive_users"
  | "enable_admin_change_password";

interface Metrics {
  cases_created: number;
  cases_connected: number;
  users_active: number;
}

interface Plan {
  cases_created: number;
}

interface TenantData {
  id: string;
  active: boolean;
  country_code: string;
  created_at: null;
  features: Record<FeatureFlags, boolean>;
  locale: string;
  metrics: Metrics;
  name: string;
  plan: Plan;
  subdomain: string;
  updated_at: string;
}

export const GET_TENANT_DATA_QUERY_KEY = "get_current_user";
const tenant = getTenantName();

const getTenantData = async () => {
  const response = await fetcher.get<TenantData>(`/tenants/${tenant}`);
  return response.data;
};

interface UseTenantDataProps {
  refetchOnMount?: boolean;
}

export const useTenantData = ({
  refetchOnMount = false,
}: UseTenantDataProps = {}) => {
  const query = useQuery({
    queryKey: [GET_TENANT_DATA_QUERY_KEY, tenant],
    queryFn: getTenantData,
    refetchOnMount,
  });

  const getFFValue = (flag: FeatureFlags, defaultValue: boolean = false) =>
    query?.data?.features?.[flag] ?? defaultValue;

  return { ...query, getFFValue };
};
