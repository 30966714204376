import { fetcher } from "@/utils/fetchers/fetcher";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "@suit-ui/react";
import { GET_CUSTOM_FIELD_VALUES_FETCH_KEY } from "./useGetCustomFieldValues";
import { CustomFieldValueType } from "../types/CustomField";
import { CustomFieldValue } from "../types/CustomFieldValue";

const createCustomFieldValue = async (
  customFieldId: string,
  value: Pick<CustomFieldValue, "name">,
) => {
  const response = await fetcher.post<CustomFieldValueType>(
    `/custom_fields/${customFieldId}/custom_field_values`,
    value,
  );
  return response.data;
};

export const useCreateCustomFieldValue = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toast = useToast();

  const { mutateAsync, isPending, isError } = useMutation({
    mutationFn: async (data: {
      customFieldId: string;
      value: Pick<CustomFieldValue, "name">;
    }) => {
      await createCustomFieldValue(data.customFieldId, data.value);
      return data;
    },
    onSuccess: ({ customFieldId }) => {
      return queryClient.invalidateQueries({
        queryKey: [GET_CUSTOM_FIELD_VALUES_FETCH_KEY, customFieldId],
      });
    },
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return { createCustomFieldValue: mutateAsync, isPending, isError };
};
