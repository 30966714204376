import { Box, IconButton, UploadProgress } from "@suit-ui/react";
import { MdDeleteOutline } from "react-icons/md";
import { TaskAttachment } from "../types/TaskAttachment";
import { getAttachmentIcon } from "../utils/getAttachmentIcon";
import { fetcherCookie } from "@/utils/fetchers/fetcher";
import { STORAGE_SESSION_NAME } from "@/features/session/conts";
import { byteConverter } from "@/utils/files/byConverter";
import { useDeleteTaskAttachment } from "../useCases/useDeleteTaskAttachment";
import { useTranslation } from "react-i18next";
import Confirm from "@/ui/Confirm";
import { UserAccess } from "@/features/session/components/UserAccess";

interface FileAttachmentProps {
  taskId: string;
  file: TaskAttachment;
}

const downloadFromS3 = async (attachmentId: string) => {
  const token = localStorage.getItem(STORAGE_SESSION_NAME);

  await fetcherCookie.get("/login", {
    params: {
      token,
    },
    withCredentials: true,
  });

  const url = `${fetcherCookie.getUri()}/attachments/${attachmentId}/download`;

  window.open(url, "__blank", "");
};

export function FileAttachment({ taskId, file }: FileAttachmentProps) {
  const { mutate, isPending } = useDeleteTaskAttachment(taskId);
  const { t } = useTranslation();

  return (
    <UploadProgress
      state="done"
      className={
        isPending
          ? "opacity-50 pointer-events-none"
          : "cursor-pointer hover:(border-neutral-500)"
      }
      onClick={() => downloadFromS3(file.id)}
      customContent={
        <UserAccess permission="attachments-destroy">
          <Confirm
            title={`${t("ui.delete")} - ${file.filename}`}
            onConfirm={() => {
              if (isPending) return;

              mutate({
                attachmentId: file.id,
              });
            }}
            onConfirmText={t("ui.delete")}
            button={
              <IconButton
                icon={<MdDeleteOutline />}
                size="sm"
                variant="text"
                color="neutral"
              />
            }
            body={t("ui.confirm_delete_description")}
          />
        </UserAccess>
      }
    >
      <UploadProgress.Image>
        {getAttachmentIcon(file.filename)}
      </UploadProgress.Image>
      <UploadProgress.Label className="grid">
        <Box className="text-ellipsis whitespace-nowrap overflow-hidden text-sm">
          {file.filename}
        </Box>
      </UploadProgress.Label>
      <UploadProgress.DoneMsg>
        {byteConverter(file?.size_bytes, 2)}
      </UploadProgress.DoneMsg>
    </UploadProgress>
  );
}
