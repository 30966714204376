import { format, parse } from "date-fns";

export function parseOfficialDate(
  date: string,
  dateFormat: string,
  locale?: Locale,
) {
  if (!date) return "";

  const inputDate = parse(
    date,
    "yyyy-MM-dd",
    new Date().setUTCHours(0, 0, 0, 0),
  );

  return format(inputDate, dateFormat, {
    locale,
  });
}
