import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { EventTypes, GetEventUrl } from "../types/Event";
import { GET_EVENTS_FETCH_KEY } from "./useGetEvents";

const deleteEvent = async (url: string, id: string) => {
  const response = await fetcher.delete(`${url}/${id}`);
  return response.data;
};

export const useDeleteEvent = (
  type: EventTypes,
  resourceId: string,
  id: string,
) => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteEvent(GetEventUrl(type, resourceId), id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_EVENTS_FETCH_KEY] });

      toast.add({
        content: t("events.delete_success"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("events.errors.delete_failed"),
        status: "danger",
      });
    },
  });
};
