import { Box, Placeholder, Table } from "@suit-ui/react";

export interface TableBodyProps {
  children?: React.ReactNode | React.ReactNode[];
  isEmpty?: boolean;
  title?: string;
  description?: string;
  className?: string;
}

export const TableBody: React.FC<TableBodyProps> = ({
  children,
  isEmpty,
  title,
  description,
  className,
}) => {
  if (!isEmpty) {
    return <Table.Body className={className}>{children}</Table.Body>;
  }

  return (
    <Table.Body className={className}>
      <Table.Row>
        <Box as="td" colSpan={99}>
          <Box className="py-6 grow shrink flex items-center justify-center">
            <Placeholder>
              <Placeholder.Image>
                <Placeholder.EmptyFileIcon />
              </Placeholder.Image>
              <Placeholder.Content>
                <Placeholder.Title className="font-regular">
                  {title}
                </Placeholder.Title>
                {!!description && (
                  <Placeholder.Description>
                    {description}
                  </Placeholder.Description>
                )}
              </Placeholder.Content>
            </Placeholder>
          </Box>
        </Box>
      </Table.Row>
    </Table.Body>
  );
};
