import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { Client } from "../types/Client";
import { useMemo } from "react";

export const GET_CLIENTS_FETCH_KEY = "get-clients";

const getClients = async (): Promise<Client[]> => {
  const response = await fetcher(`/clients`);
  return response.data;
};

export const useGetClients = () => {
  const query = useQuery({
    queryKey: [GET_CLIENTS_FETCH_KEY],
    queryFn: () => getClients(),
  });

  const clientMap = useMemo(() => {
    const clients = query.data || [];

    return new Map<string, Client>(
      clients.map((client) => [client.id, client]),
    );
  }, [query.data]);

  return {
    ...query,
    clients: query.data ?? [],
    isPending: query.isPending,
    clientMap
  };
};
