import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { CustomField } from "../types/CustomField";

export const GET_CUSTOM_FIELD_FETCH_KEY = "get-custom-fields";

export type CustomFieldModel = "Case" | "Movement" | "CaseParty";

const getCustomFields = async (model: CustomFieldModel) => {
  const response = await fetcher.get<CustomField[]>("/custom_fields", {
    params: { model },
  });

  return response.data;
};

interface useGetCustomFieldsOptionsProps {
  refetchOnMount?: boolean;
}

export const useGetCustomFields = (
  model: CustomFieldModel,
  { refetchOnMount = true }: useGetCustomFieldsOptionsProps = {},
) => {
  const query = useQuery({
    queryKey: [GET_CUSTOM_FIELD_FETCH_KEY, model],
    queryFn: () => getCustomFields(model),
    refetchOnMount,
  });

  return {
    customFields: query.data ?? [],
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    refetch: query.refetch,
    isPending: query.isPending,
    isSuccess: query.isSuccess,
  };
};
