import { Tabs } from "@suit-ui/react";
import { ReactElement, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

interface TabsWrapperProps {
  tabs: {
    tabContent: ReactElement | undefined;
    tabParam: string;
    panelContent: ReactElement | undefined;
  }[];
  tabsPanelsContainerClassname?: string;
  defaultTab?: string;
}

const UrlQueryTabs: React.FC<TabsWrapperProps> = ({
  tabs,
  tabsPanelsContainerClassname = "",
  defaultTab,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultIndex = useMemo(() => {
    const tabParamUrl = searchParams.get("tab");
    const idx = tabs.findIndex(({ tabParam }) => tabParam === tabParamUrl);

    if (idx === -1) {
      return tabs.findIndex(({ tabParam }) => tabParam === defaultTab);
    } else {
      return idx;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  useEffect(() => {
    if (!searchParams.get("tab") && defaultTab) {
      setSearchParams({ tab: defaultTab });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs
      onChange={(idx) => {
        const tabParam = tabs[idx].tabParam;
        setSearchParams({ tab: tabParam });
      }}
      defaultIndex={defaultIndex}
    >
      <Tabs.List>
        {tabs.map(
          ({ tabContent, tabParam }) =>
            tabContent && (
              <Tabs.Tab key={`Tab-${tabParam}`}>{tabContent}</Tabs.Tab>
            ),
        )}
      </Tabs.List>

      <Tabs.Panels className={tabsPanelsContainerClassname}>
        {tabs.map(({ panelContent, tabParam }) => (
          <Tabs.Panel key={`TabPanel-${tabParam}`}>{panelContent}</Tabs.Panel>
        ))}
      </Tabs.Panels>
    </Tabs>
  );
};

export { UrlQueryTabs };
