import { PageContainer } from "@/ui/containers/PageContainer";
import { Box } from "@suit-ui/react";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import { PageTitle } from "@/ui/texts/PageTitle";
import { UserAccess } from "@/features/session/components/UserAccess";
import { useTranslation } from "react-i18next";
import { ClientsTable } from "../components/ClientsTable";

export const ClientsPage = () => {
  const { t } = useTranslation();

  return (
    <UserAccess permission={"users-read"}>
      <PageContainer className="flex flex-col">
        <Box className="flex justify-between mb-4.5">
          <BreadcrumbNav />
        </Box>

        <PageTitle>{t("clients.clients")}</PageTitle>

        <ClientsTable />
      </PageContainer>
    </UserAccess>
  );
};
