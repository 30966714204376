import { Button, FormControl, Input, Modal } from "@suit-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useRef } from "react";

const schema = z.object({ name: z.string() }).required({ name: true });

type CaseFormData = z.infer<typeof schema>;

export interface CustomFieldValueModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  customFieldName: string;
  onSubmit?: (data: CaseFormData) => void;
  defaultValues?: CaseFormData | null;
  mode?: "create" | "update";
  isLoading?: boolean;
}

export const CustomFieldValueModal = ({
  isOpen,
  onClose,
  customFieldName = "",
  onSubmit = () => {},
  defaultValues,
  mode,
  isLoading,
}: CustomFieldValueModalProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      ...defaultValues,
    },
  });
  const prevDefaultValues = useRef<CaseFormData | null>(null);

  if (defaultValues && prevDefaultValues.current !== defaultValues) {
    prevDefaultValues.current = defaultValues;
    reset(defaultValues);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      closeOnOverlayClick={false}
      onAnimationComplete={() => reset()}
    >
      <Modal.Overlay />

      <Modal.Content as="form" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
          {mode === "update"
            ? t("ui.edit") + " " + customFieldName
            : t("ui.create") + " " + customFieldName}
        </Modal.Header>

        <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
        <Modal.Body>
          <FormControl required isInvalid={!!errors.name}>
            <FormControl.Label>
              {t("custom_fields.values.name")}
            </FormControl.Label>
            <Input {...register("name")} />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="mr-2"
            variant="text"
            color="neutral"
            onClick={onClose}
            disabled={isLoading}
          >
            {t("events.cancel")}
          </Button>
          <Button type="submit" loading={isLoading}>
            {mode === "update" ? t("ui.save") : t("ui.create")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
