import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getTaskApiUrl } from "../utils/getTaskApiUrl";
import { GET_TASKS_QUERY_KEY, TaskTypes } from "./useGetTasks";
import { Task } from "../types/Task";

export type CreateTaskData = Omit<
  Task,
  "_id" | "case" | "resource_id" | "resource_type"
>;

interface CreateTaskProps {
  type: TaskTypes;
  resourceId: string;
  data: CreateTaskData;
}

const createTask = async ({ type, resourceId, data }: CreateTaskProps) => {
  const url = getTaskApiUrl(type, resourceId);

  const response = await fetcher.post<Task>(url, {
    ...data,
    resource_id: resourceId,
    resource_type: type,
  });

  return response.data;
};

interface UseCreateTaskProps {
  type: TaskTypes;
  resourceId: string;
}

export function useCreateTask({ type, resourceId }: UseCreateTaskProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateTaskData) =>
      createTask({ type, resourceId, data }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_TASKS_QUERY_KEY] });

      toast.add({
        content: t("tasks.create_success"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("tasks.errors.create_failed"),
        status: "danger",
      });
    },
  });
}
