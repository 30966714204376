import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Text,
  FormControl,
  Input,
} from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateUserPassword } from "../useCases/useUpdateUserPassword";
import { useCurrentUser } from "../useCases/useCurrentUser";
import { ProfileLayout } from "../components/ProfileLayout";
import { MdCancel, MdCheckCircle, MdOutlineVisibility } from "react-icons/md";
import React, { useState } from "react";
import clsx from "clsx";
import { signOut } from "../useCases/signOut";
import { trackEvent } from "@/utils/analytics/trackEvent";

const defaultCase = {
  confirmPassword: "",
  newPassword: "",
};

const regexValidations = {
  needs_number: /\d/,
  needs_lowercase: /[a-z\u00DF-\u00F6\u00F8-\u00FF]/, // any Unicode lowercase letter
  needs_uppercase: /[A-Z\u00C0-\u00D6\u00D8-\u00DE]/, // any Unicode uppercase letter
  needs_special_character:
    /[\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\u00A1-\u00AC\u00AE-\u00FF]/, // any non-alphanumeric character
};

const passwordMinLength = 8;

const passwordSchema = z
  .string()
  .min(passwordMinLength, { message: "min_length" })
  .regex(regexValidations.needs_number, { message: "needs_number" })
  .regex(regexValidations.needs_lowercase, { message: "needs_lowercase" })
  .regex(regexValidations.needs_uppercase, { message: "needs_uppercase" })
  .regex(regexValidations.needs_special_character, {
    message: "needs_special_character",
  });

const schema = z
  .object({
    newPassword: passwordSchema,
    confirmPassword: z.string().min(1, { message: "field_required" }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "passwords_dont_match",
    path: ["confirmPassword"],
  });

type CaseFormData = z.infer<typeof schema>;

interface CheckValidationsProps {
  isValid?: boolean;
  isInvalid?: boolean;
  children?: React.ReactNode;
}

const CheckValidation: React.FC<CheckValidationsProps> = ({
  isInvalid,
  isValid,
  children,
}) => {
  return (
    <Box
      className="text-neutral-900 flex gap-1 items-center text-sm"
      apply={clsx(
        isValid && "text-success-600",
        isInvalid && "text-danger-600",
      )}
    >
      <Icon as={isInvalid ? MdCancel : MdCheckCircle} size="3.5" />
      {children}
    </Box>
  );
};

export const ChangePasswordPage = () => {
  const { data: currentUser } = useCurrentUser();
  const { isPending, updateUserPasswordAsync } = useUpdateUserPassword();
  const [toggleShowPasswords, setToggleShowPasswords] = useState<
    Record<keyof CaseFormData, boolean>
  >({
    confirmPassword: false,
    newPassword: false,
  });

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<CaseFormData>({
    mode: "onBlur",
    resolver: zodResolver(schema),
  });

  const getType = (name: keyof CaseFormData) =>
    toggleShowPasswords[name] ? "text" : "password";

  const toggleType = (name: keyof CaseFormData) => () => {
    setToggleShowPasswords((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const watchNewPassword = watch("newPassword");

  const isValidPassword = (
    validation: keyof typeof regexValidations | "min_length",
  ) => {
    if (validation === "min_length")
      return z.string().min(passwordMinLength).safeParse(watchNewPassword)
        .success;

    return z
      .string()
      .regex(regexValidations[validation])
      .safeParse(watchNewPassword).success;
  };

  const handleChangePassword = async (form: CaseFormData) => {
    if (!currentUser) return;

    await updateUserPasswordAsync({
      userId: currentUser.id,
      password: form.newPassword,
    });
    trackEvent("Account password changed");

    reset(defaultCase);

    signOut();
  };

  return (
    <ProfileLayout>
      <Card className="min-w-full md:min-w-[600px] py-11 px-20">
        <Card.Body>
          <Text className="text-primary-900 font-semibold text-xl mb-8">
            {t("session.new_password")}
          </Text>

          <Box
            as="form"
            onSubmit={handleSubmit(handleChangePassword)}
            className="flex flex-col gap-6"
            noValidate
          >
            <FormControl required isInvalid={!!errors.newPassword}>
              <FormControl.Label>{t("session.new_password")}</FormControl.Label>

              <Input.Group className="mb-3">
                <Input
                  {...register("newPassword")}
                  autoComplete="off"
                  type={getType("newPassword")}
                />
                <Input.RightElement>
                  <IconButton
                    size="sm"
                    variant="text"
                    color="neutral"
                    icon={<MdOutlineVisibility />}
                    onClick={toggleType("newPassword")}
                  />
                </Input.RightElement>
              </Input.Group>

              <Box className="grid grid-cols-2 gap-y-2 gap-x-7.5">
                <CheckValidation
                  isValid={isValidPassword("min_length")}
                  isInvalid={
                    !isValidPassword("min_length") && !!errors.newPassword
                  }
                >
                  Mínimo 8 caracteres
                </CheckValidation>
                <CheckValidation
                  isValid={isValidPassword("needs_uppercase")}
                  isInvalid={
                    !isValidPassword("needs_uppercase") && !!errors.newPassword
                  }
                >
                  1 mayúscula
                </CheckValidation>
                <CheckValidation
                  isValid={isValidPassword("needs_lowercase")}
                  isInvalid={
                    !isValidPassword("needs_lowercase") && !!errors.newPassword
                  }
                >
                  1 minúscula
                </CheckValidation>
                <CheckValidation
                  isValid={isValidPassword("needs_number")}
                  isInvalid={
                    !isValidPassword("needs_number") && !!errors.newPassword
                  }
                >
                  1 número
                </CheckValidation>
                <CheckValidation
                  isValid={isValidPassword("needs_special_character")}
                  isInvalid={
                    !isValidPassword("needs_special_character") &&
                    !!errors.newPassword
                  }
                >
                  1 caracter especial
                </CheckValidation>
              </Box>
            </FormControl>

            <FormControl required isInvalid={!!errors.confirmPassword}>
              <FormControl.Label>
                {t("session.confirm_password")}
              </FormControl.Label>

              <Input.Group>
                <Input
                  {...register("confirmPassword")}
                  autoComplete="off"
                  type={getType("confirmPassword")}
                />
                <Input.RightElement>
                  <IconButton
                    size="sm"
                    variant="text"
                    color="neutral"
                    icon={<MdOutlineVisibility />}
                    onClick={toggleType("confirmPassword")}
                  />
                </Input.RightElement>
              </Input.Group>

              {!!errors.confirmPassword && (
                <FormControl.ErrorMessage>
                  {t(`session.${errors.confirmPassword.message!}`)}
                </FormControl.ErrorMessage>
              )}
            </FormControl>

            <Box className="flex justify-end mt-4">
              <Button type="submit" loading={isPending}>
                {t("ui.save")}
              </Button>
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </ProfileLayout>
  );
};
