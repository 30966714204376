import { TaskTypes } from "../useCases/useGetTasks";

export function getTaskApiUrl(type: TaskTypes, resourceId?: string) {
  const taskUrls: Record<TaskTypes, string> = {
    all: "/tasks",
    cases: `/cases/${resourceId}/tasks`,
  };

  return taskUrls[type];
}
