import { routes } from "@/router";
import { Box, Button, Placeholder, Spinner } from "@suit-ui/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface PageContainerProps {
  children?: React.ReactNode;
  className?: string;
  isCentered?: boolean;
}

const PageContainer = ({
  children,
  className,
  isCentered,
}: PageContainerProps) => {
  return (
    <Box
      className={clsx(
        "px-8 py-6 overflow-auto bg-neutral-100 h-full",
        isCentered && "flex flex-col items-center justify-center",
        className,
      )}
    >
      {children}
    </Box>
  );
};

const PageContainerLoader = () => <Spinner size="20" />;

const PageContainerError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Placeholder className="w-[600px]" variant="vertical">
      <Placeholder.Image>
        <Placeholder.EmptyErrorIcon />
      </Placeholder.Image>
      <Placeholder.Content>
        <Placeholder.Title>{t("ui.error_loading_data")}</Placeholder.Title>
        <Placeholder.Description>
          {t("ui.an_error_occurred_while_loading_the_data")}
        </Placeholder.Description>
      </Placeholder.Content>
      <Placeholder.BtnGroup>
        <Button onClick={() => window.location.reload()} variant="outline">
          {t("ui.reload")}
        </Button>
        <Button onClick={() => navigate(routes.home)}>
          {t("ui.go_to_home")}
        </Button>
      </Placeholder.BtnGroup>
    </Placeholder>
  );
};

const PageContainerEmpty = () => {
  const { t } = useTranslation();

  return (
    <Placeholder className="w-[600px]" variant="vertical">
      <Placeholder.Image>
        <Placeholder.EmptyIcon />
      </Placeholder.Image>
      <Placeholder.Content>
        <Placeholder.Title>{t("ui.empty_data")}</Placeholder.Title>
        <Placeholder.Description>
          {t("ui.empty_data_description")}
        </Placeholder.Description>
      </Placeholder.Content>
    </Placeholder>
  );
};

const PageContainerForbidden = () => {
  const { t } = useTranslation();

  return (
    <Placeholder className="w-[600px]" variant="vertical">
      <Placeholder.Image>
        <Placeholder.EmptyErrorIcon />
      </Placeholder.Image>
      <Placeholder.Content>
        <Placeholder.Title>{t("ui.forbidden_title")}</Placeholder.Title>
        <Placeholder.Description>
          {t("ui.forbidden_description")}
        </Placeholder.Description>
      </Placeholder.Content>
    </Placeholder>
  );
};

PageContainer.Loader = PageContainerLoader;
PageContainer.Error = PageContainerError;
PageContainer.Empty = PageContainerEmpty;
PageContainer.Forbidden = PageContainerForbidden;

export { PageContainer };
