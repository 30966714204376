export const SSO_URL = import.meta.env.VITE_SSO_URL ?? "";
export const GREEN_MARIO_API_URL: string =
  import.meta.env.VITE_GREEN_MARIO_API_URL ?? "";
export const SSO_API_URL: string = import.meta.env.VITE_SSO_API_URL ?? "";
export const GREEN_MARIO_COOKIE_API_URL: string =
  import.meta.env.VITE_GREEN_MARIO_COOKIE_API_URL ?? "";

export const MIXPANEL_TOKEN: string = import.meta.env.VITE_MIXPANEL_TOKEN ?? "";

export const NODE_ENV = process.env.NODE_ENV;
