import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { Setting } from "../types/Setting";

export const GET_USER_SETTINGS_FETCH_KEY = "get_user_settings";

const getSettings = async () => {
  const response = await fetcher.get<Setting>("/settings/list_values");
  return response.data;
};

export const useGetSettings = () => {
  const query = useQuery({
    queryKey: [GET_USER_SETTINGS_FETCH_KEY],
    queryFn: getSettings,
    refetchOnMount: false,
  });

  return {
    settings: query.data,
    isPending: query.isPending,
  };
};
