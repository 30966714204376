import { fetcher } from "@/utils/fetchers/fetcher";
import { Task } from "../types/Task";
import { getTaskApiUrl } from "../utils/getTaskApiUrl";
import { CreateTaskData } from "./useCreateTask";
import { GET_TASKS_QUERY_KEY, TaskTypes } from "./useGetTasks";
import { useToast } from "@suit-ui/react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export type EditTaskData = Partial<CreateTaskData>;

interface EditTaskProps {
  type: TaskTypes;
  resourceId: string;
  data: EditTaskData;
  id: string;
}

const editTask = async ({ type, resourceId, data, id }: EditTaskProps) => {
  const url = getTaskApiUrl(type, resourceId);

  const response = await fetcher.put<Task>(`${url}/${id}`, {
    ...data,
    resource_id: resourceId,
    resource_type: type,
  });

  return response.data;
};

interface UseEditTaskProps {
  type: TaskTypes;
  resourceId: string;
  id: string;
}

export function useEditTask({ type, resourceId, id }: UseEditTaskProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: EditTaskData) =>
      editTask({ type, resourceId, data, id }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_TASKS_QUERY_KEY] });

      toast.add({
        content: t("tasks.edit_success"),
        status: "success",
      });
    },
    onError: () => {
      toast.add({
        content: t("tasks.errors.edit_failed"),
        status: "danger",
      });
    },
  });
}
