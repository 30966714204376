import { useTranslation } from "react-i18next";
import { CustomField } from "../types/CustomField";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  Icon,
  IconButton,
  Input,
  Table,
  Tooltip,
} from "@suit-ui/react";
import {
  MdAdd,
  MdOutlineDelete,
  MdOutlineEdit,
  MdSearch,
} from "react-icons/md";
import { CustomFieldValueModal } from "@/features/customFields/components/CustomFieldValueModal";
import { useGetCustomFieldValues } from "../useCases/useGetCustomFieldValues";
import { useGetCustomFields } from "../useCases/useGetCustomFields";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { useCreateCustomFieldValue } from "../useCases/useCreateCustomFieldValue";
import { useDeleteCustomFieldValue } from "../useCases/useDeleteCustomFieldValue";
import { useUpdateCustomFieldValue } from "../useCases/useUpdateCustomFieldValue";
import { CustomFieldValue } from "../types/CustomFieldValue";
import { stringIncludes } from "@/utils/texts/stringIncludes";
import { TableBody } from "@/ui/TableBody";

export interface CustomFieldValuesTableProps {
  customFieldId: CustomField["id"];
}

export function CustomFieldValuesTable({
  customFieldId,
}: CustomFieldValuesTableProps) {
  const { t } = useTranslation();
  const [createValueModal, setCreateValueModal] = useState(false);
  const [updateValueModal, setUpdateValueModal] =
    useState<CustomFieldValue | null>(null);
  const { createCustomFieldValue, isPending: isCreating } =
    useCreateCustomFieldValue();
  const { users } = useGetUsers();
  const { customFields } = useGetCustomFields("Case");
  const customField = customFields?.find((field) => field.id === customFieldId);
  const { customFieldValues, isPending, isLoading } =
    useGetCustomFieldValues(customFieldId);
  const { deleteCustomFieldValue, isPending: isDeleting } =
    useDeleteCustomFieldValue();
  const { updateCustomFieldValue, isPending: isUpdating } =
    useUpdateCustomFieldValue();
  const [removeCustomValue, setRemoveCustomValue] =
    useState<CustomFieldValue | null>(null);

  const [search, setSearch] = useState("");
  const [manuallyCreatedOnly, setManuallyCreatedOnly] = useState(true);

  const handleCreateCustomData = async (value: { name: string }) => {
    await createCustomFieldValue({
      customFieldId: customFieldId,
      value,
    });
    setCreateValueModal(false);
  };

  const handleUpdateValue = async (value: { name: string }) => {
    if (!updateValueModal) return;
    await updateCustomFieldValue({
      customFieldId: customFieldId,
      customFieldValueId: updateValueModal.id,
      data: value,
    });
    setUpdateValueModal(null);
  };

  const handleRemoveCustomValue = async () => {
    if (!removeCustomValue) return;
    await deleteCustomFieldValue({
      customFieldId: customFieldId,
      customFieldValueId: removeCustomValue.id,
    });
    setRemoveCustomValue(null);
  };

  if (!customField) return null;

  const customFieldValuesFiltered = customFieldValues.filter(
    (value) =>
      stringIncludes(value?.name ?? "", search) &&
      (!manuallyCreatedOnly || value.created_by_id),
  );

  const isEmpty = !isLoading && !customFieldValuesFiltered?.length;

  return (
    <Box className="flex flex-col gap-4">
      <Box className="flex justify-between items-center pt-6 pb-1">
        <Box className="flex gap-6">
          <Input.Group className="w-[400px]">
            <Input
              placeholder={`${t(
                "ui.search",
              )} ${customField.name.toLocaleLowerCase()}`}
              className="rounded-full"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <Input.LeftElement className="opacity-50 peer-focus-visible:opacity-100 flex justify-center items-center w-10 h-10">
              <Icon as={MdSearch} size="6" className="text-neutral-600" />
            </Input.LeftElement>
          </Input.Group>

          <Checkbox
            checked={manuallyCreatedOnly}
            onChange={(e) => setManuallyCreatedOnly(e.target.checked)}
          >
            {
              // TODO i18n
            }
            Solo {customField.name.toLocaleLowerCase()}s creadas manualmente
          </Checkbox>
        </Box>

        <Button onClick={() => setCreateValueModal(true)} leftIcon={<MdAdd />}>
          {t("ui.add") + " " + customField.name}
        </Button>
      </Box>

      <Table.Container
        className="rounded-md"
        variant="elevated"
        isLoading={isPending}
      >
        <Table>
          <Table.Head className="bg-primary-800 text-neutral-50">
            <Table.Row className="text-neutral-50">
              <Table.Header>{t("cases_data.stages.name")}</Table.Header>
              <Table.Header>{t("cases_data.stages.created_by")}</Table.Header>
              <Table.Header>{t("cases_data.stages.options")}</Table.Header>
            </Table.Row>
          </Table.Head>
          <TableBody isEmpty={isEmpty} title={t("ui.no_data")}>
            {customFieldValuesFiltered.map((opt) => {
              const creator = users.find(
                (user) => user.id === opt.created_by_id,
              );

              return (
                <Table.Row key={opt.id}>
                  <Table.Cell>{opt.name}</Table.Cell>
                  <Table.Cell>
                    {creator && (
                      <Tooltip label={creator?.name}>
                        <Avatar
                          size="sm"
                          variant="dark"
                          className="grow-0 shrink-0"
                          name={creator?.name}
                        />
                      </Tooltip>
                    )}
                  </Table.Cell>
                  <Table.Cell className="flex justify-center">
                    {creator && (
                      <>
                        <IconButton
                          icon={<MdOutlineEdit />}
                          variant="text"
                          color="neutral"
                          onClick={() => setUpdateValueModal(opt)}
                        />
                        <IconButton
                          icon={<MdOutlineDelete />}
                          variant="text"
                          color="neutral"
                          onClick={() => setRemoveCustomValue(opt)}
                        />
                      </>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </TableBody>
        </Table>
      </Table.Container>

      <CustomFieldValueModal
        isOpen={createValueModal}
        onClose={() => setCreateValueModal(false)}
        customFieldName={customField.name}
        onSubmit={handleCreateCustomData}
        defaultValues={{
          name: customFieldValuesFiltered.length === 0 ? search ?? "" : "",
        }}
        isLoading={isCreating}
      />

      <CustomFieldValueModal
        isOpen={!!updateValueModal}
        onClose={() => setUpdateValueModal(null)}
        customFieldName={customField.name}
        onSubmit={handleUpdateValue}
        defaultValues={updateValueModal}
        mode="update"
        isLoading={isUpdating}
      />

      <Dialog
        isOpen={!!removeCustomValue}
        onClose={() => setRemoveCustomValue(null)}
        status="warning"
        size="sm"
      >
        <Dialog.Overlay />

        <Dialog.Content>
          <Dialog.Header>
            {t("ui.delete")} {customField.name.toLocaleLowerCase()}
          </Dialog.Header>
          <Dialog.CloseButton />
          <Dialog.Body>
            {t("cases_data.delete_warning", {
              field: customField.name.toLocaleLowerCase(),
            })}
          </Dialog.Body>

          <Dialog.Body>{t("cases_data.courts.confirm_dialog")} </Dialog.Body>
          <Dialog.Footer>
            <Button
              onClick={() => setRemoveCustomValue(null)}
              variant="text"
              color="neutral"
              className="mr-2"
              size="sm"
            >
              {t("ui.cancel")}
            </Button>
            <Button
              size="sm"
              onClick={handleRemoveCustomValue}
              loading={isDeleting}
            >
              {t("ui.delete")}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </Box>
  );
}

