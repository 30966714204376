import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { useDateLocale } from "@/utils/i18n/useDateLocale";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Checkbox,
  DatePicker,
  FormControl,
  Input,
  Modal,
  Select,
  Text,
  TextArea,
} from "@suit-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { EventForm } from "../types/Event";
import { useEffect } from "react";
import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import { setHours } from "date-fns";

interface EventModalProps {
  title: string;
  submitButtonText: string;
  defaultValues: EventForm;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: EventForm) => Promise<void>;
  isPending: boolean;
  availableEventUsers: string[];
}

const schema = z
  .object({
    summary: z
      .string({
        required_error: "events.errors.summary_required",
      })
      .min(3, { message: "events.errors.summary_min" })
      .max(100, { message: "events.errors.summary_max" }),
    description: z
      .string({
        required_error: "events.errors.description_required",
      })
      .min(3, { message: "events.errors.description_min" })
      .max(1000, { message: "events.errors.description_max" }),
    start_date: z
      .date({
        required_error: "events.errors.start_date_required",
      })
      .nullable(),
    end_date: z.date().nullable().optional(),
    all_day: z.boolean().optional(),
    attendees: z.string().array().optional(),
  })
  .refine(
    (data) => {
      if (!data.end_date || !data.start_date) return true;

      return data.end_date >= data.start_date;
    },
    {
      message: "events.errors.end_date_invalid",
      path: ["end_date"],
    },
  );
type EventFormData = z.infer<typeof schema>;

export const EventModal: React.FC<EventModalProps> = ({
  title,
  submitButtonText,
  defaultValues,
  isOpen,
  onClose,
  onSubmit,
  isPending,
  availableEventUsers,
}) => {
  const { t } = useTranslation();
  const dateLocale = useDateLocale();
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    control,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<EventFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
    defaultValues,
  });
  const {
    findById,
    isLoading: isGetUsersLoading,
    isError: isGetUsersError,
  } = useGetUsers();

  const { data: currentUser } = useCurrentUser();

  const handleOnClose = () => {
    if (isPending) return;

    onClose();
  };

  const handleOnSubmit = async (data: EventFormData) => {
    const noon = 12;

    await onSubmit(
      data.all_day
        ? {
            ...data,
            start_date: data.start_date
              ? setHours(data.start_date, noon)
              : null,
            end_date: data.end_date ? setHours(data.end_date, noon) : null,
          }
        : data,
    );

    onClose();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [isSubmitSuccessful, defaultValues, reset]);

  const watchAllDay = watch("all_day");

  const usersIds = availableEventUsers.includes(currentUser!.id)
    ? availableEventUsers
    : [...availableEventUsers, currentUser!.id];

  const usersOptions = usersIds
    .map((userId) => findById(userId))
    ?.filter((user) => !!user?.active)
    .map((user) => ({
      label: user!.name,
      value: user!.id,
    }));

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} scrollBehavior="outside">
      <Modal.Overlay />

      <Modal.Content as="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
          {title}
        </Modal.Header>
        <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />

        <Modal.Body className="flex flex-col gap-3">
          <FormControl required isInvalid={!!errors.summary}>
            <FormControl.Label>{t("events.summary")}</FormControl.Label>
            <Input {...register("summary")} autoComplete="off" />
            {!!errors.summary && (
              <FormControl.ErrorMessage>
                {t(errors.summary.message!)}
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl required isInvalid={!!errors.description}>
            <FormControl.Label>{t("events.description")}</FormControl.Label>
            <TextArea {...register("description")} autoComplete="off" />
            {!!errors.description && (
              <FormControl.ErrorMessage>
                {t(errors.description.message!)}
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <Box className="flex items-end gap-5">
            <FormControl required isInvalid={!!errors.start_date}>
              <FormControl.Label>{t("events.start_date")}</FormControl.Label>
              <Controller
                name="start_date"
                control={control}
                render={({
                  field: { value, onChange, onBlur, disabled, name },
                }) => (
                  <DatePicker
                    name={name}
                    onChange={(data) => {
                      onChange(data);
                      trigger("start_date");
                    }}
                    onBlur={onBlur}
                    disabled={disabled}
                    selected={value}
                    dateFormat={
                      !watchAllDay
                        ? t("date_formats.datetime")
                        : t("date_formats.date")
                    }
                    size="sm"
                    autoComplete="off"
                    isClearable
                    showTimeSelect={!watchAllDay}
                    timeCaption={t("date_formats.time_caption")}
                    locale={dateLocale}
                    timeFormat={t("date_formats.time")}
                  />
                )}
              />
              {!!errors.start_date && (
                <FormControl.ErrorMessage>
                  {t(errors.start_date.message!)}
                </FormControl.ErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={!!errors.end_date}>
              <FormControl.Label>{t("events.end_date")}</FormControl.Label>
              <Controller
                name="end_date"
                control={control}
                render={({
                  field: { value, onChange, onBlur, disabled, name },
                }) => (
                  <DatePicker
                    name={name}
                    onChange={(data) => {
                      onChange(data);
                      trigger("end_date");
                    }}
                    onBlur={onBlur}
                    disabled={disabled}
                    selected={value}
                    dateFormat={
                      !watchAllDay
                        ? t("date_formats.datetime")
                        : t("date_formats.date")
                    }
                    size="sm"
                    autoComplete="off"
                    isClearable
                    showTimeSelect={!watchAllDay}
                    timeCaption={t("date_formats.time_caption")}
                    locale={dateLocale}
                    timeFormat={t("date_formats.time")}
                  />
                )}
              />
              {!!errors.end_date && (
                <FormControl.ErrorMessage>
                  {t(errors.end_date.message!)}
                </FormControl.ErrorMessage>
              )}
            </FormControl>

            <FormControl className="flex h-9">
              <Checkbox {...register("all_day")}>
                {t("events.all_day.label")}
              </Checkbox>
            </FormControl>
          </Box>

          <FormControl isInvalid={!!errors.attendees || isGetUsersError}>
            <FormControl.Label>{t("events.attendees")}</FormControl.Label>
            <Controller
              name="attendees"
              control={control}
              defaultValue={defaultValues.attendees}
              render={({ field }) => (
                <Select
                  {...field}
                  options={usersOptions}
                  valueAsObject={false}
                  menuPosition="fixed"
                  isMulti
                  disabled={isGetUsersLoading || isGetUsersError}
                  placeholder={t("events.attendees_placeholder")}
                />
              )}
            />
            {!!errors.attendees && (
              <FormControl.ErrorMessage>
                {t(errors.attendees.message!)}
              </FormControl.ErrorMessage>
            )}
            {isGetUsersError && (
              <FormControl.ErrorMessage>
                {t("events.errors.get_users_failed")}
              </FormControl.ErrorMessage>
            )}
          </FormControl>
        </Modal.Body>

        <Modal.Footer className="flex items-center">
          <Text className="mr-auto text-sm">{t("events.required_fields")}</Text>
          <Button
            className="mr-2"
            variant="text"
            color="neutral"
            onClick={handleOnClose}
          >
            {t("events.cancel")}
          </Button>
          <Button type="submit" loading={isPending}>
            {submitButtonText}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
