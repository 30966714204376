import { Button, Dialog, useDisclosure } from "@suit-ui/react";
import { CredentialFormData } from "../types/CredentialFormData";
import { useCreateCredentials } from "../useCases/useCreateCredentials";
import CredentialModal from "./CredentialModal";
import { t } from "i18next";

interface CreateCredentialModalProps {
  isOpen?: boolean;
  onClose: () => void;
}

const credDefaultValue: CredentialFormData = {
  credential_type: "",
  username: "",
  password: "",
  name: "",
  global: false,
  user_ids: [],
  finders: [] as never,
  search_params: {},
};

const CreateCredentialModal: React.FC<CreateCredentialModalProps> = ({
  isOpen = false,
  onClose,
}) => {
  const { mutateAsync, isPending: isCreating, data } = useCreateCredentials();
  const duplicateDialog = useDisclosure();

  const createCredential = async (
    cred: CredentialFormData,
    validatedKeyPath: string,
  ) => {
    const credResponse = await mutateAsync({
      params: cred,
      validatedPath: validatedKeyPath,
    });

    if (credResponse.is_duplicate) duplicateDialog.onOpen();

    onClose();
  };
  return (
    <>
      <CredentialModal
        credential={credDefaultValue}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={createCredential}
        isSaving={isCreating}
        isCreationMode
      />
      <Dialog isOpen={duplicateDialog.isOpen} onClose={duplicateDialog.onClose}>
        <Dialog.Overlay />
        <Dialog.Content>
          <Dialog.Header>{t("credentials.already_exists.title")}</Dialog.Header>
          <Dialog.CloseButton />
          <Dialog.Body>
            {t("credentials.already_exists.description", {
              name: data?.name,
            })}
          </Dialog.Body>
          <Dialog.Footer>
            <Button size="sm" onClick={duplicateDialog.onClose}>
              {t("ui.agree")}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export { CreateCredentialModal };
