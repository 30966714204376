import { Movement } from "@/features/cases/types/Movement";
import { CustomFieldInput } from "@/features/customFields/components/CustomFieldInput";
import { useGetCustomFields } from "@/features/customFields/useCases/useGetCustomFields";
import { Modal, FormControl, Input, Button } from "@suit-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetMovement } from "../useCases/useGetMovement";
import { Controller, useForm } from "react-hook-form";
import { useUpdateMovement } from "../useCases/useUpdateMovement";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { trackEvent } from "@/utils/analytics/trackEvent";

const schema = z
  .object({
    title: z.string({ description: "field_required" }),
    custom_data: z.any(),
  })
  .required({ title: true });

type MovementFormData = z.infer<typeof schema>;
export interface CreateMovementModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  movementId?: Movement["id"] | null;
}

export const EditMovementModal: React.FC<CreateMovementModalProps> = ({
  isOpen,
  onClose,
  movementId,
}) => {
  const { t } = useTranslation();
  const { customFields } = useGetCustomFields("Movement");
  const { data, isLoading: isMovementLoading } = useGetMovement(
    movementId ?? "",
  );
  const { updateMovement, isPending: isUpdating } = useUpdateMovement();

  const { register, control, handleSubmit, reset } = useForm<MovementFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = handleSubmit(async (form: MovementFormData) => {
    if (!data) return;

    await updateMovement({
      ...data,
      ...form,
    });

    trackEvent("Movement updated");

    onClose?.();
  });

  useEffect(() => {
    if (data) reset(data);

    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      {!isMovementLoading && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <Modal.Overlay />

          <Modal.Content>
            <form onSubmit={onSubmit}>
              <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
                {t("movements.edit_modal_header")}
              </Modal.Header>

              <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
              <Modal.Body className="flex flex-col gap-3">
                <FormControl required className="flex-1">
                  <FormControl.Label>{t("movements.title")}</FormControl.Label>

                  <Input {...register("title")} />
                </FormControl>

                {customFields
                  .filter((customField) => customField.display_order_form > 0)
                  .map((customField) => (
                    <FormControl key={customField.name}>
                      <FormControl.Label>{customField.name}</FormControl.Label>

                      <Controller
                        control={control}
                        name={`custom_data.${customField.name}`}
                        render={({ field }) => (
                          <CustomFieldInput
                            {...field}
                            id={customField.id}
                            name={customField.name}
                            model="Case"
                          />
                        )}
                      />
                    </FormControl>
                  ))}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  onClick={onClose}
                  variant="text"
                  disabled={isUpdating}
                  className="mr-2"
                  color="neutral"
                >
                  {t("ui.cancel")}
                </Button>
                <Button type="submit" loading={isUpdating}>
                  {t("ui.save")}
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
