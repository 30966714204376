import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "@suit-ui/react";
import { fetcher } from "@/utils/fetchers/fetcher";
import { CredentialValidate } from "../types/CredentialValidate";
import { CredentialFormData } from "../types/CredentialFormData";

interface ValidateCredentialParams {
  credential_type: CredentialFormData["credential_type"];
  username: CredentialFormData["username"];
  password: CredentialFormData["password"];
}

const validateCredential = async (credential: ValidateCredentialParams) => {
  const response = await fetcher.post<CredentialValidate>(
    "/scrapers/validate_credential",
    { credential },
  );
  return response.data;
};

export const useValidateCredential = () => {
  const toast = useToast();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: validateCredential,
    onError: (args) => {
      console.error(args);
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });
};
