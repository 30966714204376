import { CreateMovement, Movement } from "@/features/cases/types/Movement";
import { CustomFieldInput } from "@/features/customFields/components/CustomFieldInput";
import { useGetCustomFields } from "@/features/customFields/useCases/useGetCustomFields";
import { Modal, FormControl, Input, Button } from "@suit-ui/react";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface CreateMovementModalProps {
  onSubmit?: (item: CreateMovement) => void;
  isOpen?: boolean;
  onClose?: () => void;
}

const defaultState: CreateMovement = {
  title: "",
  official_date: new Date().toISOString(),
  custom_data: {},
};

export const CreateMovementModal: React.FC<CreateMovementModalProps> = ({
  onSubmit,
  isOpen,
  onClose,
}) => {
  const { customFields } = useGetCustomFields("Movement");
  const [movement, setMovement] = useState<CreateMovement>(defaultState);
  const [customData, setCustomData] = useState<Movement["custom_data"]>({});
  const { t } = useTranslation();

  useEffect(() => {
    setMovement(defaultState);
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Overlay />

        <Modal.Content
          as="form"
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            movement.custom_data = customData;
            onSubmit?.(movement);
          }}
        >
          <Modal.Header className="bg-primary-800 text-neutral-50 rounded-t-md">
            {t("movements.create_modal_header")}
          </Modal.Header>

          <Modal.CloseButton className="text-neutral-50 hover:text-primary-200 active:text-primary-300" />
          <Modal.Body className="flex flex-col gap-3">
            <FormControl required className="flex-1">
              <FormControl.Label>{t("movements.title")}</FormControl.Label>

              <Input
                value={movement.title}
                onChange={(e) => {
                  const value = e.target.value;
                  setMovement((prev) => ({ ...prev, title: value }));
                }}
              />
            </FormControl>

            {customFields.map((customField) => {
              if (customField.display_order_form > 0) {
                return (
                  <FormControl key={customField.name}>
                    <FormControl.Label>{customField.name}</FormControl.Label>

                    <CustomFieldInput
                      name={customField.name}
                      id={customField.id}
                      model="Movement"
                      onChange={(v) => {
                        setCustomData((prev) => ({
                          ...prev,
                          [customField.name]: v,
                        }));
                      }}
                    />
                  </FormControl>
                );
              }
            })}
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={onClose}
              variant="text"
              color="neutral"
              className="mr-2"
            >
              {t("ui.cancel")}
            </Button>
            <Button type="submit">{t("ui.create")}</Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};
